import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { updateSavedListings } from '../../ducks/user.duck';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  AvatarExtraLarge,
  NamedLink,
  ListingCard,
  Reviews,
  ButtonTabNavHorizontal,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { Popup } from 'semantic-ui-react';
import { loadData } from './ProfilePage.duck';
import config from '../../config';
import moment from 'moment';

import css from './ProfilePage.css';

const { UUID } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const confirmedEmailImage = require('./images/CONFIRMED_EMAIL_ICON.png');
const cleaningPledgeImage = require('./images/CLEANING_PLEDGE_ICON.png');
const verifiedCertificateImage = require('./images/VERIFIED_CERTIFICATE_ICON.png');

const joinedFormattedDate = date => moment(date).format('MMM, YYYY');

export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    };

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this);
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this);
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    });
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    });
  }

  render() {
    const {
      scrollingDisabled,
      currentUser,
      user,
      userShowError,
      queryListingsError,
      listings,
      reviews,
      queryReviewsError,
      onUpdateSavedListings,
      viewport,
      intl,
    } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const profileUser = ensureUser(user);
    const isCurrentUser =
      ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
    const publicData = profileUser.attributes.profile.publicData;
    const metadata = profileUser.attributes.profile.metadata || {};
    const joinedDate = profileUser.attributes.createdAt;
    const emailIsVerified = ensuredCurrentUser.id && ensuredCurrentUser.attributes.emailVerified;
    const displayName = profileUser.attributes.profile.displayName;
    const bio = profileUser.attributes.profile.bio;
    const hasBio = !!bio;
    const certifications = publicData && publicData.certificates ? publicData.certificates : [];
    const hasCertifications = certifications.length > 0;
    const displayLocation = publicData && publicData.location ? publicData.location : null;
    const cleaningPledge = metadata.cleaningPledge || false;
    const verifiedCertificate = metadata.verifiedCertificate || false;
    const skills = publicData && publicData.teach ? publicData.teach : [];
    const hasListings = listings.length > 0;
    const hasSkills = skills.length > 0;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const cleaningPledgeHelpText = (
      <>
        <p className={css.helpText}>The Cleaning Pledge icon shows that this member has pledged the following:</p>
        <div className={css.helpContainer}>
          <h3 className={css.helpTitle}>Both Users and Hosts:</h3>
          <p className={css.helpText}>
            To follow state and local safety guidelines for protecting
            against the spread of COVID-19.
          </p>
          <p className={css.helpText}>
            To have open and honest communication with clients about their
            current health to limit those that are sick from entering facilities
          </p>
        </div>

        <div className={css.helpContainer}>
          <h3 className={css.helpTitle}>Users:</h3>
          <p className={css.helpText}>
            To clean/wipe down all equipment and items used or touched during a booking
          </p>
          <p className={css.helpText}>
            To have clients maintain social distancing whenever using a booked space
          </p>
        </div>

        <div className={css.helpContainer}>
          <h3 className={css.helpTitle}>Hosts:</h3>
          <p className={css.helpText}>
            To clean their spaces prior to the user arriving for a booking
          </p>
          <p className={css.helpText}>
            To provide cleaning supplies so users can clean up after themselves
            at the end of a booking
          </p>
        </div>
      </>
    );

    const verifiedCertificateHelpText = (
      <>
        <p className={css.helpText}>The Verified Certificate Badge shows that this member has had their professional certification verified by Gofynder.</p>
        <p className={css.helpText}>Verification currently only applies to primary certifications from NASM, ACE, and NCSF. Gofynder does not track or update the badge if/when certifications have expire. </p>
      </>
    );

    const verifiedIcons = () => {
      return (
        <>
          {emailIsVerified ? (
            <div className={css.verifiedIcon}>
              <img src={confirmedEmailImage} alt="Confirmed Email" />
            </div>
          ) : null}
          {cleaningPledge ? (
            <div className={css.verifiedIcon}>
              <Popup content={cleaningPledgeHelpText} trigger={<img src={cleaningPledgeImage} alt="Verified Cleaning Pledge" />
              } />
            </div>
          ) : null}
          {verifiedCertificate ? (
            <div className={css.verifiedIcon}>
              <Popup content={verifiedCertificateHelpText} trigger={<img style={{margin: "auto 0"}} src={verifiedCertificateImage} alt="Verified Cleaning Pledge" />
              } />
            </div>
          ) : null}
        </>
      )
    };

    const editLinkMobile = isCurrentUser ? (
      <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
      </NamedLink>
    ) : null;
    const editLinkDesktop = isCurrentUser ? (
      <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
      </NamedLink>
    ) : null;

    const asideContent = (
      <div className={css.asideContent}>
        {editLinkMobile}
        <AvatarExtraLarge className={css.avatar} user={user} disableProfileLink />
        <h1 className={css.heading}>
          {displayName ? (
            <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
          ) : null}
        </h1>
        <h3 className={css.joinedDate}>
          <FormattedMessage id="ProfilePage.joinedDate" values={{ date: joinedFormattedDate(joinedDate) }} />
        </h3>
        <div className={css.verifiedIconsMobile}>
          {verifiedIcons()}
        </div>
        {displayLocation ? (
          <div className={css.asideItem}>
            <h3 className={css.asideItemTitle}>My City</h3>
            <p className={css.asideItemValue}>{displayLocation.search}</p>
          </div>
        ) : null}
        {hasSkills ? (
          <div className={css.asideItem}>
            <h3 className={css.asideItemTitle}>What I Teach</h3>
            {skills.map((skill, index) => (
              <p key={index} className={css.asideItemValue}>{skill}</p>
            ))}
          </div>
        ) : null}
        {editLinkDesktop}
      </div>
    );

    const listingsContainerClasses = classNames(css.listingsContainer, {
      [css.withBioMissingAbove]: !hasBio,
    });

    const reviewsError = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
      </p>
    );

    const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);

    const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);

    const mobileReviews = (
      <div className={css.mobileReviews}>
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfProviderTitle"
            values={{ count: reviewsOfProvider.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfProvider} />
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfCustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfCustomer} />
      </div>
    );

    const desktopReviewTabs = [
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfProviderTitle"
              values={{ count: reviewsOfProvider.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER,
        onClick: this.showOfProviderReviews,
      },
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfCustomerTitle"
              values={{ count: reviewsOfCustomer.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
        onClick: this.showOfCustomerReviews,
      },
    ];

    const desktopReviews = (
      <div className={css.desktopReviews}>
        <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

        {queryReviewsError ? reviewsError : null}

        {this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
          <Reviews reviews={reviewsOfProvider} />
        ) : (
            <Reviews reviews={reviewsOfCustomer} />
          )}
      </div>
    );

    const mainContent = (
      <div>
        <h1 className={cleaningPledge || verifiedCertificate ? css.desktopHeading : css.desktopHeadingWithMargins}>
          <FormattedMessage id="ProfilePage.desktopHeading" values={{ name: displayName }} />
        </h1>
        <div className={css.verifiedIconsDesktop}>
          {verifiedIcons()}
        </div>
        {hasBio ? <p className={css.bio}>{bio}</p> : null}
        <h2 className={css.listingsTitle}>
          <FormattedMessage id="ProfilePage.certifications"/>
        </h2>
        {hasCertifications ? (
        <ul className={css.certifications}>
          {certifications.map((certification, index) => (
            <li key={index} className={css.certification}>
              <p>{certification}</p>
            </li>
          ))}
        </ul>
        ) : null}
        {hasListings ? (
          <div className={listingsContainerClasses}>
            <h2 className={css.listingsTitle}>
              <FormattedMessage
                id="ProfilePage.listingsTitle"
                values={{ count: listings.length }}
              />
            </h2>
            <ul className={css.listings}>
              {listings.map(l => (
                <li className={css.listing} key={l.id.uuid}>
                  <ListingCard listing={l} currentUser={currentUser} onUpdateSavedListings={onUpdateSavedListings} />
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {isMobileLayout ? mobileReviews : desktopReviews}
      </div>
    );

    let content;

    if (userShowError && userShowError.status === 404) {
      return <NotFoundPage />;
    } else if (userShowError || queryListingsError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="ProfilePage.loadingDataFailed" />
        </p>
      );
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage(
      {
        id: 'ProfilePage.schemaTitle',
      },
      {
        name: displayName,
        siteTitle: config.siteTitle,
      }
    );

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ProfilePage',
          name: schemaTitle,
        }}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfilePage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav className={css.aside}>{asideContent}</LayoutWrapperSideNav>
          <LayoutWrapperMain>{content}</LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(propTypes.listing).isRequired,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,
  onUpdateSavedListings: func.isRequired,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  const listings = getMarketplaceEntities(state, userListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    queryListingsError,
    listings,
    reviews,
    queryReviewsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateSavedListings: savedListings => dispatch(updateSavedListings(savedListings))
})

const ProfilePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ProfilePage;
