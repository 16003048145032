import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '..';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: April , 2022</p>

      <p>
      Welcome to Gofynder. We’ve developed an online marketplace to help you find great spaces to host your fitness and wellness clients as well as for hosts to create extra income from their spaces. <strong>We have tried to make these Terms of Services easy to read and understand. Please take the time to read them carefully as they govern your use of our platform.</strong>
      </p>

      <p>
      The following terms of service (these “Terms of Service”), including community guidelines (“Community Guidelines”), govern your access to and use of the Gofynder website, including content, functionality, and services offered on or through <a href="www.gofynder.com">www.gofynder.com </a> (the “Site”) by Go Fynder, LLC.  (2604-B El Camino Real, Suite 196, Carlsbad, CA 92008). Go Fynder, LLC referred here to as “Gofynder” “we” or “us” and “you” or “member” means you as a user of the site.
      </p>

      <p>
        <strong>
        By using the Site, opening an account or by clicking to accept or agree to the Terms of Service and Community Guidelines when this option is made available to you, you accept and agree, on behalf of yourself or on behalf of your employer or any other entity (if applicable), to be bound and abide these Terms of Service, Gofynder Payment Terms, and our Community Guidelines.
        </strong>
      </p>

      <p>
        <strong>
        This Agreement contains waivers of class actions and jury trials and an agreement to submit all claims and disputes to binding arbitration in Section 4 below. If you do not agree to all the terms and conditions of this Services Agreement, including those governing disputes in Section 4, you may not use our platform or services.
        </strong>
      </p>

      <h2>Key Terms</h2>
      <p>
        <strong>Members</strong> are individuals or organizations using the website that have created an account with Gofynder.
      </p>
      <p>
        <strong>Users</strong> are members that use Gofynder to book spaces.  
      </p>
      <p>
        <strong>Hosts</strong> are members that use Gofynder to list and book out their space(s).
      </p>
      <p>
        <strong>Space</strong> is a location or site that has been made available for bookings by a Host
      </p>
      
      <p>
        <strong>Booking(s)</strong> are the arranged use of space(s) by Users from Hosts.  Bookings are available in varying lengths.
      </p>

      <h2>Overview</h2>

      <p>
      This Agreement describes the terms and conditions that apply to your use of the Gofynder marketplace to book or list spaces or other services through our website, and governs your hosting spaces for other users; and establishes the obligations owed between you and Gofynder, and between you and other users.
      </p>

      <p>
      Throughout this Agreement, the term “Service” means the service allowing you to book spaces and services through our website (the “Platform”). The term Service does not include any services provided by third parties. The terms “User” and “Host” refer to the party Booking or Listing a Space, respectively.
      </p>

      <p>
      A “Space” is the location or site that is made available to a User to use during specific times for a specific purpose (a “Session”), and subject to specific Fees and any site-specific terms or limitations. A Host may “List” a Space by providing certain details about the Space including availability, pricing, and terms or limitations of use; this posting about a Space is called a “Listing.”
      </p>

      <p>
      A User may “Book” a Space by accepting the times, fees, and any additional terms or limitations of the Listing. A “Booking” is (i) only the grant of a limited, temporary, revocable license to use a Space, in the manner, for the time, and subject to all restrictions provided, subject to this Agreement, and as confirmed through the Platform. The scope of the use—including times, specific fees, and other additional terms or limitations—may also be included in or supplemented by a separate “Use Agreement” that incorporates the terms and conditions of this Agreement and referenced policies. A Booking does not provide you a lease but only allows you limited access to use the Space or other service only as permitted in the Agreement and, as applicable, the Use Agreement.
      </p>
      
      <p>
        This Agreement is organized into 5 parts:
      </p>

      <p>
      <NamedLink name="TermsOfServicePage" to={{ hash: '#generalTerms' }}><u>Section 1: General Terms</u> </NamedLink> includes general terms describing your Account including creating an account, accepting or making payments, and describing how we communicate with you.
      </p>
      <p>
      <NamedLink name="TermsOfServicePage" to={{ hash: '#plataformAndService' }}> <u>Section 2: Platform and Service</u> </NamedLink> describes how you may and may not use the Platform and Service, and also describes how we use any data.
      </p>
      <p>
      <NamedLink name="TermsOfServicePage" to={{ hash: '#gofynderServices' }}> <u>Section 3: Gofynder Services</u> </NamedLink> establishes the obligations of Hosts, including how to List a Space and what is expected of any Space Listed through the Service; and the obligations of Users, including Booking a Space, expected behavior, responsibility for payment of Fees, and Booking other services.
      </p>

      <p>
      <NamedLink name="TermsOfServicePage" to={{ hash: '#terminationDisputes' }}> <u>Section 4: Termination, Disputes, and Binding Arbitration</u> </NamedLink> describes termination or suspension of this Agreement or your Account, and the process of resolving any disputes. This section contains waivers of both class actions and jury trials, and an agreement to submit all claims and disputes to binding arbitration. Please read this section carefully before accepting this Agreement –you may not use the Platform or Services if you do not agree to this section.
      </p>

      <p>
      <NamedLink name="TermsOfServicePage" to={{ hash: '#additionalLegalTerms' }}>  <u>Section 5: Additional Legal Terms</u> </NamedLink> provides additional legal terms including our ability to update this Agreement, disclaimers and limitations on our liability. It also includes other documents governing your use of the Services including our Community Guidelines, Fees Overview, Privacy Policy, and Cancellation and Refund Policy.
      </p>

      <p>
      If you have questions about this Agreement, please contact us. Your use of the Platform or Services constitutes your ongoing acceptance of this Agreement, as amended.
      </p>

      <h2 id="generalTerms"> Section 1: General Terms</h2>

      <p><strong>1.1 Services</strong></p>
      <p>
      Gofynder provides you access to Services through the Platform. You may access the Platform through our website. You may use the Service to List Spaces as a Host, Book Spaces as a User, communicate with other users, and access your Account.
      </p>
      <p>
      We are continuously improving the Platform for all users and reserve the right to make changes at our discretion. Changes we make to the Platform or Service, including discontinuing certain features, affect all users and we try to minimize disruption to users. However, these changes may affect users differently. We are not responsible if any specific changes we make to the Platform or Service adversely affect how you use them.
      </p>

      <p><strong>1.2 Booking Options</strong></p>

      <p>Gofynder offers multiple ways for users to Book the Spaces provided by our hosts including one-time use bookings and subscription booking options.   </p>

      <p>The booking options are as follows:</p>
      <ul>
        <li><strong>By the Hour</strong> – a one-time booking using 15-minute increments where the user selects a start and end time for their booking.  By the Hour bookings are a minimum of one hour and use 15-minute increments after the first hour. </li>
        <li><strong>By the Day</strong> – a one-time booking where a user can book a space for a single or multiple days.  The availability of the space along with the start and end times for individual days are determined by the host.</li>
        <li><strong>By the Month</strong> – a subscription booking which allows the user to use the space at any time within the host designated hours.  The user books the space in 30 day increments which automatically renew every month until cancelled.  </li>
        <li><strong>By the Class</strong> –  a subscription booking which allows the user to use the space during a specific time slot of their choosing on a specific day of the week.  The user books the space for four (4) sessions at a time which automatically renews after the 4th session until cancelled.     </li>
      </ul>
      <p><strong>1.3 Your Account</strong></p>

      <p>
      Before using the Platform, you must create an account with Gofynder (“Account”) and provide us with information about yourself or your company. We may ask that you update your Account or provide additional or different information at any time. This includes requiring additional details about Spaces or payment information. Any employees, contractors, or third-parties opening a new or using an existing account (collectively, “Agents”) represent and warrant that they have authorization to act on behalf of a person or entity they claim to represent; and if such authorization is not provided by the person or entity, such Agents acknowledge personal liability for all obligations and liabilities arising from or related to use of the Account by such Agents.
      </p>

      <p>
      You will be required to provide an email address and password to keep your Account secure (“Credentials”), and agree to keep your Credentials private and secure. You are responsible for any actions taken on your Account using your Credentials whether or not authorized or taken by Agents to List or Book a Space. You are solely responsible for the actions or communications of your Agents. We are not responsible for and disclaim all liability for use of your Account by your Agents.
      </p>

      <p>
      When creating or using your Account, you may be required to provide information about yourself or your Space, or provide optional information or comment on other users’ Spaces, including text, images, or videos (collectively, “Content”). You will only provide Content to us that you own or have authorization to provide, and ensure that Content is accurate and complete. You must keep any Content current, including your contact or payment information. We reserve the right, but shall not be obligated, to use public and private data sources to validate the accuracy of any Content. This may include validating your identity, including verifying background or business information, or verifying information about Spaces. You will provide us any additional information to verify the accuracy or completeness of any Content that you provide and we may condition your use of the Platform on our ability to verify the accuracy and completeness of this Content. If you do not provide required Content to us as requested, we may suspend or terminate your Account.
      </p>

      <p>
      If you are not at least 18 years old, you may not open an Account, access the Platform, or use the Services. Users (including Agents) who use the Services or the Platform to List or Book Spaces, on behalf of individuals under 18 years old, or provide access or use of Spaces by individuals under 18 years old, accept personal liability for all acts or omissions of such individuals.
      </p>

      <p>
        You may close your Account at any time by emailing us via <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>. You are responsible for all activity associated with your Account made before it is closed including payment of Fees, Taxes, or Fines; providing use of Spaces to Users as previously Booked; or other liabilities caused by or resulting from use of the Platform or Service. You understand that we may retain Content and continue to display and use any public Content (including comments or reviews of Spaces) provided to us prior to closing your Account. 
      </p>

      <p>
        At our sole discretion, we may suspend or terminate your Account as
        provided in <span className={css.enphasis}>Section 4.1 (b)</span>.
      </p>

      <p><strong>1.4 Compliance with Laws</strong></p>

      <p>
      As used in this Agreement, “Laws” means all applicable federal, state, local, and other governmental laws, regulations, ordinances, codes, rules, court orders, and all recorded and unrecorded private contract, restrictions, covenants and other agreements . You will comply with all Laws applicable to your use of the Space, Platform, whether as a Host, or User. While we may provide information to help you understand certain obligations of using or Hosting Spaces, we are not authorized to provide and do not provide any legal advice. You are solely responsible for your compliance with Laws and may only use the Platform or Service in compliance with applicable Laws. If you are unsure how to comply with Laws, you should seek legal advice related to Listing or Booking a Space.
      </p>

      <p><strong>1.5 Fees and Taxes</strong></p>

      <p>
      (a) <em>Fees and Overtime</em>. You are responsible for payment of fees, expenses, and other amounts related a to Booking (“Fees”). Fees include amounts paid to Gofynder and Hosts and as described in more detail on our Fees Overview. Gofynder receives Fees for providing use of the Service, and Hosts receive Fees for Bookings. The specific Fees charged are presented when you complete, update, or cancel a Booking. Please review all Fees before completing a Booking to make sure you understand the Fees for the Booking, including our Cancellation and Refund Policy. Please contact us if you have any questions about Fees or our Cancellation and Refund Policy.
      </p>

      <p>
      A Booking provides a limited use of a Space as described in the Listing and Use Agreement, if applicable. If you use a Space beyond the Booking you will be responsible for payment of additional Fees (“Additional Fees”) as described in the Fees Overview.  You are solely responsible for any damage done to the Space or the Amenities by anyone attending or working at your Session.  Hosts must notify us (via <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>) within 3 days of a User exceeding the permitted use of the Space and specifically request payment of Additional Fees.  Any dispute regarding Additional Fees will be resolved as described in Section 4.3.
      </p>

      <p>
      While we may facilitate the collection of Fees for Hosts, once remitted to Hosts, we are not responsible for the return of Fees to Users, where applicable.
      </p>

      <p>
        (b) <em>Taxes and Fines</em>. You are solely responsible for payment of all taxes, levies, penalties, and other costs imposed by any taxing authority or government agency related to Listing or Booking Spaces including any sales or occupancy tax, indirect taxes such as valued added tax (VAT) or goods and services tax (GST), usage or permitting fees, duties, and other taxes imposed by municipalities, states, or governments through regulation, ordinance, law, or judicial or regulatory interpretation (collectively “Taxes”). Except as required by Law, Gofynder will not calculate, track or pay Taxes or submit Tax reporting on your behalf. You are responsible for all Taxes owed for Booking or Listing a Space, without limitation, accurate calculation of Taxes due, timely remittance of Taxes to the appropriate taxing authority and maintenance of any required records and accounts. If any taxing authority demands that we pay such Taxes on your behalf, you are immediately liable to us for such Taxes and will reimburse or pay Gofynder for such Taxes upon demand. You are also responsible for any penalties arising from your failing to comply with this Agreement including those issued by regulatory or taxing authorities, law enforcement, fire code or safety agencies, or other third parties; or that may be issued by us for losses we or users incur that are based on your failing to comply with this Agreement or misuse of the Platform, Services, Space (collectively, “Fines”). You understand and agree that Gofynder does not provide you with any advice or guidance of any kind or nature regarding Taxes and that you have been advices to consult with your tax advisor for any required advice or guidance regarding Taxes.
      </p>

      <p>
        (c) <em>Payment</em>.  You will timely and fully pay any Fees, Deposits, Taxes, Fines, or other amounts you owe under this Agreement. If you owe amounts and we are unable to receive payment through the Platform for any reason, then we may require that you pay through other means (such as direct debit, wire transfer, or cashiers’ check). We may set-off any amounts owed to us through collection of funds that would otherwise be payable to you through the Platform. You are responsible for any costs or expenses associated with our recovering Fees, Taxes, or Fines owed, including our attorneys’ fees or expenses. In our discretion, any late payments of more than 7 days may incur a late charge of up to ten percent (10%) and accumulate interest of ten percent (10%) per month until delinquency is resolved.
    </p>

      <p><strong>1.6  Making or Receiving Payment</strong></p>

      <p>Acceptance and payment of funds between users or Gofynder on the Platform (“Payment Processing”) is provided by Stripe. Your use of Payment Processing is subject to the Stripe Connected Account Agreement that includes the Stripe Services Agreement as may be modified by Stripe from time to time (collectively, the “Stripe Agreement”) links to which can be found at the end of this agreement. As a condition using Payment Processing, you must provide accurate and complete information about you and your business and you authorize us to share this information to Stripe. All bank and credit card information is sent directly to and stored with Stripe using their security protocols. Gofynder does not store your payment information on its systems and shall not have any responsibility for the safety or security of that information. Your use of Payment Processing is conditioned upon your compliance with the Stripe Agreement, and if the Stripe Agreement is terminated by Stripe you may not be able to use the Platform, or have your Account suspended or terminated.
      </p>

      <p>We may change or add other payment processing services at any time upon notice to you, which may be subject to additional terms or conditions.
      </p>

      <p>You may not offer or accept payments using any method other than making a booking through Gofynder.  You may not offer direct payments using payment systems outside of the Gofynder platform.
      </p>

      <p><strong>1.7 Communication and Notices</strong></p>

      <p>We may communicate with you and provide you information or notices regarding your Account or transactions through email, telephone, text message or SMS, or through messaging on the Platform. You will promptly respond to any communications you receive and understand that failure to do so may impact your ability to Book or List Spaces, or use the Platform or Services.
      </p>

      <p>We may send you notices to the email address or physical address included in your Account, through messaging on the Platform, or (for businesses) to the address of your headquarters or registered business, or (for individuals) to your residence. You may send any legal notices to us at <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>. You agree that any email notice from us is considered accepted by you one day after such notice was sent and will have the same legal effect as if it were physically delivered to you.</p>
      
      <p>You agree to receive any communications from us and transact with us electronically. This acceptance of emails or other electronic messages constitutes your consent and your electronic signature has the same legal effect as physically signing a document. You may withdraw this consent to transact electronically at any point by providing notice to us. However, given that electronic communication is integral to the Platform and the Services, following any such notice we may elect to close your Account.</p>

      <p>
      To receive electronic communications you must keep your hardware and software up to date. If you
      are having problems receiving communications, please first verify that your hardware and software
      have all current updates installed. If you cannot resolve the issue yourself, please contact us via email
      at <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>.
      </p>
      <p><strong>1.8 Communication with Other Users</strong></p>
      <p>
      The Platform allows you to communicate with other users without disclosing sensitive personal contact information. It is your responsibility to use good judgment in the information you provide to other users. You may use the Platform only to List or Book Spaces, communicate with us or other users, resolve disputes, or use other functionality we provide to you through the Platform. You may not use the Platform to send messages that are unwanted or unrelated to a Listing or Booking through the Platform, use the Platform to harass or attempt to market other services to users, or send spam.
      </p>

      <p>
      We strongly recommend that you should use the Platform to communicate with other users. If you use other means of communication you understand that you may be putting your personal contact information at risk of misuse. You also understand that any communications made outside of the Platform may impair your ability to recover all or some amounts owed to you in the session of a dispute between you and another user. This is explained further in Section 4.3.
      </p>

      <p>
      Requesting or providing email addresses, Skype/IM usernames, telephone numbers or any other personal contact details to communicate outside of Gofynder in order to circumvent or abuse the Gofynder messaging system or Gofynder platform is not permitted. 
      </p>

      <p>
      We are not responsible for, and disclaim all liability resulting from, any losses or harm to you resulting from sharing personal or sensitive information with other users, or communicating or engaging with users outside of the Platform. More information regarding appropriate communications conduct when using Gofynder is included in our Community Guidelines.
      </p>
      <p>
      Gofynder, at its sole option and without notice or any obligation to do so, may from time to time (i) remove communications among users which contain or share personal contact information, or (ii) suspend or terminate the accounts of users that share personal contact information.
      </p>

      <p><strong>1.9 No Endorsement</strong></p>
      <p>
      We provide a Platform to help users connect with each other on the Platform but do not endorse any specific Users, Hosts, Spaces, or Vendors or Vendor Services registered with or offered through the Platform. Any designations or badges provided through the Platform are based on the information we have available to us which may be provided by other users or third parties and which may be inaccurate or out of date and do not constitute endorsement. Each user is independent, and Hosts and Vendors are independent contractors, unaffiliated with Gofynder, that are solely responsible for the character, quality, utility and provision of any Space or Vendor Service they provide or do not provide to you.
      </p>

      <p><strong>1.10 Miscellaneous </strong></p>
      <p>
      Gofynder does not provide protection for members who interact outside of the Gofynder platform.
      </p>
      <p>
      All information and exchanges must be performed exclusively on Gofynder’s platform.
      </p>
      <p>
      Rude, abusive, improper language, or violent messages will not be tolerated and may result in an account warning or the suspension/removal of your account.
      </p>
      <p>
      Gofynder is open to everyone. Discrimination against a community member based on gender, race, age, religious affiliation, sexual preference or otherwise is not acceptable and may result in the suspension/removal of your account.
      </p>
      <p>
      Users may not submit proposals or solicit parties introduced through Gofynder to contract, engage with, or pay outside of Gofynder.
      </p>

      <h2 id="plataformAndService">Section 2: Platform and Service</h2>
      <p><strong>2.1 Ownership, License, Restrictions</strong></p>

      <p>Gofynder owns all right, title, and interest in the Platform, Services, and all intellectual property embodied or contained in them (individually and collectively, “IP”). IP includes all registered or potential patents, copyrights, trademarks, trade secrets, and other proprietary rights. Through your Account, we grant you a limited, temporary, revocable, non-transferable, non-exclusive license to use the Platform and Service for the purposes described in this Agreement and only as provided to you through the Platform. This license does not constitute a transfer of ownership or grant you any additional rights to use the IP. You may not register, claim ownership in, or sublicense the Platform, Services, or IP; use the Platform, Service, or IP in violation of this Agreement or Laws; or reverse engineer or copy all or any portion of the Platform, Service, or IP (except as expressly permitted). We may suspend or close your Account and pursue legal action against you if we believe or determine that your use of the Platform, Service, or IP exceeds the scope of this grant; or that you are attempting to hack or disrupt the use of the Platform, Service, or IP by others; or that you are otherwise interfering with the normal operation of the Platform or Service.
    </p>

      <p>We may provide you logos or other digital media (“Logos”) to help you advertise your Space to potential Users. Where provided, you agree to use Logos only to advertise your Space. You may not use any Logos, the Platform, or the Service for any unlawful competitive use or in a manner that harms or tarnishes the Gofynder brand or reputation; and you are prohibited from modifying or using Logos in any manner that is inconsistent with our authorization or guidelines. We may terminate your right to use any Logos in our discretion upon notice to you in which session you shall cease using any such Logos.
    </p>

      <p><strong>2.2 Community Guidelines</strong></p>

      <p>We have established Community Guidelines that set our expectations for all users on the Platform.
      You will review and abide by the Community Guidelines whenever using the Platform or Services,
      communicating with other users, or using or providing use of Spaces. If you believe that another user
      is violating the Community Guidelines, please contact us via email at <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>. Gofynder
      shall have no duty to monitor users’ compliance with or to enforce the Community Guidelines and
      shall have no liability for any user’s violation of the Community Guidelines.</p>


      <p><strong>2.3 Content</strong></p>
      <p>
        (a) <em>Posting Content</em>. You represent and warrant that you are authorized to provide Content to the Platform and that any Content you provide does not violate the proprietary or privacy rights of a third party. You may not provide any Content that is copyrighted by third parties without their express permission. You grant Gofynder a fully-paid, worldwide, non-exclusive, perpetual license to use, copy, transmit, distribute, modify, publicly display, and sublicense any Content you provide to us. This grant includes our ability to use any Content for both internal use (such as analysis to improve the Platform or Services) or external use (such as in marketing or online advertising). If you cannot provide us the above grant then you may not provide Content to us. You agree to indemnify, defend and hold us harmless for any damages or losses based on third-party claims that Content violates proprietary or privacy rights.
      </p>

      <p>
        (b) <em>Restrictions on Certain Content</em>. Restrictions on Certain Content. You may never post any Content that (i) is defamatory, obscene, profane, or pornographic; (ii) is abusive, harassing, or disrespectful of other users; (iii) violates applicable Laws, including those prohibiting discrimination, false advertising, privacy, or unlawful marketing; (iv) is intended to deceive or mislead, is false or inaccurate, or misrepresents the nature or condition of a Space; (v) contains marketing or promotional content unrelated to the details of a Space; or (vi) includes sensitive personal information, including payment, contact information, or personal account details. You will not knowingly or negligently provide any Content that contain viruses, Trojan horses, and other harmful content (collectively, “Viruses”). While we attempt to identify any Viruses, we are not liable for and disclaim all responsibility for ensuring that Content is free from Viruses.
      </p>

      <p><strong>2.4 Privacy and Data Usage</strong></p>
      <p>
      Our Privacy Policy describes our collection, use, storage, and sharing personal information. This includes personal information included in Content and information collected through use of the Platform. We may anonymize, pseudonymize, or aggregate any information, including personal information or Content, and use this information for any purpose including improvement of the Platform or Services, or creating or distributing public marketing collateral.
      </p>

      <p><strong>2.5 Support, Feedback</strong></p>
      <p>
      We appreciate any ideas, suggestions, or feedback you voluntarily provide to help us improve the Platform (“Feedback”). Any Feedback you provide us is ours to use, register, modify, monetize, and otherwise use. You grant us all title and interest in any Feedback you provide to us and, if necessary, agree to assist us in establishing our ownership. You acknowledge that you will not receive any compensation for providing us Feedback.
      </p>

      <h2 id="gofynderServices">Section 3: Gofynder Services</h2>
      <p><strong>3.1 Listing Spaces - <em>This section applies to Hosts</em></strong></p>

      <p>
        (a) <em>Listings</em>. When you List a Space as a Host, you must provide details about the Space including a description, how it may be used, the cost of a Booking, a list of any facilities, current pictures, and other details about its potential uses and condition (collectively, “Description”). This includes details regarding use of any amenities, furniture, equipment, Host-provided Services available at an additional charge, or fixtures that are part of or in the Space (collectively, “Amenities”). While it is important to communicate excitement and the benefits of your Space, the Description must be accurate and give potential Users a reasonably good understanding of how they may use the Space for their Session. Descriptions may not include any contractual obligations or alter Users’ legal liabilities from those described in this Agreement including (i) modifying or expanding Users’ financial responsibilities or limit Users’ ability to seek recourse or restitution under this Agreement (unless expressly permitted by Gofynder when applicable in a Use Agreement ), (ii) violate the Community Guidelines or Laws, or (iii) require Users to pay you directly outside the Platform.
      </p>

      <p>
        (b) <em>Transacting with Users</em>. All Hosts must comply with our Community Guidelines when Listing a Space, providing a Description, and in transacting with Users. You are responsible for maintaining the Space and Amenities so that Users may reasonably use them as provided in the Description and Booking. Spaces and Amenities must be in good working order and provided to Users in a safe, clean and usable condition. Any plumbing, electrical, structural or other physical deficiencies or defects must be corrected before you List a Space. Except as specified in a Use Agreement, all water, electricity, HVAC and other utility services (exclusive of telephone and data services) are provided with the Space at no additional cost.
      </p>

      <p>
        (c) <em>Use Agreements</em>. You may elect to require a Use Agreement with your Listing that includes all or part of the Description including a listing of any Amenities and any additional terms, conditions, and/or waivers. The Use Agreement will incorporate this Agreement and supplement and be a part of the binding agreement between you and a User. Except where approved by Gofynder, Use Agreements may not impose rules or limitations on use that are materially different than those listed in the Description, include any additional contractual obligations, or alter Users’ or Hosts’ liabilities.
      </p>

      <p>
        (d) <em>Recordings</em>. During their Session, Users may photograph, film, or otherwise record sessions when using Spaces and Amenities (“Session Recordings”). Except as expressly prohibited by federal, state, local or other governmental Laws or as otherwise set forth in the Description or any Use Agreement , you grant to any User that Books a Space a fully-paid, worldwide, non-exclusive, perpetual license to use, copy, transmit, distribute, modify, publicly display, and sublicense Session Recordings that may include or identify the Space or Amenities.
      </p>

      <p>
        (e) <em>Conduct and Fees</em>. As a Host, you are solely responsible for ensuring that Spaces and Amenities comply with all applicable Laws including any local ordinances related to the condition, licensure, or registration Spaces for use by Users, and payment of Taxes. We may condition your continued use of the Platform and Services on your providing proof, to our reasonable satisfaction, of your compliance with Laws at any time. All Fees owed by Hosts are subject to the Fees Overview and Cancellation and Refund Policy.
      </p>

      <p>
        (f) <em>Preparation for and Supervision of Session</em>. Preparation for and Supervision of Session. As a Host, you are solely responsible for (i) preserving and protecting your Space and Amenities by removing and/or securing valuable, vulnerable or sensitive items, (ii) determining the appropriate types and intensities of permitted uses in your Space, (iii) evaluating the appropriateness of potential Users, and (iv) supervising and monitoring the Space and Amenities and your User’s use of the Space and Amenities for the Session, to the extent (if any) you as Host determine in your sole judgment.
      </p>

      <p>
        (g) <em>Cleaning and maintaining your space</em>. As a Host, you agree to properly maintain and clean your space at regular intervals and/or prior to all bookings.  Cleaning your space will include all of the following that apply to your space: sweep, mop, vacuum, and clean the entire space (including all equipment) using CDC recommended cleaning supplies.  Cleaning will also include cleaning all common areas including bathrooms and/or lockers rooms.  Hosts will also provide a hand washing station with soap, warm water, and paper towels or hand sanitizer with at least 60% alcohol. 
      </p>

      <p><strong>3.2 Booking Spaces - <em>This section applies to Users</em></strong></p>
      <p>
        (a) <em>Bookings</em>. As a User, you should review the Description and availability to confirm they are appropriate for your Session before Booking a Space. The Platform allows you to confirm any details or ask the Host any specific questions about the Space, or confirm details of a Booking, without sharing your personal contact information. Prior to Booking, you will need to provide payment information through the Platform. Booking Fees will be shown before you complete your Booking. You are responsible for all Fees and Taxes associated with the Booking. All Bookings are subject to our Cancellation and Refund Policy.
      </p>

      <p>
      When you Book a Space, you are only provided a license to use the Space as described in the Booking and confirmed by the Host, subject to this Agreement and any Use Agreement. A Booking does not provide you a lease or access or use of the Space beyond the specified time and Description.
      </p>

      <p>
        (b) <em>Conduct and Fees</em>. You will comply with the Community Guidelines and any Use Agreements throughout the Booking and use the Space only as permitted or agreed upon and consistent with the Description and assure that any attendees do the same. During your Booking, you are responsible for (i) the behavior and acts of any attendees, service providers, or others that access the Space, (ii) ensuring that the use does not exceed any limitations identified in the Booking, (iii) coordinating the timely setup or breakdown of your Session, or (iv) complying with applicable Laws including acquiring any required licenses or permits for your Session, hiring security personnel for larger Sessions, or limiting noise to certain times of the day. You are responsible for and accept all liability for any damage done to the Space or Amenities during your Session by attendees or service providers whether intentional or not, for your failure to comply with applicable Laws, and for any Fines you incur. You agree to return the Space to the Host in substantially the condition as provided to your or as otherwise agreed in any Use Agreement and consistent with the Community Guidelines, and to promptly notify Hosts of any damage done to the Space or Amenities. All Fees owed by Users are subject to the Fees Overview and Cancellation and Refund Policy.
      </p>

      <p>
        (c) <em>Use of Facilities. </em>By using the Platform, you are agreeing that you are either an individual who understands your own medical conditions and care thereof, or you are a certified fitness or wellness professional and/or instructor with proper education and knowledge in the activity you are leading, plus you are familiar with the medical conditions of all students and clients under your supervision during the session rented on Gofynder. In addition, you have a valid business license to conduct classes and the proper insurance for your profession is valid at the time of your use of the Space.
      </p>

      <p>
      You, the User, are obligated to use the Space you rent in a professional, courteous and workmanlike manner. You are also solely responsible for the clients/guests you bring to the Space. You and your guests shall not knowingly or intentionally steal, damage or permanently alter anything, in any way, during or after your use. You obey the restrictions and guest limits listed in the Platform for that Space. You shall ensure you leave the space in the same condition as you entered it. You report any accidental or intentional damage of the space and any equipment you used to the host.  Gofynder and hosts are not responsible for injuries.  Gofynder is not responsible for any damage or theft of your or your clients’/guests’ belongings.
      </p>

      <p>
      In addition, You and your guests may not promote any local competing facility while in the host space, this includes wearing any apparel or uniform from another gym/studio. You and your guests may not approach anyone in the host space, for any reason, including solicitation. If anyone asks you questions regarding the host space, you will refer them to a host representative.
      </p>

      <p>
      If you bring any minors (anyone under the age of 18 years old) into the Space, parental consent is required for each minor. If no parental consent is provided to the Space, the Host has the option to not allow that individual to participate in your class. A Host also has discretion over the occupancy and capacity of its Space. Various exercises demand different amount of square footage space per person. If you plan to bring many clients, it is your responsibility to confirm the number with the Host is acceptable for the Space.
      </p>

      <p>
      By using this site and any Spaces listed on the site, you hereby certify and acknowledge that you are educated and skilled in the classes you are leading, instructing and/or teaching. Plus, you have assessed all your students, guests and/or clients to ensure they are healthy enough to participate in your class.  Gofynder and the Hosts listed on the site are not responsible for your experience and skill level, and it is highly recommended /required that you carry your own professional liability insurance. 
      </p>

      <p><strong>3.3 Required and Supplemental Insurance</strong></p>
      <p>
        (a) <em>Required Insurance</em>. Every member will acquire and maintain all insurance as required by Law and suitable for you or your business. You are solely responsible for understanding and evaluating what insurance is appropriate to cover damage, loss, injury, legal liability, and other harm specific to you, your business, those attending the Session, third parties, the Space or Amenities, and deciding what coverage, limits and providers are appropriate for you.
      </p>

      <p>
        (b) <em>Supplemental Insurance</em>. Gofynder may, in its sole discretion, obtain and maintain supplemental insurance policies providing limited supplemental protection of Hosts against liability to Users, for personal injury or property damage sustained by Users (a “Host Policy”). Where we elect to provide a Host Policy, this does not eliminate or reduce your obligation to maintain insurance as provided in Section 3.3(a). Further, where provided, recovery under a Host Policy will be (i) available only where a Host fully complies with this Agreement, (ii) supplemental to any insurance maintained by Hosts, (iii) limited to the terms, conditions, and exclusions of the Host Policy, (iv) subject to the then applicable claims procedure established by Gofynder and the issuer of the Host Policy, and (v) subject to a Host promptly notifying Gofynder of any potential claims. Further, Gofynder or its insurers may amend, modify, or terminate any Host Policy at any time, with or without notice to you.
      </p>

      <p><strong>3.4 Cancellations and Refunds</strong></p>
      <p>
      All cancellations and any refunds that may be available to you are subject to our Fees Overview and Cancellation and Refund Policy.
      </p>

      <h2 id="terminationDisputes">Section 4: Termination, Disputes, and Binding Arbitration</h2>
      <p><strong>4.1 Term, Termination, and Survival</strong></p>
      <p>
        (a) <em>Term and Termination By User</em>. You consent to this Agreement when you first access or use the Platform, and your continued use of the Platform constitutes your ongoing consent to this Agreement as amended from time to time. You may terminate this Agreement by closing your Account with us, however this will not immediately terminate any ongoing rights or obligations you or we may have. This includes any obligations to pay for Bookings, to honor any Bookings made before termination, or to pay Fees, Taxes or Fines due; or any liabilities that you incurred prior to termination.  You can request the termination of your Account and all personal data by sending an email to hello@gofynder.com
      </p>

      <p>
        (b) <em>Suspension or Termination by Gofynder</em>. We may suspend your Account--including your ability to communicate with other users or receive payments, or complete a Booking--or terminate this Agreement and your Account at any time including, without limitation, (i) if we believe that use of your Account poses a risk to Gofynder, you, other users, or third parties, (ii) actual or potential fraud by you or on your behalf, (iii) your failure to respond to communications from us or other users, or (iv) your failure to comply with this Agreement or applicable Law.
      </p>

      <p>
        (c) <em>Survival</em>. The following provisions will also survive termination of this Agreement: Sections 1.3 (Compliance with Laws), 1.4 (Fees and Taxes), 1.6 (Communication and Notices), 1.7 (Communication with Other Users), 1.8 (No Endorsement), 2.1 (Ownership, License, Restrictions), 2.3 (Content), 2.4 (Privacy and Data Usage), 2.5 (Support, Feedback), Section 3.1(d)(Recordings) and (e)(Conduct and Fees) for Hosts, Section 3.2(b) (Conduct and Fees) for Users, 3.3 (Required and Supplemental Insurance), 3.4 (Cancellations and Refunds), Section 4 (Termination, Disputes, and Binding Arbitration), and Section 5 (Additional Legal Terms).
      </p>


      <p><strong>4.2 Binding Arbitration</strong></p>
      <p>
      This section describes how disputes or claims arising under this Agreement between you and Gofynder or between you and another user (not resolved through the process set forth in Section 4.3) will be resolved. It includes waivers to both a jury trial and your ability to join other plaintiffs as part of a class action. Please read this section carefully before accepting this Agreement—you may not use the Platform or Services if you do not agree to this section.
      </p>

      <p>
        (a) <em>Process for Arbitration</em>. Subject to the exclusions provided in Section 4.2(b) and the process provided in Section 4.3, all disputes, claims, and controversies arising under or related to this Agreement between you and Gofynder or between you and another user (if not resolved pursuant to Section 4.3 below) will be resolved through binding arbitration as follows:

      </p>

      <p>
        •	(i) if the amount of the dispute, claim, or controversy is reasonably less than $25,000, resolution shall be administered online by FairClaims (www.fairclaims.com) or another online arbitration provider of our choosing in accordance with their applicable arbitration rules and procedures effective at the time a claim is made. You consent to receive electronic service of process at the email associated with your Account. Where you are delinquent in responding to such process, you will be responsible for any attorney, court, or other fees associated with the delinquency. The party filing the User Dispute will be responsible for payment of any costs associated with that filing, including costs borne by Gofynder. As a part of the User Dispute, you may also seek to recover these costs if you prevail.
      </p>
      <p>
        •	(ii) if the amount of the dispute, claim or controversy is reasonably $25,000 or more, resolution shall be before a single arbitrator and administered by JAMS. This includes but is not limited to any statutory or common law claims relating to breach, enforcement, or interpretation of this Agreement and any Use Agreement. Any such arbitration will take place in the county where the Booked or Listed Space is located, unless you and Gofynder mutually agree otherwise. The arbitrator will apply the substantive Laws of California. All claims from $25,000 to $250,000 shall be subject to the JAMS Streamlined Arbitration Rules. The Federal Arbitration Act (9 U.S.C. §§ 1-16) will govern all such arbitrations under this Agreement. To initiate such an arbitration, a party will provide a written demand that states both the basis of the claim and the desired relief. Each party irrevocably and unconditionally consents to service of process through personal service at their corporate headquarters, registered address, or primary address (for individuals or sole proprietors). Nothing in this Agreement affects the right of any party to serve process in any other manner permitted by Law.
      </p>

      <p>
      Once arbitration is initiated as provided in Subsections (i) or (ii) above, the parties will share the costs of the arbitration, facilities, and arbitration reporters (as necessary) equally except as otherwise determined by the arbitrator. Each party will be responsible for its own attorneys’ fees and legal costs. The arbitrator may award the prevailing party recovery of any of the costs of arbitration or legal fees as they see appropriate.
      </p>

      <p>
      The arbitrator may provide for any monetary or other remedies that are available under applicable Law but may not modify the terms of this Agreement or any Use Agreement. The arbitrator will provide a reasoned decision addressing the specifics of the dispute. The decision is binding and not subject to appeal. The parties will act promptly to respect the decision of the arbitrator, including payment of any amounts owed or taking of any action required.
      </p>

      <p>
      Any judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction.
      </p>

      <p>
        (b) <em>Certain Claims Excluded</em>. Notwithstanding Section 4.2(a), the parties agree that any claims based on ownership or misuse of the other party’s intellectual property—including patents, copyrights, or trademarks—may be brought before the state or Federal courts located in San Diego, California. Either party may also seek provisional remedies for injunctive relief under such claims from a court of competent jurisdiction.
      </p>

      <p>
          (c) <em>Class Action and Jury Waiver</em>. Each party agrees that any action or claim arising from or related to this Agreement or any Use Agreement may only be brought on an individual basis and not part of a class action or consolidated arbitration or join claims with other users or third parties. Further, each party expressly waives its right to a jury in arbitration and court, where permitted. You may opt-out of this class action and jury waiver described in Section 4.2(c) by emailing us at <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a> within 30 days of your first use of the Platform or Services. You must include your name, phone number, physical address, and email address in your opt-out notice. This is your only mechanism for opting out of this Section 4.2(c) and failure to do so as described constitutes your consent to this waiver. If you choose to opt out of this Section 4.2(c), please note that all other provisions in this Agreement will remain intact and in full force and effect.
      </p>

      <p>
        (d) <em>Conflict of Rules</em>. If any provision of this Section 4.2 is found to be invalid or unenforceable, the reviewing court or arbitrator, as applicable, will interpret or revise the provisions only as minimally necessary to comply with Law. All the other provisions will remain enforceable and intact as written.
      </p>

      <p><strong>4.3 Disputes Between Users</strong></p>
      <p>
      This section describes how disputes or claims arising under this Agreement between you and another user will be resolved. Please read this section carefully before accepting this Agreement—you may not use the Platform or Services if you do not agree to this section.
      </p>

      <p>
        (a) <em>Initial User Dispute Resolution</em>. You agree to first attempt to resolve any disputes, disagreements, or claims that you have with other users (“User Dispute”) in good faith through the Platform. If you are unable to resolve the User Dispute, you then will submit the User Dispute to us for review by submitting a written summary of the User Dispute, including any documentation, to us at  <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>.  In our sole discretion, we may (i) require that you submit additional details regarding any User Dispute, and/or (ii) hold any pending payments or reverse any payments already made to you and hold such payments pending final resolution of the User Dispute. We will review the summary and communications made on the Platform. We may, but are not required to, also review communications made outside of the Platform. Upon review and investigation, we will either (a) provide you and the other user our conclusion based on the summary provided, which you agree to accept as final and binding determination with the same force and effect as if determined through arbitration as provided in Section 4.2; or (b) require that the dispute is resolved through binding arbitration adjudicated by a third party as provided in Section 4.2(a). The party filing the User Dispute will be responsible for payment of any costs associated with that filing, including costs borne by Gofynder. As a part of the User Dispute, you may also seek to recover these costs if you prevail. Any payments held by Gofynder pending final resolution of a User Dispute shall be disbursed by Gofynder as determined by Gofynder, the arbitrator, or a court of competent jurisdiction.
      </p>

      <p>
        (b) <em>User Disputes Under $25,000</em>. After following the process outlined above, if the User Dispute relates to an amount reasonably less than $25,000, you agree to submit the User Dispute to binding arbitration as provided in Section 4.2(a)(i) above.
      </p>

      <p>
        (c) <em>User Disputes of $25,000 or higher</em>. After following the process outlined above, if the User Dispute relates to an amount reasonably $25,000 or more, you agree to submit the User Dispute to binding arbitration as provided in Section 4.2(a)(ii) above.
      </p>

      <p><strong>4.4 Confidentiality of Proceedings</strong></p>
      <p>
      Any proceedings pursuant to this Section 4 and their results will be maintained as confidential by all parties. Except as may be required by Law, the parties and those persons participating in the proceedings on their behalf will not disclose and will maintain the confidentiality of all materials, testimony, and evidence provided during the proceeding as well as the results of such proceeding. The parties agree to enter into a separate confidentiality agreement or order, as appropriate, to maintain the confidentiality of the proceedings.
      </p>

      <h2 id="additionalLegalTerms">Section 5: Additional Legal Terms</h2>
      <p><strong>5.1 Right to Amend</strong></p>
      <p>
      We may amend or modify this Agreement at any time by posting the modified Agreement on our website, sending you a copy via email, or otherwise communicating the amendment to you through the Platform. Your continued use of the Platform or Services after we amend or modify this Agreement constitutes your consent to the revised Agreement. If you do not agree to the revised Agreement, you must close your Account by contacting us via <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>.
      </p>

      <p><strong>5.2 Force Majeure; Cancelations</strong></p>
      <p>
      Gofynder is not responsible or liable for nonperformance caused by telecommunications failures, nonperformance of vendors, fires or other acts of nature, strife or acts of political discord, or other sessions outside its reasonable control (each a “Force Majeure”).
      </p>

      <p>
      Cancellations of Bookings, whether with or without cause, or caused by sessions outside of your reasonable control, are subject to our Cancellation and Refund Policy. Please review it carefully before Booking a Space.
      </p>

      <p><strong>5.3 No Assignment</strong></p>
      <p>
      You may not assign this Agreement, or any rights granted to you, including operation or management of your Account, without our prior written consent. Any attempt to do so without our prior consent will be void. We may assign this Agreement upon notice to you as part of a sale or transfer of part or all of our business. Any permitted transfer will inure to the benefit of and bind any successors in interest.
      </p>

      <p><strong>5.4 Disclaimers</strong></p>
      <p>
      GOFYNDER PROVIDES THE PLATFORM AND SERVICE TO LIST AND BOOK SPACES AND VENDOR SERVICES, AND COMMUNICATE WITH OTHER USERS. WE ARE NOT RESPONSIBLE TO YOU OR ANY THIRD PARTY FOR PROVIDING, OR FOR THE CONDITION OR NATURE OF, ANY SPACE OR VENDOR SERVICES. HOSTS, USERS, AND VENDORS ARE INDEPENDENT THIRD-PARTIES AND ARE NOT AFFILIATED, CONTROLLED BY, OR EMPLOYED BY Gofynder. HOSTS AND VENDORS SET THEIR OWN PRICES, USE THEIR OWN FACILITIES AND RESOURCES, AND MAY CONDITION USE OF SPACES OR VENDOR SERVICES AS THEY FEEL IS APPROPRIATE FOR THEIR BUSINESS.
      </p>

      <p>
      YOU UNDERSTAND AND AGREE THAT USE OF THE PLATFORM AND SERVICES ARE AT YOUR OWN RISK. Gofynder IS NOT RESPONSIBLE FOR PERFORMING AND DOES NOT PERFORM BACKGROUND CHECKS ON USERS, HOSTS, OR VENDORS (INCLUDING CRIMINAL OR CIVIL CHECKS); DOES NOT REVIEW THE CONDITION OF SPACES OR ENSURE THAT THEY COMPLY WITH EXISTING LAW, MEET ANY SPECIFIC REQUIREMENTS, OR ARE CONSISTENT WITH THEIR DESCRIPTION; AND DOES NOT GUARANTEE PERFORMANCE OF ANY USER OR THIRD PARTY. ANY INFORMATION PROVIDED TO YOU REGARDING USERS, SPACES, VENDORS OR VENDOR SERVICES IS ONLY PROVIDED TO FACILITATE YOUR USE OF THE PLATFORM AND IS PROVIDED “AS IS” AND WITHOUT ANY REPRESENTATION OR WARRANTY. GOFYNDER DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS AND IMPLIED, THAT ANY INFORMATION PROVIDED THROUGH THE PLATFORM IS ACCURATE OR COMPLETE, OR THAT ANY USER, SPACE, VENDOR OR VENDOR SERVICE WILL BE PROVIDED TO YOU AS DESCRIBED. Gofynder DISCLAIMS ALL RESPONSIBILITY FOR AND LIABILITY RESULTING FROM THE NEGLIGENCE, INTENTIONAL MISCONDUCT, OR CRIMINAL ACTIVITY OF ALL USERS OR THIRD PARTIES, OR ANY INJURY OR PROPERTY DAMAGE THAT OCCURS TO YOU, YOUR CLIENTS, THIRD PARTIES, OR PROPERTY WHILE ACCESSING OR USING SPACES OR VENDOR SERVICES.
      </p>

      <p>
      THE PLATFORM AND SERVICES ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY GUARANTEE OR WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SUITABILITY FOR YOUR PARTICULAR USE, OR NONINFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE DISCLAIM THAT THE PLATFORM OR SERVICES ARE FREE FROM ERROR OR VIRUSES; THAT THEY WILL NEVER CAUSE HARM; THAT THEY MEET OR BE SUITABLE FOR YOUR NEEDS OR REQUIREMENTS; THAT THEY ARE ALWAYS AVAILABLE; OR THAT THEY ACCURATELY REPRESENT SPACES, HOSTS, USERS, VENDORS OR VENDOR SERVICES. WE EXPRESSLY DISCLAIM ANY OBLIGATION TO CORRECT ERRORS, EVEN THOSE THAT WE ARE AWARE OF, AND MAY CHOOSE TO DISCONTINUE OPERATION OF THE PLATFORM OR SERVICE, OR FEATURES OF EITHER, AT ANY TIME.
      </p>

      
      <p><strong>5.5 Representations and Warranties</strong></p>
      <p>
      By opening your Account, you represent and warrant that (a) you are authorized to use the Platform and Services and have authority to execute this Agreement; (b) that you have only one Account with us and have not had an Account terminated by us previously; (c) all information provided to us is accurate and complete; (d) you will not use the Platform in violation of Law or the Community Guidelines, to commit fraud, to deceive other users, or for any other improper purpose; and (e) you are authorized to provide any Content to us.
      </p>

      <p>
      When using the Platform or Services as a Host, you further represent and warrant that (f) you are permitted under applicable Laws to List any Spaces provided; (g) your Listing of a Space and the Space itself comply with applicable Laws; and (h) you will reasonably facilitate the use of, and not obstruct the use of, the Spaces as Booked by Users.
      </p>

      <p>
      When using the Platform as a User, you further represent and warrant that (i) you will comply with any Use Agreements; (j) you will not use Spaces in a manner that violates Laws or Community Guidelines, or that facilitates the violation of either by third parties; and (k) that any payment Credentials provided to us may be used as described in this Agreement.
      </p>

      <p><strong>5.6 Indemnities</strong></p>
      <p>
      You will indemnify, defend and hold Gofynder and its agents, directors, shareholders, members, partners, managers, officers, employees, information providers, distributors, vendors, attorneys, or affiliates (collectively, “Gofynder Affiliates”) harmless against all third-party claims, liabilities, losses, damages, and related expenses (including reasonable legal expenses) (collectively, “Claims”) arising from or related to (a) provision of your Space (for Hosts), or use of Spaces (for Users); (b) Content you provide through the Platform; (c) your failure to comply with Laws; (d) your providing of information to us that is inaccurate or incomplete, (e) your breach of any of your obligations under this Agreement, and (f) any contract or other agreement between you and any other user other than through the Platform. This indemnification will survive termination of this Agreement.
      </p>

      <p><strong>5.7 Limitations of Liability</strong></p>
      <p>
      EXCEPT TO THE LIMITED EXTENT OF THE GOFYNDER FEES (AS DEFINED BELOW) UNDER NO CIRCUMSTANCES OR ANY LEGAL THEORY WILL GOFYNDER OR ITS AGENTS, DIRECTORS, SHAREHOLDERS, MEMBERS, PARTNERS, MANAGERS, OFFICERS, EMPLOYEES, INFORMATION PROVIDERS, DISTRIBUTORS, VENDORS, ATTORNEYS, OR AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES (WHETHER DIRECT, INDIRECT, GENERAL, COMPENSATORY, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, EXEMPLARY OR OTHERWISE), INJURY, CLAIM OR LIABILITY OF ANY KIND OR CHARACTER BASED UPON OR ARISING FROM YOUR USE OF OR INABILITY TO USE THE PLATFORM OR SERVICES, OR SPACES OR VENDOR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. IF YOU ARE DISSATISFIED WITH THE PLATFORM OR SERVICES, OR ANY CONTENT CONTAINED THEREIN, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE PLATFORM AND SERVICES.
      </p>

      <p>
      AS USED HEREIN, “Gofynder FEES” SHALL MEAN THE AMOUNT PAID BY YOU TO GOFYNDER, EXCLUDING ANY AMOUNTS THAT WERE PAID OR PAYABLE TO HOSTS, FOR USE OF THE PLATFORM OR SERVICES.
      </p>

      <p>
      THE LIMITATION OF LIABILITY SET FORTH ABOVE APPLIES TO THE EXTENT PERMITTED BY LAW.
      </p>

      <p><strong>5.8 Entire Agreement; Interpretation</strong></p>
      <p>
      This Agreement together with any Use Agreement incorporates the Community Guidelines, Fees Overview, Privacy Policy, Cancellation and Refund Policy, and any other policies referenced therein, which constitute the entire agreement between you and Gofynder governing your use of the Platform or Services. This Agreement supersedes all prior understandings or agreements between you and Gofynder. As between you and Gofynder, this Agreement controls over any conflicting terms in a Use Agreement except where expressly stated otherwise and agreed upon in writing between the parties.
      </p>

      <p>
      As used in this Agreement, “including” or similar words mean “including but not limited to”; “or” may be read as “either… or…” or “both… and…”; and “such as,” “specifically,” “for example,” or similar words identify non-exclusive lists of examples. Headings and ordering used in this Agreement are provided for your convenience and do not affect the interpretation of related provisions. Any monetary amounts described in this agreement will be in USD and “$” will be read to mean United States Dollars.
      </p>
      <p>
      Except as provided in Section 5.1 above, this Agreement may only be modified through a written agreement that is signed by both you and Gofynder. Where any part of this Agreement is found to be invalid or unenforceable, it will be reformed or reinterpreted through force of Law as minimally required to accomplish the objectives while maintaining the original intent of such provision.
      </p>

      <h1>Gofynder Payment Terms &amp; Fees</h1>

      <p>Last Updated: January, 2022</p>
      <p>
      This Gofynder Fees Overview specifies the Fees owed by you and collected by Gofynder when Listing or Booking on the Platform. This Fees Overview specifically identify Fees owed by Users, Hosts, and how Fees are paid to Gofynder.
      </p>

      <p>
      The Fees Overview is part of and uses words and phrases defined in the Gofynder Services Agreement. All Users acknowledge and agree that all payments shall be made in accordance with this Fees Overview. If you have any questions about the Fees Overview, please contact us via email at   <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>.
      </p>

      <h2>User &amp; Host Fees</h2>

      <p>
      We may authorize a hold for the amount of the Fees to your credit card or other payment methods linked to your Account when you request a Booking. When a Booking is accepted by a Host, we will charge your Account for the full amount of the Fees. Fees include amounts ultimately paid to Hosts and processing Fees payable to Gofynder. Host Fees will include the total amount Booked. If you update a Booking, we may adjust the Host Fees accordingly which may result in either additional amounts owed, or a refund of amounts paid.
      </p>

      <h2>Fee Schedule</h2>
      <p>
      Fees charged to Users and Hosts will use the schedule listed here:
      </p>

      <p>
        <strong>By the Hour Bookings – </strong>
        Users and Hosts will each be charged a commission of 6% of the booking total cost.  
      </p>
      <p>
        <strong>By the Day Bookings – </strong>
        Users and Hosts will each be charged a commission of 4% of the booking total cost.  
      </p>
      <p>
        <strong>By the Month Bookings – </strong>
        Users and Hosts will each be charged a commission of 4% of the booking total cost 
      </p>
      <p>
        <strong>By the Class Bookings – </strong>
        Users and Hosts will each be charged a commission of 4% of the booking total cost.
      </p>

      <h2>Other Fees</h2>
      <p>
      Additional Fees. Where you exceed the use limitations of a Space, additional Fees may apply. For overtime use of a Space, the Additional Fees will be based on the rate(s) for the Space, at one and half times the scheduled rate, and will be billed in 30-minute increments. For over-intensive use of a Space (e.g., materially exceeding the agreed number of attendees), in our sole discretion we may impose an additional use charge. We may bill any Additional Fees when first notified that the agreed-upon time for use was exceeded and may charge any payment method tied to the User’s Account. Any disputes relating to Additional Fees are subject to the dispute resolution process identified in the Service Agreement.
      </p>
      <p>
      Fines for Damages and Other Circumstances. While we try to avoid these situations, occasionally the terms or expectations of a Booking are not met and Fines may be owed by a user. This may be caused by damage to or misuse of a Space or Amenities, excess garbage left at a Space, violations of the Use Agreement or the Community Guidelines, insufficient funds or unauthorized use of a payment account, or other claims. Where any Fines are owed, you agree that we may immediately charge any payment method linked to your Account.
      </p>
      <p>
      Cancellations. All Fees are subject to our Cancellation and Refund Policy.

      </p>

      <h1>Gofynder Cancellation and Refund Policy</h1>
      <p>Last Updated: January, 2022</p>
      <p>
      Gofynder designed the Platform and Services to help Hosts and Users efficiently and conveniently complete Bookings. Every Booking is a Host’s commitment to make a Space available to the User who has paid in full upfront upon confirmation. When Users cancel Bookings, the Host’s ability to Book that Space during that time becomes increasingly difficult as other interested Users may have already made other plans. When Hosts cancel Bookings, Users’ sessions and plans can be severely impacted and may require intensive logistical support from Gofynder. Nonetheless, plans may change, requiring Users or Hosts to cancel Bookings on occasion.
      </p>

      <p>
      This Gofynder Cancellation and Refund Policy ("Policy") covers all cancellations or refunds for Fees paid on the Platform, which are identified in the Fees Overview. Both Users and Hosts agree that they are subject to and will comply with the terms, conditions, and procedures of this Policy when cancelling a Booking. This Policy is part of and uses words and phrases defined in the Gofynder Services Agreement. If you have any questions about this Policy, please contact us via  <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>.
      </p>

      <p>
      You must agree to this Policy, as part of the Gofynder Services Agreement, to use the Services and the Platform. Please read this Policy carefully. You may not use the Platform or Services if you do not agree to this Policy.
      </p>

      <p>
      Gofynder may issue payments to Hosts, withhold refunds to Users, or collect cancellation Fees from Hosts in accordance with this Policy. We reserve the right to amend this Policy for any reason and at any time by posting an updated version to our website as provided in the Gofynder Services Agreement.
      </p>

      <h2>Host Initiated Cancelation</h2>
      <p>
      Hosts may cancel a Booking at any time but agree to the terms outlined below.
      </p>

      <p>
        * By the Hour Bookings - Should the Host initiate a cancellation   <u>the user will receive a full refund</u>.
      </p>
      <p>
        * By the Day Bookings - Should the Host initiate a cancellation    <u>the user will receive a full refund</u>.
      </p>
      <p>
       * By the Month Bookings – Should the Host initiate a cancellation of a monthly subscription prior to the start of the booking the user will receive a full refund.  If a host cancels a subscription after it has started. the user will be able to use the remaining time in their current 30 day booking, but the subscription will not renew after those days have been used.  The host will be paid their full fee minus the normal GF fees.
      </p>
      <p>
        * By the Class Bookings – Should the Host initiate a cancellation of a By the Class subscription prior to the start of the booking the user will receive a full refund.  If a host cancels a subscription after it has started the user will be able to use the remaining time slots in their current 4 week block, but the subscription will not continue after those time slots have been used.  The host will be paid their full fee minus the normal GF fees. 
      </p>
      <p>
        *	The Space is No Longer Available – n the rare cases where a space is no longer available (for whatever reason) and the user is unable to complete their booking.   Refunds (amounts and logistics) will be determined by the team at Gofynder on a case by case basis.   
        <em>If you are experiencing this issue please contact us at </em>
       <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>
        <em> for immediate assistance.</em>
        <em></em>
      </p>

      <p>
      Refunds to the User will be initiated by Gofynder within 7 days of finalized cancellation.  Refund processing times may vary by bank and payment method.  (please see Stripe User Agreement for more detail) Gofynder will collect processing fees from Host immediately following the cancellation request. 
      </p>

      <h2> User Initiated Cancelation</h2>
      <p>
        Users may cancel a Booking at any time but agree to the terms outlined below. 
      </p>
      <p>
        * By the Hour Bookings – Should the User initiate a cancellation more than 72 hours ahead of the scheduled start time of the booking <u>the user will receive a full refund</u>. Should the User initiate a cancellation 72 hours or less from the scheduled start time of the booking <u>they will forfeit their entire booking fee</u> and the host will be paid for the space booking minus the normal Gofynder fees.
      </p>
      <p>
        * By the Day Bookings – Should the User initiate a cancellation more than 72 hours ahead of the scheduled start time of the booking (the earliest time the space is available on the day they booked) <u>the user will receive a full refund</u>. Should the User initiate a cancellation 72 hours or less from the scheduled start time of the booking (the earliest time the space is available on the day they booked) <u>they will forfeit their entire booking fee</u> and the host will be paid for the space booking minus the normal Gofynder fees.
      </p>
      <p>
        * By the Month Bookings – Should the User initiate a cancellation of monthly a subscription more than 72 hours before the start of the booking the user will receive a full refund.  If a user cancels a subscription less than 72 hours before the start of the booking, but before their booking starts they will be charged for a pro-rated amount equal to 7 days worth of space time and receive the rest of their booking fee as a refund.  If a user cancels a subscription after it has started the user will be able to use the remaining time in their current 30 day booking, but the subscription will not renew after those days have been used.  The host will be paid in full, minus the normal GF fees.
      </p>
      <p>
        * By the Class Bookings – Should the User initiate a cancellation of a By the Class Booking subscription more than 72 hours before the start of the booking the user will receive a full refund.  If a user cancels a subscription less than 72 hours before the start of the booking, but before their booking starts they will be charged for the equivalent of one class and receive the rest of their booking fee as a refund.  If a user cancels a subscription after it has started the user will be able to use the remaining time slots in their current 4 week block, but the subscription will not renew after those time slots have been used.  The host will be paid in full minus the normal GF fees.
      </p>

      <p>
      Refunds to the User will be initiated by Gofynder within 7 days of finalized cancellation.  Refund processing times may vary by bank and payment method.  (please see Stripe User Agreement for more detail) Gofynder will collect processing fees from User immediately following the cancellation request.
      </p>

      <h2>Stripe Agreement Information</h2>
      <p>
        Please visit: <a href="https://stripe.com/us/ssa">https://stripe.com/us/ssa</a>
      </p>

      


    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
