import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingAvailabilityAndPricingForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './EditListingAvailabilityAndPricingPanel.css';

const { Money } = sdkTypes;

const EditListingAvailabilityAndPricingPanel = props => {
    const {
        className,
        rootClassName,
        listing,
        disabled,
        ready,
        onSubmit,
        onChange,
        submitButtonText,
        panelUpdated,
        updateInProgress,
        errors,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const { bookingOptions, spaceType } = currentListing.attributes.publicData;

    const initialValues = () => {
        let values = {};
        if (bookingOptions) {
            Object.keys(bookingOptions).map(key => {
                values[key] = {
                    ...bookingOptions[key],
                    rate: new Money(bookingOptions[key].rate.amount, bookingOptions[key].rate.currency),
                }

                return key;
            });
        }
        return values;
    }

    return (
        <div className={classes}>
            <h1 className={css.title}>
                {isPublished ? (
                    <FormattedMessage
                        id="EditListingAvailabilityAndPricingPanel.title"
                        values={{ listingTitle: <ListingLink listing={listing} /> }}
                    />
                ) : (
                        <FormattedMessage id="EditListingAvailabilityAndPricingPanel.createListingTitle" />
                    )}
            </h1>
            <EditListingAvailabilityAndPricingForm
                className={css.form}
                initialValues={initialValues()}
                onSubmit={values => {
                    let bookingOptions = {};
                    
                    Object.keys(values).map(key => {
                        if (values[key].enable && values[key].enable !== false) {
                            bookingOptions[key] = {
                                ...values[key],
                                rate: {
                                    amount: values[key].rate.amount,
                                    currency: values[key].rate.currency
                                }
                            }
                        }
                        return key;
                    });
                    
                    const arrayBookingOptions = Object.keys(bookingOptions);

                    const minPrice = arrayBookingOptions.reduce((minValue, key) => {
                        const amount = values[key].rate.amount;
                        if (amount < minValue) {
                            return amount;
                        }
                        return minValue;
                    }, values[arrayBookingOptions[0]].rate.amount);

                    const maxPrice = arrayBookingOptions.reduce((maxValue, key) => {
                        const amount = values[key].rate.amount;
                        if (amount > maxValue) {
                            return amount;
                        }
                        return maxValue;
                    }, values[arrayBookingOptions[arrayBookingOptions.length - 1]].rate.amount);
                    const updateValues = {
                        publicData: {
                            searchFilterBookingOptions: arrayBookingOptions,
                            minPrice,
                            maxPrice,
                            bookingOptions
                        }
                    }
                    onSubmit(updateValues);
                }}
                onChange={onChange}
                saveActionMsg={submitButtonText}
                disabled={disabled}
                ready={ready}
                updated={panelUpdated}
                updateError={errors.updateListingError || errors.createStripePlanError || errors.updateStipePlanError}
                updateInProgress={updateInProgress}
                spaceType={spaceType}
            />
        </div>
    );
};

EditListingAvailabilityAndPricingPanel.defaultProps = {
    className: null,
    rootClassName: null,
    listing: null,
};

EditListingAvailabilityAndPricingPanel.propTypes = {
    className: string,
    rootClassName: string,
    listing: object,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
};

export default EditListingAvailabilityAndPricingPanel;
