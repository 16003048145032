import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';
import { getSearchPathUserLocation } from '../../../util/mapBox'

import css from './SectionFitnessProfessional.css';

const SectionFitnessProfessional = props => {
    const { rootClassName, className, isAuthenticated, history } = props;

    const classes = classNames(rootClassName || css.root, className);

    const handleGetUserLocation = async () => {
        const to = await getSearchPathUserLocation();
        history.push(to);
    }

    const hostOnGofynderLinkProps = !isAuthenticated
        ? { name: "SignupPage" }
        : { name: 'HostPage', to: { hash: '#how-it-works' } };

    return (
        <div className={classes}>
            <div className={css.container}>
                <p className={css.preTitle}>
                    <strong>Why We Do This</strong>
                </p>
                <h1 className={css.title}>
                    <FormattedMessage id="SectionFitnessProfessional.title" />
                </h1>
                <div className={css.content}>
                    
                    <p>
                        Whether you are a personal trainer, yoga instructor, dance teacher, whether
                        you have a studio or not. Making a living doing the thing you
                        love is hard.
                    </p>
                    
                    <p>
                        Having experienced this first hand we have made it our mission to empower 
                        the fitness professionals of the world.
                    </p>
                </div>
                <div className={css.buttonsWrapper}>
                    <button
                        className={classNames(css.button, css.buttonFindSpace)}
                        onClick={() => handleGetUserLocation()}>
                        <FormattedMessage id="SectionFitnessProfessional.findASpace" />
                    </button>
                    <NamedLink
                        {...hostOnGofynderLinkProps}
                        className={classNames(css.button, css.buttonHostOnGofynder)}
                    >
                        <FormattedMessage id="SectionFitnessProfessional.hostOnGofynder" />
                    </NamedLink>
                </div>
            </div>
        </div>
    );
};

SectionFitnessProfessional.defaultProps = { rootClassName: null, className: null };

SectionFitnessProfessional.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionFitnessProfessional;