/*
 * Marketplace specific configuration.
 */

// export const amenities = [
//   {
//     key: 'towels',
//     label: 'Towels',
//   },
//   {
//     key: 'bathroom',
//     label: 'Bathroom',
//   },
//   {
//     key: 'swimming_pool',
//     label: 'Swimming pool',
//   },
//   {
//     key: 'own_drinks',
//     label: 'Own drinks allowed',
//   },
//   {
//     key: 'jacuzzi',
//     label: 'Jacuzzi',
//   },
//   {
//     key: 'audiovisual_entertainment',
//     label: 'Audiovisual entertainment',
//   },
//   {
//     key: 'barbeque',
//     label: 'Barbeque',
//   },
//   {
//     key: 'own_food_allowed',
//     label: 'Own food allowed',
//   },
// ];

export const categories = [
  { key: 'private', label: 'Private space' },
  { key: 'shared', label: 'Shared space' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 3000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const activities = [
  { key: 'fitness', label: 'Fitness' },
  { key: 'yoga', label: 'Yoga' },
  { key: 'dance', label: 'Dance' },
  { key: 'martialArts', label: 'Martial Arts' },
  { key: 'pilates', label: 'Pilates' },
  { key: 'massage', label: 'Massage' },
];

export const amenities = [
  { key: 'audioSystem', label: 'Audio System' },
  { key: 'videoSystem', label: 'Video System' },
  { key: 'balletBar', label: 'Ballet Bar' },
  { key: 'bathrooms', label: 'Bathroom(s)' },
  { key: 'boxingRings', label: 'Boxing Ring(s)' },
  { key: 'airConditioning', label: 'Air Conditioning' },
  { key: 'drinkingFountain', label: 'Drinking Fountain' },
  { key: 'cardioEquipment', label: 'Cardio Equip.' },
  { key: 'lockerRooms', label: 'Lockers' },
  { key: 'freeWeights', label: 'Free Weights' },
  { key: 'mirrors', label: 'Mirrors' },
  { key: 'punchingBags', label: 'Punching Bag(s)' },
  { key: 'yogaWall', label: 'Yoga Wall' },
  { key: 'vendingMachines', label: 'Vending Machines' },
  { key: 'wifi', label: 'Wifi' },
  { key: 'showers', label: 'Showers' },
  { key: 'outdoorSpace', label: 'Outdoor Space' }
]

export const healthRelated = [
  { key: 'sanitizerdispensers', label: 'Sanitizer Dispensers' },
  { key: 'portablehepafilters', label: 'Portable HEPA Filters' },
  { key: 'hvacairfilterupgrade', label: 'HVAC Air Filter Upgrade (MERV 13+)' },
  { key: 'uvairfilters', label: 'UV Air Filters' },
  { key: 'regularspacecleaning', label: 'Regular Space Cleaning' },
  { key: 'notoachdoor', label: 'No touch door opener(s)' },
  { key: 'handwashingstation', label: 'Hand washing station' }
]

export const flooringTypes = [
  { key: 'carpet', label: 'Carpet' },
  { key: 'concrete', label: 'Concrete' },
  { key: 'hardwood', label: 'Hardwood' },
  { key: 'sportcourt', label: 'Sport Court' },
  { key: 'sportturf', label: 'Sport Turf' },
  { key: 'sprungfloors', label: 'Sprung Floors' },
  { key: 'rubbermatting', label: 'Rubber Matting' }
]

export const parking = [
  { key: 'freeparkinglot', label: 'Parking Lot(free)' },
  { key: 'paidparkinglot', label: 'Paid Parking Lot' },
  { key: 'streetparking', label: 'Street Parking' },
  { key: 'valet', label: 'Valet' },
]

export const bookingTypes = [
  { key: 'hour', label: 'By the hour' },
  { key: 'class', label: 'By the class' },
  { key: 'day', label: 'By the day' },
  { key: 'month', label: 'By the month' },
]

export const tags = [
  { key: 'aerial', label: 'Aerial' },
  { key: 'aerial-yoga', label: 'Aerial Yoga' },
  { key: 'ballet', label: 'Ballet' },
  { key: 'barre', label: 'Barre' },
  { key: 'barre-yoga', label: 'Barre Yoga' },
  { key: 'baseball', label: 'Baseball' },
  { key: 'basketball', label: 'Basketball' },
  { key: 'bodybuilding', label: 'Bodybuilding' },
  { key: 'bootcamp', label: 'Bootcamp' },
  { key: 'boxing', label: 'Boxing' },
  { key: 'bardio-barre', label: 'Cardio Barre' },
  { key: 'circuit-training', label: 'Circuit Training' },
  { key: 'competition-crossfit', label: 'Competition Crossfit' },
  { key: 'competition-weight-training', label: 'Competition Weight Training' },
  { key: 'contemporary-dance', label: 'Contemporary Dance' },
  { key: 'crossfit', label: 'Crossfit' },
  { key: 'cycling', label: 'Cycling' },
  { key: 'dance', label: 'Dance' },
  { key: 'functional-training', label: 'Functional Training' },
  { key: 'grappling', label: 'Grappling' },
  { key: 'gymnastics', label: 'Gymnastics' },
  { key: 'heated-yoga', label: 'Heated Yoga' },
  { key: 'hiit', label: 'HIIT' },
  { key: 'interval-training', label: 'Interval Training' },
  { key: 'jiu-jitsu', label: 'Jiu Jitsu' },
  { key: 'judo', label: 'Judo' },
  { key: 'karate', label: 'Karate' },
  { key: 'kettlebell', label: 'Kettlebell' },
  { key: 'kickboxing', label: 'Kickboxing' },
  { key: 'kids-gymnastics', label: 'Kids Gymnastics' },
  { key: 'kids-martial-arts', label: 'Kids Martial Arts' },
  { key: 'kung-fu', label: 'Kung Fu' },
  { key: 'martial-arts', label: 'Martial Arts' },
  { key: 'mma', label: 'MMA' },
  { key: 'olympic-lifting', label: 'Olympic Lifting' },
  { key: 'open-gym', label: 'Open Gym' },
  { key: 'performance-training', label: 'Performance Training' },
  { key: 'personal-training', label: 'Personal Training' },
  { key: 'photo-video-shoot', label: 'Photo/Video Shoot' },
  { key: 'pilates', label: 'Pilates' },
  { key: 'pole-fitness', label: 'Pole Fitness' },
  { key: 'reformer', label: 'Reformer' },
  { key: 'rock-cimbing', label: 'Rock Climbing' },
  { key: 'small-group-training', label: 'Small Group Training' },
  { key: 'soccer', label: 'Soccer' },
  { key: 'sports-training', label: 'Sports Training' },
  { key: 'tai-chi', label: 'Tai Chi' },
  { key: 'trampoline', label: 'Trampoline' },
  { key: 'trx', label: 'TRX' },
  { key: 'tumbling', label: 'Tumbling' },
  { key: 'weight-training', label: 'Weight Training' },
  { key: 'wrestling', label: 'Wrestling' },
  { key: 'yoga', label: 'Yoga' },
  { key: 'zumba', label: 'Zumba' },
]

export const BOOKING_OPTION_HOUR_COMMISSION = 0.06;
export const BOOKING_OPTION_MONTH_COMMISSION = 0.04;
export const BOOKING_OPTION_CLASS_COMMISSION = 0.04;

// Activate booking dates filter on search page
export const dateRangeLengthFilterConfig = {
  active: true,

  // A global time zone to use in availability searches. As listings
  // can be in various time zones, we must decide what time zone we
  // use in search when looking for available listings within a
  // certain time interval.
  //
  // If you have all/most listings in a certain time zone, change this
  // config value to that.
  //
  // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  searchTimeZone: 'Etc/UTC',

  // Options for the minimum duration of the booking
  options: [
    { key: '0', label: 'Any length' },
    { key: '60', label: '1 hour', shortLabel: '1h' },
    { key: '120', label: '2 hours', shortLabel: '2h' },
  ],
};
