import React from 'react';
import { object, string } from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import css from './DropdownInput.css';

const DropdownInputComponent = props => {
    const {
        rootClassName,
        className,
        labelClassName,
        input,
        id,
        placeholder,
        label,
        help,
        options
    } = props;

    const handleChange = (e, data) => {
        input.onChange(data.value);
        return data.value;
    }

    const classes = classNames(rootClassName);
    return (
        <div className={classes}>
            {label ? <label htmlFor={id} className={classNames(css.label, labelClassName)}>{label}</label> : null}
            {help ? <p className={css.help}>{help}</p> : null}
            <Dropdown placeholder={placeholder} fluid multiple selection className={classNames(css.input, className)}
                options={options}
                onChange={handleChange}
                defaultValue={input.value} />
        </div>
    )
}

DropdownInputComponent.defaultProps = {
    rootClassName: null,
    className: null,
    labelClassName: null,
    label: null,
};

DropdownInputComponent.propTypes = {
    rootClassName: string,
    className: string,
    labelClassName: string,
    id: string.isRequired,
    input: object.isRequired,
};

const DropdownInput = props => {
    return (
        <Field component={DropdownInputComponent} {...props} />
    )
}

export default DropdownInput;