import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import classNames from 'classnames';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  PageHeaderTitle
} from '../../components';
import { sendNewContactMessage } from './AboutPage.duck';
import config from '../../config';

import SectionFitnessProfessional from './SectionFitnessProfessional/SectionFitnessProfessional';
import SectionDidYouKnow from './SectionDidYouKnow/SectionDidYouKnow';
//import SectionFitnessPros from './SectionFitnessPros/SectionFitnessPros';
//import SectionSpaceOwners from './SectionSpaceOwners/SectionSpaceOwners';
import SectionOurStory from './SectionOurStory/SectionOurStory';
import SectionContactUs from './SectionContactUs/SectionContactUs';
import SectionPartners from './SectionPartners/SectionPartners';
import SectionOurTeam from './SectionOurTeam/SectionOurTeam';

import css from './AboutPage.css';

const AboutPageComponent = props => {
  // const { siteTwitterHandle, siteFacebookPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const {
    isAuthenticated,
    newContactMessageResult,
    sendNewContactMessageInProgress,
    sendNewContactMessageError,
    onSendNewContactMessage,
    history,
    intl
  } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'AboutPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'AboutPage.schemaDescription' });

  // prettier-ignore
  return (
    <StaticPage
      title={schemaTitle}
      description={schemaDescription}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AboutPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.pageHeaderContainer}>
          <span role="img" aria-label="Yoga instructor teaching a large class in a rented yoga studio"></span>

            <PageHeaderTitle
              title="About Us"
              isAbout={true}
              className={'aboutPage'}
            // referenceTitle="Multi-Purpose Sports & Fitness Space"
            // referenceDescription="San Antonio, TX"
            />
          </div>

          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionFitnessProfessional isAuthenticated={isAuthenticated} history={history} />
              </div>
            </li>
            <li className={classNames(css.section, css.didYouKnowSectionContainer)}>
              <div className={css.sectionFacts}>
                <SectionDidYouKnow />
              </div>
            </li>
            <li className={css.sectionOurStory}>
              <SectionOurStory />
            </li>
            <li className={css.section}>
              <div className={css.sectionFacts}>
                <SectionOurTeam />
              </div>
            </li>
            <li id="contact" className={css.section}>
              <div className={css.sectionContent}>
                <SectionContactUs
                  newContactMessageResult={newContactMessageResult}
                  sendNewContactMessageInProgress={sendNewContactMessageInProgress}
                  sendNewContactMessageError={sendNewContactMessageError}
                  onSendNewContactMessage={onSendNewContactMessage}
                />
              </div>
            </li>
            <li className={classNames(css.SectionPartners, css.lastSection)}>
              <SectionPartners />
            </li>
          </ul>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};


const mapStateToProps = (state) => {
  const {
    newContactMessageResult,
    sendNewContactMessageInProgress,
    sendNewContactMessageError,
  } = state.AboutPage;

  const {
    isAuthenticated
  } = state.Auth;

  return {
    isAuthenticated,
    newContactMessageResult,
    sendNewContactMessageInProgress,
    sendNewContactMessageError,
  }
}

const mapDispatchToProps = dispatch => ({
  onSendNewContactMessage: data => dispatch(sendNewContactMessage(data))
})

const AboutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AboutPageComponent);

export default AboutPage;
