import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionWaysToBook.css';

import byTheHourImage from './images/stopwatch-2.png';
import byTheDayImage from './images/calendar-3.png';
import byTheClassImage from './images/users-1.png';
import byTheMonthImage from './images/calendar-6.png';

const bookingType = (title, image, description) => {
    return (
        <div className={css.bookingWrapper}>
            <div className={css.imageWrapper}>
                <img src={image} alt={title} className={css.bookingImage} />
            </div>
            <div className={css.bookingInfo}>
                <h2 className={css.bookingTitle}>{title}</h2>
                <p className={css.bookingDescription}>{description}</p>
            </div>
        </div>
    );
};

const SectionWaysToBook = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <div className={css.content}>
                    <h1 className={css.title}>
                        <FormattedMessage id="SectionWaysToBook.waysToBook" />
                    </h1>
                    <div className={css.grid}>
                        {bookingType(
                            'By the Hour',
                            byTheHourImage,
                            `Use spaces for short periods of time as you need them. Limit 
                            your costs while still having access to great facilities.`
                        )}
                        {bookingType(
                            'By the Day',
                            byTheDayImage,
                            `Whether you are looking to put on a workshop, schedule all
                            your clients on one day or you are just looking to have 
                            flexibility in your day.
                            The book by the day option is for you.`
                        )}
                        {bookingType(
                            'By the Class',
                            byTheClassImage,
                            `Check out our newest booking option. You can now book a space
                            every week on the same day and time.
                            Build up your client base by having regularly scheduled class every
                            week.`
                        )}
                        {bookingType(
                            'By the Month',
                            byTheMonthImage,
                            `Want a space you can use any time? You can now book spaces on a monthly
                            subscription and use it whenever it works for you.`
                        )}
                    </div>
                    <div className={css.more}>
                        <NamedLink
                            name="HowItWorksPage"
                            to={{
                                hash: '#booking-options'
                            }}
                        >
                            <FormattedMessage id="SectionWaysToBook.learnMore" />
                        </NamedLink>
                    </div>
                </div>
                <div className={css.image} />
            </div>
        </div>
    );
};

SectionWaysToBook.defaultProps = { rootClassName: null, className: null };

SectionWaysToBook.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionWaysToBook;