import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../../components';

import css from './SectionEarningPotential.css';
import { Popup } from 'semantic-ui-react';

const SectionEarningPotential = props => {

    return (
        <div className={css.root}>
            <div className={css.headerContent}>
                <h1 className={css.headerMainTitle}>
                    <FormattedMessage id="SectionEPTraining.title" />
                </h1>
                <p className={css.headerSubTitle}>
                    <FormattedMessage id="SectionEPTraining.subTitle" />
                </p>
                <div className={css.grid}>
                    <div className={css.descriptionColumn}>
                        <div className={css.itemHeader}>

                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPTraining.descriptionItem2" />
                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPTraining.descriptionItem3" />
                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPTraining.descriptionItem4" />
                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPTraining.descriptionItem5" />
                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPTraining.descriptionItem6" />
                        </div>
                        <div className={css.lastItemLabel}>
                            <FormattedMessage id="SectionEPTraining.descriptionLastItem" />
                        </div>
                    </div>
                    <div className={css.normalColumn}>
                        <div className={css.itemHeader}>
                            <div className={css.itemPretitle}>
                                <FormattedMessage id="SectionEPTraining.normalPretitle" />
                            </div>
                            <div className={css.itemTitle}>
                                <FormattedMessage id="SectionEPTraining.normalTitle" />
                            </div>
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPTraining.normalItem2" />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            className={css.popup}
                            wide="very"
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPTraining.normalItem3Popup1" /><br /><br /><FormattedMessage id="SectionEPTraining.normalItem3Popup2" /><br /><br /><FormattedMessage id="SectionEPTraining.normalItem3Popup3" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPTraining.normalItem3" /></div>} 
                        />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                                                        className={css.popup2}
                                                        wide="very"
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPTraining.normalItem4Popup1" /><br /><br /><FormattedMessage id="SectionEPTraining.normalItem4Popup2" /><br /><br /><FormattedMessage id="SectionEPTraining.normalItem4Popup3" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPTraining.normalItem4" /></div>} 
                        />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            className={css.popup3}
                            wide="very"
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPTraining.normalItem5Popup1" /><br /><br /><FormattedMessage id="SectionEPTraining.normalItem5Popup2" /><br /><br /><FormattedMessage id="SectionEPTraining.normalItem5Popup3" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPTraining.normalItem5" /></div>} 
                        />
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPTraining.normalItem6" />
                        </div>
                        <div className={css.lastItem}>
                            <FormattedMessage id="SectionEPTraining.normalLastItem" />
                        </div>
                    </div>
                    <div className={css.gofynderColumn}>
                        <div className={css.itemHeader}>
                            <div className={css.itemPretitle}>
                                <FormattedMessage id="SectionEPTraining.gofynderPretitle" />
                            </div>
                            <div className={css.itemTitle}>
                                <FormattedMessage id="SectionEPTraining.gofynderTitle" />
                            </div>
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPTraining.gofynderItem2" />
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPTraining.gofynderItem3" />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            className={css.popup2}
                            wide="very"
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPTraining.gofynderItem4Popup1" /><br /><br /><FormattedMessage id="SectionEPTraining.gofynderItem4Popup2" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPTraining.gofynderItem4" /></div>}
                            position="top right" 
                        />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            className={css.popup3}
                            wide="very"
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPTraining.gofynderItem5Popup1" /><br /><br /><FormattedMessage id="SectionEPTraining.gofynderItem5Popup2" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPTraining.gofynderItem5" /></div>} 
                            position="top right" 
                        />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            className={css.popup4}
                            wide="very"
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPTraining.gofynderItem6Popup1" /><br /><br /><FormattedMessage id="SectionEPTraining.gofynderItem6Popup2" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPTraining.gofynderItem6" /></div>} 
                            position="top right" 
                        />
                        </div>
                        <div className={css.lastItem}>
                            <FormattedMessage id="SectionEPTraining.gofynderLastItem" />
                        </div>
                    </div>
                </div>
                <div className={css.descriptionEarnings}>
                    <FormattedMessage id="SectionEPTraining.descriptionEarnings" />
                </div>
                <NamedLink
                    name="SignupPage"
                    className={css.signup}
                >
                    <FormattedMessage id="SectionEPTraining.signup" />
                </NamedLink>
            </div>
        </div>
    )

}

export default SectionEarningPotential;