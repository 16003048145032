import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionBookingOptions.css';


const bookingPlan = ({ plan, description, bookingType, spaceTypes, mostPopular }) => {
    const classes = mostPopular ? classNames(css.planContainer, css.mostPopularContainer) : css.planContainer;
    const mostPopularClass = mostPopular ? css.mostPopular : css.mostPopularUnselected;

    return (
        <div className={classes}>
            <div className={mostPopularClass}>Most Popular</div>
            <div className={css.planNameContainer}>
                <h2>By the {plan}</h2>
            </div>
            <p className={css.planDescription}>
                {description}
            </p>
            <div className={css.featureContainer}>
                <h4 className={css.featureTitle}>Booking Type</h4>
                <p className={css.featureDescription}>{bookingType}</p>
            </div>
            <div className={css.featureContainer}>
                <h4 className={css.featureTitle}>Space Types</h4>
                <p className={css.featureDescription}>{spaceTypes}</p>
            </div>
        </div>
    )
}

const SectionBookingOptions = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionBookingOptions.title" />
                </h2>
                <p className={css.description}>
                    <FormattedMessage id="SectionBookingOptions.description" />
                </p>
                <p className={css.description}>
                    <FormattedMessage id="SectionBookingOptions.description2" />
                </p>
                <div className={css.grid}>
                    {bookingPlan({
                        plan: 'Hour',
                        description: 'Pick a start and end time on the day that works for you',
                        bookingType: 'One-time',
                        spaceTypes: 'Shared & Private Spaces',
                        mostPopular: true,
                    })}
                    {bookingPlan({
                        plan: 'Day',
                        description: `Book the whole day based on a space's availability`,
                        bookingType: 'One-time',
                        spaceTypes: 'Shared & Private Spaces',
                        mostPopular: false,
                    })}
                    {bookingPlan({
                        plan: 'Month',
                        description: `Unlimited use of a space during the host's set hours`,
                        bookingType: 'Subscription',
                        spaceTypes: 'Shared & Private Spaces',
                        mostPopular: false,
                    })}
                    {bookingPlan({
                        plan: 'Class',
                        description: `Reserve a space at the same day & time every week`,
                        bookingType: 'Subscription',
                        spaceTypes: 'Private Spaces Only',
                        mostPopular: false,
                    })}
                </div>
            </div>
        </div>
    );
};

SectionBookingOptions.defaultProps = { rootClassName: null, className: null };

SectionBookingOptions.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionBookingOptions;