import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionHowHostingWorks.css';

const gridItem = (title, description, description2) => {
    return (
        <div className={css.itemWrapper}>
            <div className={css.iconWrapper}>
                <i className="check circle outline icon"></i>
            </div>
            <div className={css.infoWrapper}>
                <h2>{title}</h2>
                <p>{description}</p>
                <p>{description2}</p>
            </div>
        </div>
    )
}

const SectionHowHostingWorks = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionHowHostingWorks.title" />
                </h2>
                <div className={css.grid}>
                    {gridItem(
                        'List your space for free',
                        `Listing your space on Gofynder is always free. We only take 
                        commissions on completed bookings - so we only make money when you
                        do.`,
                        'We give you four different ways to book out your space(s). You set your pricing, availability and pick which bookings to accept.'
                    )}
                    {gridItem(
                        'Welcome users to your space',
                        `Message other members and accept bookings directly through the Gofynder. Once you accept a booking request the user will be notified automatically.`,
                        ''
                    )}
                    {gridItem(
                        'Get paid',
                        `We charge users upfront so you know that payments will be processed without issue and payouts are deposited directly into your bank account.`,
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

SectionHowHostingWorks.defaultProps = { rootClassName: null, className: null };

SectionHowHostingWorks.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionHowHostingWorks;