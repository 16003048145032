import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { BOOKING_OPTION_CLASS } from '../../util/types';
import moment from 'moment';

import css from './BookingBreakdown.css';

const nextDatesBookByClass = (startDate) => {
  let nextDates = [startDate];
  for (let i = 1; i < 4; i++) {
    const lastDate = nextDates[nextDates.length - 1];
    nextDates = [...nextDates, moment(lastDate).add(1, 'weeks').toDate()]
  }
  return nextDates;
};

const LiteItemDetailsByClassMaybe = props => {
  const { bookingOption, booking, timeZone, intl, useCheckout } = props;

  const isClass = bookingOption === BOOKING_OPTION_CLASS;

  if (!useCheckout || !isClass) return null;

  const { start, end, displayStart, displayEnd } = booking.attributes;

  const startDate = displayStart || start
  const endDate = displayEnd || end;

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };
  const timeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric'
  };

  const nextDates = nextDatesBookByClass(startDate);

  const startTime = intl.formatDate(startDate, { ...timeFormatOptions, ...timeZoneMaybe });
  const endTime = intl.formatDate(endDate, { ...timeFormatOptions, ...timeZoneMaybe });

  return (
    <div className={css.bookingPeriodCheckout}>
      <h3 className={css.bookingSummaryTitle}>
        <FormattedMessage id="BookingBreakdown.startDateTitle" />
      </h3>
      <div className={css.dateLabel}>
        <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
      </div>
      <div className={css.timeSlotLabel}>
        <FormattedMessage id="BookingBreakdown.hoursCheckout" values={{ startTime, endTime }} />
      </div>

      <h3 className={css.bookingSummaryTitle}>
        <FormattedMessage id="BookingBreakdown.classSlotDetailsTitle" />
      </h3>
      <div className={css.bookingTimeSlotsDescription}>
        <FormattedMessage id="BookingBreakdown.classSlotDetailsSubTitle" values={{ startTime, endTime }} />
      </div>
      <div className={css.bookingTimeSlotsDetails}>
        {nextDates.map((n, index) => (
          <div className={css.rootClassSlots} key={index}>
            <div className={css.dateLabel}>
              <FormattedDate value={n} {...dateFormatOptions} {...timeZoneMaybe} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiteItemDetailsByClassMaybe;
