import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionSpacesTypes.css';

import image from './images/LxfFbYtg.jpeg';

const SectionSpacesTypes = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.header}>
                <h2 className={css.headerTitle}>
                    <FormattedMessage id="SectionSpacesTypes.title" />
                </h2>
                <p className={css.headerDescription}>
                    <FormattedMessage id="SectionSpacesTypes.description" />
                </p>
                <p className={css.headerDescription}>
                    <FormattedMessage id="SectionSpacesTypes.description2" />
                </p>
            </div>
            <div className={css.imageContainer}>
                <img className={css.image} src={image} alt="Personal trainer greeting clients in a rented gym" />
            </div>
        </div>
    );
};

SectionSpacesTypes.defaultProps = { rootClassName: null, className: null };

SectionSpacesTypes.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionSpacesTypes;