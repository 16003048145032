import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, convertMoneyToNumber, convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { propTypes, LINE_ITEM_DAY, BOOKING_OPTION_MONTH, BOOKING_OPTION_CLASS } from '../../util/types';
import { arrayOf } from 'prop-types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Decimal } from 'decimal.js';
import config from '../../config';
import { Popup } from 'semantic-ui-react';
// import { daysBetween } from '../../util/dates';
// import moment from 'moment';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

const getTotalBasePriceFromTransactions = (transactions, unitType, intl) => {
  const result = transactions.reduce((basePrice, transaction) => {
    const unitPurchase = transaction.attributes.lineItems.find(
      item => item.code === unitType && !item.reversal
    );

    // const { start, end } = transaction.booking.attributes;
    // const endDay = unitType === LINE_ITEM_DAY ? moment(end).subtract(1, 'days') : end;
    // let dayQuantity = unitType === LINE_ITEM_DAY ? daysBetween(start, endDay) : 0;
    // const quantity = unitPurchase ? unitPurchase.quantity.plus(dayQuantity) : 0;
    const total = unitPurchase ? convertMoneyToNumber(unitPurchase.lineTotal) : new Decimal(0);

    return {
      quantity: basePrice.quantity.plus(unitPurchase.quantity),
      unitPrice: !basePrice.unitPrice && unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : basePrice.unitPrice,
      total: basePrice.total.plus(total)
    };
  }, { quantity: new Decimal(0), unitPrice: null, total: new Decimal(0) });

  return {
    quantity: result.quantity.toString(),
    unitPrice: result.unitPrice,
    total: formatMoney(intl, new Money(
      convertUnitToSubUnit(result.total.toNumber(), unitDivisor(config.currency)),
      config.currency
    ))
  }
}

const LineItemBasePriceMaybe = props => {
  const { transactions, unitType, intl, bookingOption } = props;

  const isDaily = unitType === LINE_ITEM_DAY;
  const isMonthly = bookingOption === BOOKING_OPTION_MONTH;
  const isClass = bookingOption === BOOKING_OPTION_CLASS;
  const translationKey = isMonthly
    ? 'BookingBreakdown.baseUnitMonth'
    : isDaily
      ? 'BookingBreakdown.baseUnitDay'
      : isClass
        ? 'BookingBreakdown.baseUnitClass'
        : 'BookingBreakdown.baseUnitHour';

  const {
    quantity: quantityMaybe,
    unitPrice,
    total
  } = getTotalBasePriceFromTransactions(transactions, unitType, intl);

  const quantity = isClass
    ? quantityMaybe / 4 // we need the amount the hours per week
    : isMonthly
      ? 1 // one per month
      : quantityMaybe;

  const helpTextMessage = intl.formatMessage({ id: `BookingBreakdown.basePriceHelpText.${bookingOption}` });

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
        {/* WE USE STYLE INSTEAD OF CSS MODULE BECAUSE IT'S NOT WORKING FOR THIS COMPONENT */}
        <Popup style={{fontSize: 14}} content={helpTextMessage} trigger={<i className="question circle outline icon"></i>} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transactions: arrayOf(propTypes.transaction.isRequired),
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
