/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import React from 'react';
import {
  EditListingAllDonePanel, EditListingAvailabilityAndPricingPanel, EditListingDescriptionPanel, EditListingFeaturesPanel, EditListingIntroductionPanel, EditListingLocationPanel,
  EditListingPhotosPanel, EditListingSpaceTypePanel, EditListingSpecialInstructionsPanel, EditListingTimeSlotsPanel
} from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { ensureListing } from '../../util/data';
import { intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import {
  BOOKING_OPTION_CLASS, BOOKING_OPTION_MONTH, LISTING_STATE_PENDING_APPROVAL, propTypes
} from '../../util/types';
import {
  createSlug, LISTING_PAGE_PARAM_TYPES, LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PENDING_APPROVAL_VARIANT
} from '../../util/urlHelpers';
import css from './EditListingWizard.css';


export const INTRODUCTION = 'intro';
export const TYPE = 'space-type';
export const DESCRIPTION = 'description';
export const FEATURES = 'features';
export const INSTRUCTIONS = 'instructions';
export const LOCATION = 'location';
export const PHOTOS = 'photos';
export const AVAILABILITY_PRICING = 'availability-and-pricing';
export const TIME_SLOTS = 'time-slots';
export const ALL_DONE = 'done';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  INTRODUCTION,
  TYPE,
  DESCRIPTION,
  FEATURES,
  INSTRUCTIONS,
  LOCATION,
  PHOTOS,
  AVAILABILITY_PRICING,
  TIME_SLOTS,
  ALL_DONE
];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  // console.log(params, tab)
  // return
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.push(to);
};

const promoIsValid = ["proof"];

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    location: routerLocation,
    images,
    listing,
    currentUser,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onCreateStripePlan,
    onUpdateStripePlan,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    fetchExceptionsInProgress,
    availabilityExceptions,
    userMissingRequiredFields,
    callUserRequiredFieldsMissing,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  // support time base availability plan
  const onCompleteEditListingWizardTab = async (tab, updateValues, passThrownErrors = false, isLastTab = false, publishListing = false) => {

    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    let extraValues = {};

    // CREATE/UPDATE STRIPE PLAN FOR MONTH AND CLASS
    if (tab === AVAILABILITY_PRICING) {
      const bookingOptions = updateValues && updateValues.publicData
        && updateValues.publicData &&
        updateValues.publicData.bookingOptions;

      const createStripePlan = bookingOptions[BOOKING_OPTION_MONTH] ||
        bookingOptions[BOOKING_OPTION_CLASS] ? true : false;

      if (createStripePlan) {
        const bookingOptionsArray = Object.keys(bookingOptions).filter(b => b === BOOKING_OPTION_CLASS || b === BOOKING_OPTION_MONTH);
        let stripeProduct = currentListing.attributes.publicData.stripeProduct;

        for (const key of bookingOptionsArray) {
          const bookingOption = bookingOptions[key];
          const stripePlan = currentListing.attributes.publicData[`${key}StripePlan`];
          const { amount, currency } = bookingOption.rate;
          const interval = key === BOOKING_OPTION_MONTH ? 'month' : 'week';
          const interval_count = key === BOOKING_OPTION_MONTH ? 1 : 4;
          const customerCommission = key === BOOKING_OPTION_MONTH ? (amount * 0.04) : ((amount * 0.04) * 4); // we include the customer commission on the final plan amount
          const finalAmount = key === BOOKING_OPTION_MONTH ? (amount + customerCommission) : ((amount * 4) + customerCommission); // we multiply the amount for the 4 weeks
          // update
          if (stripePlan) {
            if (stripePlan.amount !== amount) {
              const plan = await onUpdateStripePlan(stripePlan.id, {
                amount: finalAmount,
                currency,
                interval,
                interval_count,
                product: stripeProduct,
                listing_id: currentListing.id.uuid
              });

              if (!plan) return;

              extraValues = {
                publicData: { ...updateValues.publicData, ...extraValues.publicData, [`${key}StripePlan`]: plan }
              }
            }
          }
          // create
          else {
            const plan = await onCreateStripePlan({
              amount: finalAmount,
              currency,
              interval,
              interval_count,
              product: stripeProduct,
              listing_id: currentListing.id.uuid
            });

            if (!plan) return;

            // save stripe product if doesn't exist
            if (!stripeProduct) {
              stripeProduct = plan.product;
              extraValues = {
                publicData: { ...updateValues.publicData, ...extraValues.publicData, [`${key}StripePlan`]: plan, stripeProduct }
              }
            }
            else {
              extraValues = {
                publicData: { ...updateValues.publicData, ...extraValues.publicData, [`${key}StripePlan`]: plan }
              }
            }

          }
        }
        // -- end loop -- //
      }
      // -- end create plan -- //
    }

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft({ ...updateValues, title: 'Give a short name that summarizes your space' })
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, ...extraValues, id: currentListing.id };


      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (publishListing === false) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            if (!isLastTab) redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
          } else {
            handlePublishListing(currentListing.id);
          }
          // if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
          //   // Create listing flow: smooth scrolling polyfill to scroll to correct tab
          //   handleCreateFlowTabScrolling(false);

          //   // After successful saving of draft data, user should be redirected to next tab
          //   redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
          // }
          // else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
          //   handlePublishListing(currentListing.id);
          // }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, ...extraValues, id: currentListing.id });
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
    };
  };

  const missingField = (...fields) => fields.reduce((missingFields, field) => {
    if (!field.field) return missingFields.concat(field.name);
    return missingFields
  }, [])

  switch (tab) {
    case INTRODUCTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewIntroduction'
        : 'EditListingWizard.saveEditIntroduction';
      return (
        <EditListingIntroductionPanel
          {...panelProps(INTRODUCTION)}
          location={routerLocation}
          userMissingRequiredFields={userMissingRequiredFields}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            // verify we have all required fields from the user
            const { profileImage } = currentUser;
            const { location } = currentUser.attributes.profile.publicData;
            const { phoneNumber } = currentUser.attributes.profile.protectedData;
            const missingFields = missingField(
              { name: 'profileImage', field: profileImage },
              { name: 'phoneNumber', field: phoneNumber },
              { name: 'location', field: location }
            )
            callUserRequiredFieldsMissing(missingFields);
            if (missingFields.length > 0) return;
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case TYPE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewSpaceType'
        : 'EditListingWizard.saveEditSpaceType';
      return (
        <EditListingSpaceTypePanel
          {...panelProps(TYPE)}
          userMissingRequiredFields={userMissingRequiredFields}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {            // verify we have all required fields from the user
            const { profileImage } = currentUser;
            const { location } = currentUser.attributes.profile.publicData;
            const { phoneNumber } = currentUser.attributes.profile.protectedData;
            const missingFields = missingField(
              { name: 'profileImage', field: profileImage },
              { name: 'phoneNumber', field: phoneNumber },
              { name: 'location', field: location }
            )
            callUserRequiredFieldsMissing(missingFields);
            if (missingFields.length > 0) return;
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingDescriptionPanel
          {...panelProps(DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingFeaturesPanel
          {...panelProps(FEATURES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case INSTRUCTIONS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewInstructions'
        : 'EditListingWizard.saveEditInstructions';
      return (
        <EditListingSpecialInstructionsPanel
          {...panelProps(INSTRUCTIONS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={(values, isValid) => {
            onCompleteEditListingWizardTab(tab, values);
            promoIsValid[0] = isValid;
          }}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          {...panelProps(LOCATION)}
          promoIsValid = {promoIsValid[0]}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case PHOTOS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPhotos'
        : 'EditListingWizard.saveEditPhotos';

      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
        />
      );
    }
    case AVAILABILITY_PRICING: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAvailabilityAndPricing'
        : 'EditListingWizard.saveEditAvailabilityAndPricing';
      return (
        <EditListingAvailabilityAndPricingPanel
          {...panelProps(AVAILABILITY_PRICING)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      )
    }
    case TIME_SLOTS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewTimeSlots'
        : 'EditListingWizard.saveEditTimeSlots';
      return (
        <EditListingTimeSlotsPanel
          {...panelProps(TIME_SLOTS)}
          fetchExceptionsInProgress={fetchExceptionsInProgress}
          availabilityExceptions={availabilityExceptions}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onAddAvailabilityException={onAddAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          intl={intl}
          onSubmit={values => {
            // We want to return the Promise to the form,
            // so that it doesn't close its modal if an error is thrown.
            return onCompleteEditListingWizardTab(tab, values, true, true, false);
          }}
          onNextTab={() =>
            onCompleteEditListingWizardTab(tab, {}, false, true, true)
          }
        />
      )
    }
    case ALL_DONE: {
      return (
        <EditListingAllDonePanel
          {...panelProps(ALL_DONE)}
          intl={intl}
          onSubmit={() => {
            const isPendingApproval =
              currentListing && currentListing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
            const listingSlug = currentListing ? createSlug(currentListing.attributes.title || '') : null;
            const routeName = isPendingApproval ? 'ListingPageVariant' : 'ListingPage';
            const pathParams = isPendingApproval ? {
              id: currentListing.id.uuid,
              slug: listingSlug,
              variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
            } : {
                id: currentListing.id.uuid,
                slug: listingSlug,
              };
            history.push(createResourceLocatorString(routeName, routeConfiguration(), pathParams));
          }}
          onNextTab={() =>
            // redirect to new listing page
            history.push(createResourceLocatorString('NewListingPage', routeConfiguration()))
          }
        />
      )
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: []
};

const { array, bool, func, object, oneOf, shape, string, arrayOf } = PropTypes;

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
    createStripePlanError: object,
    updateStipePlanError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  // availability: object.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onCreateStripePlan: func.isRequired,
  onUpdateStripePlan: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
