import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, ExternalLink } from '../../../components';

import css from './SectionWeGotYouCovered.css';

import poweredByStripeImage from './images/stripe-logo.png';

const gridItem = (title, description, link, image) => {
    return (
        <div className={css.itemWrapper}>
            <div className={css.infoWrapper}>
                <h2>{title} {link}</h2>
                <p>{description}</p>
                {image}
            </div>
        </div>
    )
}

const SectionPaymentsMadeEasy = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <div className={css.image}>
                    <span role="img" aria-label="Happy boxer gym owner"></span>
                </div>
                <div className={css.content}>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionWeGotYouCovered.title" />
                    </h2>
                    <div className={css.grid}>
                        {gridItem(
                            '$1,000,000 liability insurance',
                            `All bookings made through Gofynder are covered by our host insurance policy so
                            you can list your space with confidence knowing that you are covered against the
                            unexpected.`,
                            '',
                            ''
                        )}
                        {gridItem(
                            'Secure payments powered by',
                            `All payments are processed using Stripe so you know everything is simple, secure and deposited directly into your bank account. `,
                            <ExternalLink
                                key="linkToStripe"
                                href="https://www.stripe.com"
                                className={css.poweredByStripe}
                                title="Go to Stripe page"
                                >
                                <span>Stripe</span>
                            </ExternalLink>,      
                            <ExternalLink
                                key="linkToStripe"
                                href="https://www.stripe.com"
                                className={css.poweredByStripe}
                                title="Go to Stripe page"
                                >
                                <img className={css.stripeLogo} src={poweredByStripeImage} alt="Stripe logo" />
                            </ExternalLink>
                        )}
                        {gridItem(
                            'Trust',
                            `All users looking to book a space must complete their profile (including uploading a profile picture) so you know who you are dealing with a head of time.`,
                            '',
                            ''
                        )}
                    </div>
                    <NamedLink
                        name="SignupPage"
                        className={css.startHosting}
                    >
                        <FormattedMessage id="SectionPaymentsMadeEasy.startHosting" />
                    </NamedLink>
                </div>
            </div>
        </div>
    );
};

SectionPaymentsMadeEasy.defaultProps = { rootClassName: null, className: null };

SectionPaymentsMadeEasy.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionPaymentsMadeEasy;