/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  IconArrowHead,
} from '../../components';

import css from './TopbarMobileMenu.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const [showExamples, setShowExamples] = React.useState(false);

  const extraMenuItems = (
    <>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('HowItWorksPage'))}
        name="HowItWorksPage"
      >
        <FormattedMessage id="TopbarMobileMenu.howItWorksLink" />
      </NamedLink>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
        name="AboutPage"
      >
        <FormattedMessage id="TopbarMobileMenu.aboutPageLink" />
      </NamedLink>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('HostPage'))}
        name="HostPage"
      >
        <FormattedMessage id="TopbarMobileMenu.hostPageLink" />
      </NamedLink>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
        name="AboutPage"
        to={{ hash: '#contact' }}
      >
        <FormattedMessage id="TopbarMobileMenu.contactLink" />
      </NamedLink>
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('FAQPage'))}
        name="FAQPage"
      >
        <FormattedMessage id="TopbarMobileMenu.faqLink" />
      </NamedLink>
      <button
        className={classNames(css.navigationLink, css.examplesLink)}
        onClick={() => setShowExamples(!showExamples)}
      >
        <FormattedMessage id="TopbarMobileMenu.examples" />
        <IconArrowHead className={css.arrow} direction={showExamples ? "up" : "down"} size="big"/>
      </button>
      {showExamples && (
        <div className={classNames(css.examples, css.swingEntrance)}>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('PersonalTrainingPage'))}
            name="PersonalTrainingPage"
          >
            <FormattedMessage id="TopbarMobileMenu.personalTrainingLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('YogaPage'))}
            name="YogaPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yogaLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('GroupFitnessPage'))}
            name="GroupFitnessPage"
          >
            <FormattedMessage id="TopbarMobileMenu.groupFitnessLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CyclesPage'))}
            name="CyclesPage"
          >
            <FormattedMessage id="TopbarMobileMenu.cyclesLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('PilatesPage'))}
            name="PilatesPage"
          >
            <FormattedMessage id="TopbarMobileMenu.pilatesLink" />
          </NamedLink>
        </div>
      )}
    </>
  )

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <div className={css.inbox}>
          </div>
          {extraMenuItems}
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('GetStarted'))}
          name="GetStarted"
        >
          <FormattedMessage id="TopbarMobileMenu.getStartedLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        {extraMenuItems}
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
