import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string, object } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { findPhoneNumbersInText } from 'libphonenumber-js'
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsInFirstTimeCompleted,
  txIsSubscriptionRunning,
  txIsSubscriptionCancelledByCustomer
} from '../../util/transaction';
import {
  LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes,
  BOOKING_OPTION_MONTH,
  BOOKING_OPTION_CLASS,
  BOOKING_OPTION_HOUR,
  BOOKING_OPTION_DAY,
  // LINE_ITEM_CUSTOMER_COMMISSION
} from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import {
  dateIsBefore,
  calculateQuantityFromHours,
  minutesBetween,
  dateFromAPIToLocalNoon
} from '../../util/dates';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import moment from 'moment-timezone';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import CancelActionButtonsMaybe from './CancelActionButtonsMaybe';
import SubscriptionPanelMaybe from './SubscriptionPanelMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';

import css from './TransactionPanel.css';

const validateIfPhoneNumberExists = (value) => {
  const phoneNumbers = findPhoneNumbersInText(value, 'US');
  return phoneNumbers.length > 0;
}

const validateMessage = (message) => {
  for(var i = 0; i < message.length; i++){
    const stringIsANumber = !isNaN(message[i]);
    const lessThanFourDigits =  message[i].length < 5;
    if (stringIsANumber === true && !lessThanFourDigits) {
      return true;
    }
  }
  return false;
}

const TODAY = new Date();
const TOMMORROW = new Date(TODAY);
TOMMORROW.setDate(TOMMORROW.getDate() + 1);

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      filterIsActive: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();

    // Load stripe subscription if available
    const { onFetchStripeSubscription, transaction } = this.props;
    const stripeSubscriptionId = transaction.attributes.protectedData.stripeSubscription || null;
    if (stripeSubscriptionId) {
      onFetchStripeSubscription(stripeSubscriptionId);
    }
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const updatedMessage = message.replace(/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/, "*-*-*");
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    const separateMessage = message.split(" ");
    const hasPhoneNumbers = validateMessage(separateMessage);
    const getPhoneNumbers = validateIfPhoneNumberExists(message);
    //If the message has emails then invalid the message.
    if(updatedMessage.includes("*-*-*") || hasPhoneNumbers || getPhoneNumbers){
      this.setState({filterIsActive: true});
      return;
    }
    this.setState({filterIsActive: false});
    onSendMessage(ensuredTransaction.id, updatedMessage)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onUpdateListing,
      updateListingInProgress,
      // updateListingError,
      onAcceptSale,
      onDeclineSale,
      onCreateStripeSubscription,
      acceptInProgress,
      declineInProgress,
      createStripeSubscriptionInProgress,
      acceptSaleError,
      declineSaleError,
      createStripeSubscriptionError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      fetchStripeSubscriptionInProgress,
      fetchStripeSubscriptionError,
      stripeSubscription: stripeSubscriptionMaybe,
      onConfirmClassBooking,
      onCancelStripeSubscription,
      cancelStripeSubscriptionInProgress,
      cancelStripeSubscriptionError,
      onCancelBooking,
      cancellationInProgress,
      cancellationError,
      onSendBookingAcceptedEmail,
      bookingAcceptedEmailInProgress,
      bookingAcceptedEmailError,
      onFetchTimeSlots,
      monthlyTimeSlots
    } = this.props;

    
    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const { bookingPlan } = currentTransaction.attributes.protectedData || {};
    const timeZone = currentListing.attributes.availabilityPlan.timezone;


    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const hasSubscriptions = bookingPlan === BOOKING_OPTION_MONTH || bookingPlan === BOOKING_OPTION_CLASS;
    const availableTimeSlots = currentListing && currentListing.attributes && currentListing.attributes.publicData
      && currentListing.attributes.publicData.availableTimeSlots
      ? currentListing.attributes.publicData.availableTimeSlots
      : null;
      // We add 6 hours to match transition when subscription starts, default timezone is utc we add 6 hours
      // to make it cst
    
    const startDate = currentTransaction.booking
    ? moment(currentTransaction.booking.attributes.start).toDate() 
    : TOMMORROW;
    /*var startDate = TODAY;

    if(currentTransaction.booking == null){
      startDate = TOMMORROW;
    }else{
      startDate = moment(currentTransaction.booking.attributes.start).add(6, 'hours').toDate();
    }*/
    
    // value on minutes
    const timeAheadFromStartDate = currentTransaction && currentTransaction.booking && currentTransaction.booking.attributes &&
      dateIsBefore(TODAY, startDate)
      ? minutesBetween(TODAY, startDate)
      : 0;

    const isOneTime = bookingPlan === BOOKING_OPTION_HOUR || bookingPlan === BOOKING_OPTION_DAY;
    const isSubscription = bookingPlan === BOOKING_OPTION_CLASS || bookingPlan === BOOKING_OPTION_MONTH;
    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
            return transition.attributes.name;
          })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 || transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showSubscriptionPanel: hasSubscriptions,
          showCancelButton: true,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
          showSubscriptionPanel: hasSubscriptions
        };
      } else if (txIsSubscriptionRunning(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showSubscriptionPanel: hasSubscriptions,
          showCancelButton: true
        }
      } else if (txIsSubscriptionCancelledByCustomer(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
          showSubscriptionPanel: hasSubscriptions,
        }
      } else if (txIsInFirstTimeCompleted(tx)) {  // Soon to be depecrated
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showSubscriptionPanel: hasSubscriptions,
          showCancelButton: true
        }
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showSubscriptionPanel: hasSubscriptions
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;
    // const isPrivate = publicData && publicData.spaceType && publicData.spaceType === 'private';

    const bookingOptions = publicData && publicData.bookingOptions ? publicData.bookingOptions : null;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
        ? 'TransactionPanel.perDay'
        : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saveBookedDatesListingPage = (type, startDate, endDate) => {
      // const isPrivate = publicData && publicData.spaceType && publicData.spaceType === 'private';
      const currentBookedDates = publicData && publicData.bookedDates ? publicData.bookedDates : [];
      const today = new Date();
      // update and delete old dates
      const cleanBookedDates = currentBookedDates.filter(c => {
        // const start = new Date(c.start);
        const end = new Date(c.end);
        // if (isPrivate && new Date(startDate).getDate() === start.getDate()) return false;
        // if (today.getDate() === end.getDate()) return true;
        if (dateIsBefore(end, today)) return false;
        return true;
      });

      onUpdateListing({
        id: currentListing.id,
        publicData: {
          bookedDates: [
            ...cleanBookedDates,
            {
              type,
              start: startDate,
              end: endDate,
            }
          ]
        }
      })
    }

    // const calculateFeePercentage = (transaction) => {
    //   const totalCustomerPay = transaction.attributes.payinTotal.amount;
    //   const lineCustomerCommission = transaction.attributes.lineItems.find(l => l.code === LINE_ITEM_CUSTOMER_COMMISSION);
    //   const totalCustomerCommission = lineCustomerCommission.lineTotal.amount;

    //   const fee_percent = ((totalCustomerCommission * 2) * 100) / totalCustomerPay;
    //   return fee_percent;
    // }
    const handleAcceptSale = () => {
      // check if it's recurring and create stripe subscription first
      const booking = currentTransaction.booking.attributes;
      // const timeZone = currentListing.attributes.availabilityPlan.timezone;
      const { monthStripePlan, classStripePlan } = currentListing.attributes.publicData;
      const { stripeCustomer } = currentTransaction.attributes.protectedData;

      const start = bookingPlan === BOOKING_OPTION_MONTH ? dateFromAPIToLocalNoon(booking.start) : booking.start;
      const end = bookingPlan === BOOKING_OPTION_MONTH ? dateFromAPIToLocalNoon(booking.end) : booking.end;
      const momentBookingStart = moment.tz(start, timeZone);
      const momentBookingEnd = moment.tz(end, timeZone);

      if (bookingPlan === BOOKING_OPTION_MONTH) {
        const startAt = momentBookingStart.clone().add(1, 'month').subtract(2, "days").unix();
        const cancelAt = momentBookingEnd.clone().unix();

        onCreateStripeSubscription({
          transaction_id: currentTransaction.id.uuid,
          stripe_customer: stripeCustomer,
          stripe_plan: monthStripePlan.id,
          start_at: startAt,
          cancel_at: cancelAt,
        }).then(subscription => {
          if (subscription && subscription.id) {
            const extraParams = {
              protectedData: {
                stripeSubscription: subscription.id
              }
            }
            onAcceptSale(currentTransaction.id, extraParams)
            saveBookedDatesListingPage(bookingPlan, momentBookingStart.format(), momentBookingEnd.format());
            onSendBookingAcceptedEmail(currentTransaction.id.uuid, BOOKING_OPTION_MONTH);
          }
        })
      }
      else if (bookingPlan === BOOKING_OPTION_CLASS) {

        // The start date is 4 weeks from the start date and we substract 2 days (48 hours) because
        // we need to charge 48 hours before the start date, and we transform to Unix timestamp that it's
        // the format for Stripe, this is the date where the trails end
        const startAt = momentBookingStart.clone().add(4, 'week').subtract(2, "days").unix();

        const quantity = calculateQuantityFromHours(new Date(booking.start), new Date(booking.end));

        onCreateStripeSubscription({
          transaction_id: currentTransaction.id.uuid,
          stripe_customer: stripeCustomer,
          stripe_plan: classStripePlan.id,
          quantity,
          start_at: startAt
        }).then(subscription => {
          if (subscription && subscription.id) {
            const transactionParams = {
              protectedData: {
                stripeSubscription: subscription.id
              }
            }
            onAcceptSale(currentTransaction.id, transactionParams)
            onConfirmClassBooking(currentListing.id.uuid, momentBookingStart.format(), momentBookingEnd.format());
            saveBookedDatesListingPage(bookingPlan, momentBookingStart.format(), momentBookingEnd.format());
            onSendBookingAcceptedEmail(currentTransaction.id.uuid, BOOKING_OPTION_CLASS);
          }
          else throw new Error('Something went wrong')
        })
      }
      else {
        onAcceptSale(currentTransaction.id)
        saveBookedDatesListingPage(bookingPlan, momentBookingStart.format(), momentBookingEnd.format());
      }
    }
    /**
     * Each booking option has a different cancelation policy, we handle
     * that here
     */
    const handleCancelBooking = () => {
      const params = {
        id: currentTransaction.id,
        transactionRole,
        isOneTime,
        isSubscription,
        timeAhead: timeAheadFromStartDate,
        bookingPlan,
        stripeSubscriptionMaybe,
      }

      if (isSubscription) {
        // WE ARE GOING TO KEEP THE CANCEL NOW HERE BUT NOW THIS FUNCTION
        // IT IS HANDLED IN THE BACKEND
        const cancelNow = txIsSubscriptionRunning(currentTransaction) ? false : true;
        onCancelStripeSubscription(currentTransaction.id.uuid, cancelNow)
          .then(() => onCancelBooking(params))
          .catch(() => { });
      } else {
        onCancelBooking(params)
      }
    }
    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress || bookingAcceptedEmailInProgress}
        declineInProgress={declineInProgress}
        createStripeSubscriptionInProgress={createStripeSubscriptionInProgress}
        updateListingInProgress={updateListingInProgress}
        acceptSaleError={acceptSaleError || bookingAcceptedEmailError}
        declineSaleError={declineSaleError}
        createStripeSubscriptionError={createStripeSubscriptionError}
        onAcceptSale={handleAcceptSale}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );
    const cancelButtons = (
      <CancelActionButtonsMaybe
        showButtons={stateData.showCancelButton}
        isProvider={isProvider}
        isCustomer={isCustomer}
        isOneTime={isOneTime}
        isSubscription={isSubscription}
        stripeSubscription={stripeSubscriptionMaybe}
        cancellationInProgress={cancellationInProgress || cancelStripeSubscriptionInProgress}
        cancellationError={cancellationError}
        onCancelBooking={handleCancelBooking}
        timeAheadFromStartDate={timeAheadFromStartDate}
      />
    );

    const subscriptionPanel = (
      <SubscriptionPanelMaybe
        showButtons={stateData.showSubscriptionPanel}
        isCustomer={isCustomer}
        transaction={currentTransaction}
        stripeSubscription={stripeSubscriptionMaybe}
        fetchStripeSubscriptionInProgress={fetchStripeSubscriptionInProgress}
        fetchStripeSubscriptionError={fetchStripeSubscriptionError}
        // cancelSharetribeSubscriptionInProgress={cancelSharetribeSubscriptionInProgress}
        // cancelSharetribeSubscriptionError={cancelSharetribeSubscriptionError}
        // cancelStripeSubscriptionInProgress={cancelStripeSubscriptionInProgress}
        cancelStripeSubscriptionError={cancelStripeSubscriptionError}
      // onCancelSharetribeSubscription={onCancelSharetribeSubscription}
      // onCancelStripeSubscription={onCancelStripeSubscription}
      />
    )

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                bookingPlan={bookingPlan}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                stripeSubscription={stripeSubscriptionMaybe}
                availableTimeSlots={availableTimeSlots} />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}

            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              subscription={stripeSubscriptionMaybe}
            />
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                filterIsActive= {this.state.filterIsActive}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
                <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
              )}
            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}

            {stateData.showSubscriptionPanel ? (
              <div className={css.mobileActionButtons}>
                {subscriptionPanel}
              </div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  bookingOptions={bookingOptions}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                />
              ) : null}
              {/* {!fetchStripeSubscriptionInProgress ? ( */}
              <BreakdownMaybe
                className={css.breakdownContainer}
                bookingPlan={bookingPlan}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                stripeSubscription={stripeSubscriptionMaybe}
                availableTimeSlots={availableTimeSlots}
              />
              {/* ) : null} */}
              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}

              {stateData.showCancelButton ? (
                <div className={css.desktopActionButtons}>{cancelButtons}</div>
              ) : null}

              {stateData.showSubscriptionPanel ? (
                <div className={css.subscriptionBreakdownContainer}>
                  <h3 className={css.subscriptionBreakdownTitle}>
                    <FormattedMessage id="TransactionPanel.subscriptionBreakdownTitle" />
                  </h3>
                  {subscriptionPanel}
                </div>
              ) : null}
            </div>
            
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  stripeSubscription: null
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  onCreateStripeSubscription: func.isRequired,
  createStripeSubscriptionInProgress: bool.isRequired,
  createStripeSubscriptionError: propTypes.error,

  onUpdateListing: func.isRequired,
  updateListingInProgress: bool.isRequired,
  // updateListingError: propTypes.error,

  onSendBookingAcceptedEmail: func.isRequired,
  bookingAcceptedEmailInProgress: bool.isRequired,
  bookingAcceptedEmailError: propTypes.error,

  stripeSubscription: object,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
