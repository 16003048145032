import React from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../../components';

import css from './SectionHeroLocations.css';

const SectionHeroLocations = props => {
    const {
        intl,
        rootClassName,
        className,
    } = props;

    const classes = classNames(rootClassName || css.root, className);

    const titlePlaceholder = intl.formatMessage(
        { id: 'SectionHeroLocations.title' },
        { location: "Los Angeles, CA" }
      );

    const descriptionPlaceholder = intl.formatMessage(
        { id: 'SectionHeroLocations.description' },
        { location: "Los Angeles" }
      );

    const sendMessagePlaceholder = intl.formatMessage(
        { id: 'SectionHeroLocations.button' },
        { location: "Los Angeles" }
      );

    return (
        <div className={classes}>
            <div className={css.container}>
                <div className={css.image}>
                    <span role="img" aria-label="Personal trainer running on a treadmill"></span>
                </div>
                <div className={css.content}>
                    <h1 className={css.title}>
                        {titlePlaceholder}
                    </h1>
                    <h2 className={css.description}>
                        {descriptionPlaceholder}
                    </h2>
                    <NamedLink
                        name="SearchPage"
                        to={{
                        search:
                            '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        {sendMessagePlaceholder}
                    </NamedLink>
                </div>
                
            </div>
        </div>
    );
};

SectionHeroLocations.defaultProps = { rootClassName: null, className: null };

SectionHeroLocations.propTypes = {
    rootClassName: string,
    className: string,
    onCreateListingDraft: func,
};

export default SectionHeroLocations;