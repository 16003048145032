import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, updateSavedListings } from '../../ducks/user.duck';
import { ensureCurrentUser } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const QUERY_SAVED_LISTINGS_REQUEST = 'app/SavedPage/QUERY_SAVED_LISTINGS_REQUEST';
export const QUERY_SAVED_LISTINGS_SUCCESS = 'app/SavedPage/QUERY_SAVED_LISTINGS_SUCCESS';
export const QUERY_SAVED_LISTINGS_ERROR = 'app/SavedPage/QUERY_SAVED_LISTINGS_ERROR';

export const REMOVE_LISTING_FROM_FAVORITE = 'app/SavedPage/REMOVE_LISTING_FROM_FAVORITE';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  savedListingRefs: [],
  querySavedListingsInProgress: false,
  querySavedListingsError: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_SAVED_LISTINGS_REQUEST:
      return { ...state, querySavedListingsInProgress: true, querySavedListingsError: null };
    case QUERY_SAVED_LISTINGS_SUCCESS:
      return {
        ...state,
        querySavedListingsInProgress: false,
        // we make sure we only include the listing ref once
        savedListingRefs: state.savedListingRefs.find(l => l.id.uuid === payload.savedListingRef.id.uuid) ? state.savedListingRefs : [...state.savedListingRefs, payload.savedListingRef]
      };
    case QUERY_SAVED_LISTINGS_ERROR:
      return { ...state, querySavedListingsInProgress: false, querySavedListingsError: payload };

    case REMOVE_LISTING_FROM_FAVORITE:
      return { ...state, savedListingRefs: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const querySavedListingsRequest = () => ({
  type: QUERY_SAVED_LISTINGS_REQUEST,
});

export const querySavedListingsSuccess = savedListingRef => ({
  type: QUERY_SAVED_LISTINGS_SUCCESS,
  payload: { savedListingRef },
});

export const querySavedListingsError = e => ({
  type: QUERY_SAVED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryRemoveListingFromFavorites = savedListingRefs => ({
  type: REMOVE_LISTING_FROM_FAVORITE,
  payload: savedListingRefs,
});

// ================ Thunks ================ //

export const queryListing = listingId => (dispatch, getState, sdk) => {
  dispatch(querySavedListingsRequest());
  return sdk.listings.show({
    id: listingId,
    include: ['author', 'images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  }).then(response => {
    // Pick only the id and type properties from the response
    const listingRef = {
      id: response.data.data.id,
      type: response.data.data.type
    };
    dispatch(addMarketplaceEntities(response));
    dispatch(querySavedListingsSuccess(listingRef));
  })
    .catch(e => dispatch(querySavedListingsError(storableError(e))));
};

export const updateSavedListingsAsFavorite = savedListings => (dispatch, getState, sdk) => {
  return dispatch(updateSavedListings(savedListings)).then(() => {
    const savedListingRefs = getState().SavedPage.savedListingRefs;
    const newSavedListingRefs = savedListingRefs.reduce((newList, r) => {
      if (savedListings.find(s => s === r.id.uuid)) return newList.concat(r);
      else return newList;
    }, []);
    dispatch(queryRemoveListingFromFavorites(newSavedListingRefs));
  });
};

export const loadData = userId => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(fetchCurrentUser()),
  ]).then(() => {
    const { currentUser } = getState().user;

    const ensuredCurrentUser = ensureCurrentUser(currentUser);

    const savedListingIds = (ensuredCurrentUser.attributes.profile.publicData && ensuredCurrentUser.attributes.profile.publicData.savedListings) || [];

    const fns = savedListingIds.map(listingId => dispatch(queryListing(listingId)));
    return Promise.all([fns]);
  });
};
