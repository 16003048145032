import classNames from 'classnames';
import propTypes, { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { Button, FieldRadioButton, Form, NamedLink } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { required } from '../../util/validators';
import css from './EditListingSpaceTypeForm.css';

export const EditListingSpaceTypeFormComponent = props => (
    <FinalForm
        {...props}
        render={formRenderProps => {
            const {
                className,
                disabled,
                ready,
                handleSubmit,
                intl,
                invalid,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
                userMissingRequiredFields,
            } = formRenderProps;

            const spaceTypeRequiredMessage = intl.formatMessage({
                id: 'EditListingSpaceType.spaceTypeRequired',
            });

            const classes = classNames(css.root, className);
            const submitReady = (updated && pristine) || ready;
            const submitInProgress = updateInProgress;
            const submitDisabled = invalid || disabled || submitInProgress;
            const { updateListingError, showListingsError } = fetchErrors || {};

            const profileLink = (
                <NamedLink
                    name="ProfileSettingsPage"
                >
                    <FormattedMessage id="EditListingIntroductionForm.erroruserRequiredFieldsLinkText" />
                </NamedLink>
            );

            const userMissingRequiredFieldsErrorMessage = userMissingRequiredFields && userMissingRequiredFields.length
            && userMissingRequiredFields.length > 0 ? (
                <div>
                    <p className={css.error}>
                        <FormattedMessage id="EditListingIntroductionForm.userMissingRequiredFields" values={{ updateProfileLink: profileLink }} />
                    </p>
                    <p className={css.error}>
                        <FormattedMessage id="EditListingIntroductionForm.userMissingRequiredFields2" values={{ updateProfileLink: profileLink }} />
                    </p>
                </div>
            ) : null;
            return (
                <Form onSubmit={handleSubmit} className={classes}>
                    {userMissingRequiredFieldsErrorMessage}
                    {updateListingError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingSpaceType.updateFailed" />
                        </p>
                    ) : null}
                    {showListingsError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingSpaceType.showListingFailed" />
                        </p>
                    ) : null}

                    <div className={css.optionsRoot}>
                        <div className={css.labels}>
                            <label for="shared" className={css.label}>
                                <FormattedMessage id="EditListingSpaceType.sharedTitle" />
                            </label>
                            <label for="private" className={css.label}>
                                <FormattedMessage id="EditListingSpaceType.privateTitle" />
                            </label>
                        </div>
                        <div className={css.descriptions}>
                            <label for="shared" className={css.description}>
                                <h3>
                                    <FormattedMessage id="EditListingSpaceType.sharedDescription1" />
                                </h3>
                                <p>
                                    <FormattedMessage id="EditListingSpaceType.sharedDescription2" />
                                </p>
                            </label>
                            <label for="private" className={css.description}>
                                <h3>
                                    <FormattedMessage id="EditListingSpaceType.privateDescription1" />
                                </h3>
                                <p>
                                    <FormattedMessage id="EditListingSpaceType.privateDescription2" />
                                </p>
                            </label>
                        </div>
                        <div className={css.selectors}>
                            <div className={css.selector} onClick={e => e.stopPropagation()}>
                                <FieldRadioButton
                                    id="shared"
                                    name="spaceType"
                                    value="shared"
                                    validate={required(spaceTypeRequiredMessage)} />
                            </div>
                            <div className={css.selector} onClick={e => e.stopPropagation()}>
                                <FieldRadioButton
                                    id="private"
                                    name="spaceType"
                                    value="private"
                                    validate={required(spaceTypeRequiredMessage)} />
                            </div>
                        </div>
                    </div>

                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}
                    >
                        {saveActionMsg}
                    </Button>
                </Form>
            );
        }}
    />
);

EditListingSpaceTypeFormComponent.defaultProps = { fetchErrors: null };

EditListingSpaceTypeFormComponent.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
};

export default compose(injectIntl)(EditListingSpaceTypeFormComponent);
