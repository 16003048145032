import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { BOOKING_OPTION_DAY } from '../../util/types';
import { dateFromAPIToLocalNoon, daysBetween } from '../../util/dates';
import { TimeSlotLine } from './LineItemBookingPeriod';
import moment from 'moment';

import css from './BookingBreakdown.css';

const LiteItemDetailsByDayMaybe = props => {
  const { bookingOption, bookings, availableTimeSlots, timeZone, useCheckout } = props;

  const isDaily = bookingOption === BOOKING_OPTION_DAY;

  if (!useCheckout || !isDaily) return null;

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  return (
    <div className={css.bookingPeriodCheckout}>
      <h3 className={css.bookingSummaryTitle}>
        <FormattedMessage id="BookingBreakdown.datesCheckoutTitle" />
      </h3>
      {bookings.map(booking => {

        const { start, end, displayStart, displayEnd } = booking.attributes;

        const startDate = dateFromAPIToLocalNoon(displayStart || start)
        const endDateRaw = dateFromAPIToLocalNoon(displayEnd || end);

        const endDate = moment(endDateRaw).subtract(1, 'days'); // eslint-disable-line no-unused-vars

        const startWeekday = moment(startDate).format('ddd').toLocaleLowerCase();
        const daysCount = daysBetween(startDate, endDate);

        let extraDays = [];
        if (daysCount > 0) {
          let lastDate = startDate;
          for (let i = 0; i < daysCount; i++) {
            const currentDate = moment(lastDate).add(1, 'days');
            const currentWeekday = currentDate.format('ddd').toLocaleLowerCase();
            extraDays.push(
              <>
                <div className={css.lineItemDay}>
                  <div className={css.dateLabel}>
                    <FormattedDate value={currentDate} {...dateFormatOptions} />
                  </div>
                </div>
                {/* print hours available in a day */}
                <div className={css.timeSlotsWrapper}>
                  {/* we set the time zone to null because we are not using datetime, we fetch the time slot from the availability plan */}
                  <TimeSlotLine weekday="hour" timeSlots={availableTimeSlots[currentWeekday]} timeZoneMaybe={null} />
                </div>
              </>
            )
            lastDate = currentDate;
          }
        }

        return (
          <div className={css.lineItemDateTimeDay} key={booking.id.uuid}>
            <div className={css.dateLabel}>
              <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
            </div>
            <div className={css.timeSlotsWrapper}>
              {/* we set the time zone to null because we are not using datetime, we fetch the time slot from the availability plan */}
              <TimeSlotLine weekday="hour" timeSlots={availableTimeSlots[startWeekday]} timeZoneMaybe={null} />
            </div>
            {extraDays.map((e, index) => (<React.Fragment key={index}>{e}</React.Fragment>))}
          </div>
        );
      })}
    </div>
  );
};

export default LiteItemDetailsByDayMaybe;
