import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import config from '../../../config';
import routeConfiguration from '../../../routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';

import css from './SectionConnecting.css';
import { TopbarSearchForm } from '../../../forms';

const SectionConnecting = props => {
    const { rootClassName, className, isHIW } = props;

    const classes = classNames(rootClassName || css.root, className);

    const handleSubmit = (values) => {
        const { currentSearchParams } = props;
        const { search, selectedPlace } = values.location;
        const { history } = props;
        console.log(history)
        const { origin, bounds } = selectedPlace;
        const originMaybe = config.sortSearchByDistance ? { origin } : {};
        const searchParams = {
        ...currentSearchParams,
        ...originMaybe,
        address: search,
        bounds,
        };
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
    }

    return (
        <div className={classes}>
            <div className={css.container}>
                <div className={css.textInfo}>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionConnecting.title" />
                    </h2>
                    <div className={css.content}>
                        <p>
                            Search by location and filter your findings by activity type, amenities or availability to find the right spot for you.
                        </p>
                        <p>
                            We have a wide variety of space types that are great for fitness, yoga, dance, martial arts and more.
                        </p>
                    </div>
                </div>
                <div className={css.buttonsWrapper}>
                    <TopbarSearchForm className={css.seachBar} desktopInputRoot={css.desktopInputRoot} onSubmit={handleSubmit} isHIW={isHIW}/>
                </div>
            </div>
        </div>
    );
};

SectionConnecting.defaultProps = { rootClassName: null, className: null };

SectionConnecting.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionConnecting;