import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../../components';

import css from './SectionGetStarted.css';

const SectionGetStarted = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <div className={css.image}>
                    <span role="img" aria-label="Fitness professional inside a gym"></span>
                </div>
                <div className={css.content}>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionGetStarted.title" />
                    </h2>
                    <h2 className={css.description}>
                        <FormattedMessage id="SectionGetStarted.description" />
                    </h2>
                    <NamedLink
                        name="SignupPage"
                        className={css.joinGofynder}
                    >
                        <FormattedMessage id="SectionGetStarted.joinGofynder" />
                    </NamedLink>
                </div>
            </div>
        </div>
    );
};

SectionGetStarted.defaultProps = { rootClassName: null, className: null };

SectionGetStarted.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionGetStarted;