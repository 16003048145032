import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionWhatDoYouDo.css';

import personalTrainingImage from './images/Activity_PERSONAL_TRAINING.png';
import yogaImage from './images/Activity_YOGA.png';
import danceImage from './images/Activity_Dance.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const activityLink = (name, image, searchQuery) => {
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.activity}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
    </NamedLink>
  );
};

const SectionWhatDoYouDo = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionWhatDoYouDo.title" />
      </div>

      <div className={css.activities}>
        {activityLink(
          'Personal Training',
          personalTrainingImage,
          '?address=Helsinki%2C%20Finland&bounds=60.2978389%2C25.254484899999966%2C59.9224887%2C24.782875800000056&origin=60.16985569999999%2C24.93837910000002'
        )}
        {activityLink(
          'Yoga',
          yogaImage,
          '?address=Rovaniemi%2C%20Finland&bounds=67.18452510000002%2C27.32667850000007%2C66.1553745%2C24.736871199999996&origin=66.50394779999999%2C25.729390599999988'
        )}
        {activityLink(
          'Dance',
          danceImage,
          '?address=Rovaniemi%2C%20Finland&bounds=67.18452510000002%2C27.32667850000007%2C66.1553745%2C24.736871199999996&origin=66.50394779999999%2C25.729390599999988'
        )}
      </div>
      <div className={css.exploreLink}>
        <NamedLink name="NewListingPage">
          <FormattedMessage id="SectionWhatDoYouDo.explore" />
        </NamedLink>
      </div>

    </div>
  );
};

SectionWhatDoYouDo.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionWhatDoYouDo.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWhatDoYouDo;
