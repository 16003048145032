import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-losangeles',
    predictionPlace: {
      address: 'Los Angeles, California',
      bounds: new LatLngBounds(new LatLng(34.161440999758, -118.121305008073), new LatLng(33.9018913203336, -118.521456965901)),
    },
  },
  {
    id: 'default-newyork',
    predictionPlace: {
      address: 'New York City, New York',
      bounds: new LatLngBounds(new LatLng(40.91757640130, -73.7008392055224), new LatLng(40.477399, -74.2590879797556)),
    },
  },
  {
    id: 'default-bayarea',
    predictionPlace: {
      address: 'Bay Area, San Francisco',
      bounds: new LatLngBounds(new LatLng(37.82616630554143, -122.45332577794537), new LatLng(37.79023369445857, -122.49880622205464)),
    },
  },
  {
    id: 'default-washingtondc',
    predictionPlace: {
      address: 'Washington, D.C',
      bounds: new LatLngBounds(new LatLng(38.99555093, -76.909391), new LatLng(38.79155738, -77.1197609567342)),
    },
  },
  {
    id: 'default-sandiego',
    predictionPlace: {
      address: 'San Diego, California',
      bounds: new LatLngBounds(new LatLng(33.0722089336828, -116.853118984), new LatLng(32.534171982, -117.266223298428)),
    },
  },
];
