import { storableError } from '../../util/errors';
import axios from 'axios';

// ================ Action types ================ //

export const SEND_NEW_CONTACT_MESSAGE_REQUEST = 'app/AboutPage/SEND_NEW_CONTACT_MESSAGE_REQUEST';
export const SEND_NEW_CONTACT_MESSAGE_SUCCESS = 'app/AboutPage/SEND_NEW_CONTACT_MESSAGE_SUCCESS';
export const SEND_NEW_CONTACT_MESSAGE_ERROR = 'app/AboutPage/SEND_NEW_CONTACT_MESSAGE_ERROR';

// ================ Reducer ================ //

const initialState = {
    newContactMessageResult: null,
    sendNewContactMessageInProgress: false,
    sendNewContactMessageError: null,
};

const aboutPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case SEND_NEW_CONTACT_MESSAGE_REQUEST:
            return {
                ...state,
                newContactMessageResult: null,
                sendNewContactMessageInProgress: true,
                sendNewContactMessageError: null,
            };
        case SEND_NEW_CONTACT_MESSAGE_SUCCESS:
            return {
                ...state,
                sendNewContactMessageInProgress: false,
                newContactMessageResult: payload,
            };
        case SEND_NEW_CONTACT_MESSAGE_ERROR:
            return {
                ...state, sendNewContactMessageInProgress: true,
                sendNewContactMessageError: payload,
            };
        default:
            return state;
    }
};

export default aboutPageReducer;

// ================ Action creators ================ //

export const sendNewContactMessageRequest = () => ({
    type: SEND_NEW_CONTACT_MESSAGE_REQUEST,
});

export const sendNewContactMessageSuccess = result => ({
    type: SEND_NEW_CONTACT_MESSAGE_SUCCESS,
    payload: result,
});

export const sendNewContactMessageError = e => ({
    type: SEND_NEW_CONTACT_MESSAGE_ERROR,
    payload: e,
});


export const sendNewContactMessage = (data) => (dispatch, getState, sdk) => {
    dispatch(sendNewContactMessageRequest());

    return axios.post('/api/v1/contact/sendMessage', data)
        .then(response => {
            dispatch(sendNewContactMessageSuccess(response.data));
            return response.data;
        })
        .catch(e => {
            dispatch(sendNewContactMessageError(storableError(e)));
            throw e;
        });
};