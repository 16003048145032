import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '..';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import {
  loadData,
} from '../SanDiegoLandingPage/SanDiegoLandingPage.duck';
import { updateSavedListings } from '../../ducks/user.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import SectionHeroLocations from './SectionHeroLocations/SectionHeroLocations';
import SectionWhyGofynder from './SectionWhyGofynder/SectionWhyGofynder';
import SectionFeaturedListings from './SectionFeaturedListings/SectionFeaturedListings';
import SectionOtherPopularSearches from './SectionOtherPopularSearches/SectionOtherPopularSearches';
import SectionOtherPopularCities from './SectionOtherPopularCities copy/SectionOtherPopularCities';

import css from './SanDiegoLandingPage.css';

const SanDiegoLandingPageComponent = props => {
  // const { siteTwitterHandle, siteFacebookPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);
  const {
    intl,
    onUpdateSavedListings,
    currentUser,
    location,
    history,
    listings
  } = props;

  const siteTitle = "San Diego ";
  const schemaTitle = intl.formatMessage(
    { id: 'CitiesLandingPage.schemaTitle' }, { siteTitle: siteTitle }
  );
  const schemaDescription = intl.formatMessage({ id: 'CitiesLandingPage.schemaDescription' }, { siteTitle: siteTitle });

  // prettier-ignore
  return (
    <StaticPage
      title={schemaTitle}
      description={schemaDescription}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="SanDiegoLandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <ul className={css.sections}>
            <li id="san-diego" className={css.sectionEarnMoney}>
              <SectionHeroLocations intl={intl} />
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionWhyGofynder />
              </div>
            </li>
            {listings && listings.length > 0 && (
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionFeaturedListings
                    listings={listings}
                    onUpdateSavedListings={onUpdateSavedListings}
                    currentUser={currentUser}
                    location={location}
                    history={history}
                  />
                </div>
              </li>
            )}
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionOtherPopularSearches />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionOtherPopularCities intl={intl} />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {

  const {
    featuredListings
  } = state.SanDiegoLandingPage;

  const listings = getMarketplaceEntities(state, featuredListings);

  const {
    currentUser
  } = state.user;

  const {
    isAuthenticated
  } = state.Auth;

  return {
    isAuthenticated,
    currentUser,
    listings
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateSavedListings: savedListings => dispatch(updateSavedListings(savedListings))
});

const SanDiegoLandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SanDiegoLandingPageComponent);

SanDiegoLandingPage.loadData = () => {
  const ids = [];
  return loadData(ids);
};

export default SanDiegoLandingPage;
