import React, { Component } from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { propTypes, BOOKING_OPTION_HOUR, BOOKING_OPTION_CLASS, BOOKING_OPTION_MONTH, BOOKING_OPTION_DAY } from '../../util/types';
import {
  dateIsAfter, isInRange, isSameDay, resetToStartOfDay,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
  dateFromAPIToLocalNoon,
  START_DATE,
  END_DATE
} from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { Form, Button, FieldDateRangeInput } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import css from './BookingDatesForm.css';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';

const { Money } = sdkTypes;

const identity = v => v;

const MAX_TIME_SLOTS_RANGE = 180;
const TODAY = new Date();

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const Next = props => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.timeZone),
      focusedInput: null
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
  }

  fetchMonthData(date) {
    const { listing, timeZone, onFetchTimeSlots } = this.props;
    const listingId = listing.id;

    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timeZone } = this.props;

    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);
        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(this.state.currentMonth, timeZone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  render() {
    const { rootClassName, className, unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            // isOwnListing,
            listing,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            monthlyTimeSlots,
            timeZone,
            userMissingRequiredFields
          } = fieldRenderProps;
          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};

          const { publicData } = listing.attributes;
          const isPrivate = publicData && publicData.spaceType ?
            publicData.spaceType === 'private' : false;
          const bookedDates = publicData && publicData.bookedDates
            ? publicData.bookedDates : [];

          const availableTimeSlots = publicData && publicData.availableTimeSlots
            ? publicData.availableTimeSlots
            : {};

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({ id: 'BookingDatesForm.bookingEndTitle' });
          const requiredMessage = intl.formatMessage({ id: 'BookingDatesForm.requiredDate' });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          // const timeSlotsError = fetchTimeSlotsError ? (
          //   <p className={css.timeSlotsError}>
          //     <FormattedMessage id="BookingDatesForm.timeSlotsError" />
          //   </p>
          // ) : null;
          const timeSlotsError = null;

          const buttonDisabled = !startDate || !endDate || userMissingRequiredFields;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const unitPriceCustomerCommissionAmount = (unitPrice.amount * config.custom.BOOKING_OPTION_MONTH_COMMISSION);
          const bookingData =
            startDate && endDate
              ? {
                unitType,
                unitPrice,
                startDate,
                endDate,
                unitPriceCustomerCommission: new Money(unitPriceCustomerCommissionAmount, config.currency),
                availableTimeSlots,
                timeZone,
                intl
              }
              : null;
          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              <EstimatedBreakdownMaybe bookingData={bookingData} />
            </div>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const checkForDayAvailability = timeSlot => {
            return !!bookedDates.find(b => {
              const startMoment = new Date(moment(b.start).tz(timeZone));
              // const endMoment = new Date(moment(b.end).tz(timeZone));
              if (b.type === BOOKING_OPTION_MONTH) {
                const startDate = dateFromAPIToLocalNoon(b.start);
                const endDate = dateFromAPIToLocalNoon(b.end);
                const inRange = isInRange(timeSlot, startDate, endDate, 'day', timeZone) ? true : false;
                return inRange;
              }
              else if (b.type === BOOKING_OPTION_HOUR) {
                const startTime = new Date(b.start);
                const sameDay = isSameDay(timeSlot, startTime) ? true : false;
                return sameDay;
              }
              else if (b.type === BOOKING_OPTION_DAY) {
                // we need to compare the end of the booked date with the current day of the calendar
                // because we use ranges and the first days are blocked by default but there is a gap
                // with the end date so we make sure to block the last day and we don't need to 
                // convert from api to local because we would be getting the next day instead of the 
                // actual current end date
                const endDate = b.end;
                const sameDay = isSameDay(timeSlot, endDate) ? true : false;
                return sameDay;
              }
              else if (b.type === BOOKING_OPTION_CLASS) {
                // we need to compare the booked date with the date of the calendar and make sure
                // to only block the day if it's after or same of the booked date
                if (dateIsAfter(timeSlot, startMoment) || isSameDay(timeSlot, startMoment)) {
                  const momentTimeSlot = moment(timeSlot).tz(timeZone);
                  const bookedWeekday = moment(b.start).tz(timeZone).format('ddd').toLowerCase();
                  const currentWeekday = momentTimeSlot.format('ddd').toLowerCase();
                  if (bookedWeekday === currentWeekday) return true;
                }
              }
              return false
            });
          };

          const allTimeSlots = Object.keys(monthlyTimeSlots).reduce((map, k) => {
            const timeSlots = monthlyTimeSlots[k].timeSlots ? monthlyTimeSlots[k].timeSlots : [];
            timeSlots.map(t => {
              // we only want to block dates if the space is private, if not we use the default
              // configuration of sharetribe (seats) so that way we allow other users to 
              // book on the same dates
              if (isPrivate && checkForDayAvailability(t.attributes.start)) {
                return map;
              }
              // we refactor the default entries from time to day, we use the structure that
              // sharetribe does for the entries for the type of day.
              return map.push({
                ...t,
                attributes: {
                  ...t.attributes,
                  type: 'time-slot/day',
                  start: resetToStartOfDay(t.attributes.start, timeZone),
                  end: resetToStartOfDay(t.attributes.start, timeZone, 1)
                }
              });
            })
            return map;
          }, []);

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {timeSlotsError}

              <FieldDateRangeInput
                className={css.bookingDates}
                unitType={unitType}
                name="bookingDates"
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText={endDatePlaceholderText}
                focusedInput={this.state.focusedInput}
                onFocusedInputChange={this.onFocusedInputChange}
                onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
                onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
                navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
                format={identity}
                timeSlots={allTimeSlots}
                useMobileMargins
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                )}
              />

              {bookingInfo}

              <div className={submitButtonClasses}>
                <Button
                  className={css.reserveButton}
                  disabled={buttonDisabled}
                  type="submit">
                  <div className={css.firstLine}>
                    <FormattedMessage id="BookingDatesForm.requestToBook" />
                  </div>
                  <div className={css.secondLine}>
                    <FormattedMessage id='BookingDatesForm.youWontBeChargedInfo' />
                  </div>
                </Button>
              </div>

            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
