import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionCategoryMaybe = props => {
  const { options, publicData } = props;
  
  const option = options.find(o => o.key === publicData.spaceType);

  return publicData.spaceType && option ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.categoryTitle" />
      </h2>
      <p className={css.description}>
        {option.label}
      </p>
    </div>
  ) : null;
};

export default SectionCategoryMaybe;
