/**
 * Booking breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the BookingDatesForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React from 'react';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import { DATE_TYPE_DATETIME, LINE_ITEM_CUSTOMER_COMMISSION, BOOKING_OPTION_CLASS } from '../../util/types';
import { unitDivisor, convertMoneyToNumber, convertUnitToSubUnit } from '../../util/currency';
import { BookingBreakdownMultiple } from '../../components';

import css from './BookingTimeForm.css';

const { Money, UUID } = sdkTypes;

const estimatedTotalPrice = (unitPrice, unitCount, unitPriceCustomerCommission) => {
  const numericPrice = convertMoneyToNumber(unitPrice);
  const customerComissionPrice = unitPriceCustomerCommission ? convertMoneyToNumber(unitPriceCustomerCommission) : null;
  const numericTotalPrice = unitPriceCustomerCommission ?
    new Decimal(numericPrice)
      .plus(customerComissionPrice)
      .times(unitCount)
      .toNumber() : new Decimal(numericPrice).times(unitCount).toNumber()
  return new Money(
    convertUnitToSubUnit(numericTotalPrice, unitDivisor(unitPrice.currency)),
    unitPrice.currency
  );
};

// When we cannot speculatively initiate a transaction (i.e. logged
// out), we must estimate the booking breakdown. This function creates
// an estimated transaction object for that use case.
const estimatedTransaction = (unitType, bookingStart, bookingEnd, unitPrice, quantity, unitPriceCustomerCommission, bookingPlan) => {
  const now = new Date();

  const WEEKS = bookingPlan === BOOKING_OPTION_CLASS ? 4 : 1;
  
  const totalUnitPrice = estimatedTotalPrice(unitPrice, quantity * WEEKS);
  const totalPrice = estimatedTotalPrice(unitPrice, quantity * WEEKS, unitPriceCustomerCommission);
  const totalCustomerCommission = estimatedTotalPrice(unitPriceCustomerCommission, quantity * WEEKS);

  return {
    id: new UUID('estimated-transaction'),
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: TRANSITION_REQUEST_PAYMENT,
      payinTotal: totalPrice,
      payoutTotal: totalPrice,
      lineItems: [
        {
          code: LINE_ITEM_CUSTOMER_COMMISSION,
          includeFor: ['customer'],
          unitPrice: unitPriceCustomerCommission,
          quantity: new Decimal(1 * WEEKS),
          lineTotal: totalCustomerCommission,
          reversal: false,
        },
        {
          code: unitType,
          includeFor: ['customer', 'provider'],
          unitPrice: unitPrice,
          quantity: new Decimal(quantity * WEEKS),
          lineTotal: totalUnitPrice,
          reversal: false,
        },
      ],
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_CUSTOMER,
          transition: TRANSITION_REQUEST_PAYMENT,
        },
      ],
    },
    booking: {
      id: new UUID('estimated-booking'),
      type: 'booking',
      attributes: {
        start: bookingStart,
        end: bookingEnd,
      },
    },
  };
};

const EstimatedBreakdownMaybe = props => {
  const { bookingPlan, unitType, unitPrice, startDate, endDate, quantity, timeZone, unitPriceCustomerCommission } = props.bookingData;

  const canEstimatePrice = startDate && endDate && unitPrice && quantity;

  if (!canEstimatePrice) {
    return null;
  }

  const tx = estimatedTransaction(unitType, startDate, endDate, unitPrice, quantity, unitPriceCustomerCommission, bookingPlan);

  return (
    <BookingBreakdownMultiple
      className={css.receipt}
      userRole="customer"
      unitType={unitType}
      transactions={[tx]}
      bookings={[tx.booking]}
      dateType={DATE_TYPE_DATETIME}
      bookingOption={bookingPlan}
      timeZone={timeZone}
    />
  );

};

export default EstimatedBreakdownMaybe;
