import React from 'react';
import { arrayOf } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { convertMoneyToNumber, convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Decimal } from 'decimal.js';
import config from '../../config';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

const estimatedTotalPrice = (transactions, isProvider) => {
  const totalPrice = transactions.reduce((decimalTotalPrice, transaction) => {
    const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
    const numericPrice = convertMoneyToNumber(totalPrice);
    return decimalTotalPrice.plus(numericPrice);
  }, new Decimal(0));

  const numericTotalPrice = totalPrice.toNumber();

  return new Money(
    convertUnitToSubUnit(numericTotalPrice, unitDivisor(config.currency)),
    config.currency
  );
};

const LineItemUnitPrice = props => {
  const { transactions, isProvider, intl } = props;

  const totalLabel = <FormattedMessage id="BookingBreakdown.total" />

  const totalPrice = estimatedTotalPrice(transactions, isProvider);
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>{totalLabel}</div>
      <div className={css.totalPrice}>{formattedTotalPrice}</div>
    </div>
  );
};

LineItemUnitPrice.propTypes = {
  transactions: arrayOf(propTypes.transaction.isRequired),
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
