import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './SectionOtherPopularSearches.css';

const SectionOtherPopularSearches = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionOtherPopularSearches.title" />
                </h2>
                <div className={css.grid}>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Personal Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Bootcamp Gym Rental</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Boxing Gym</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Circuit Training Spaces</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Crossfit Rental</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Functional Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles HIIT Space Rental</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Interval Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Kettlebell Space Rental</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Olympic Lifting Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Performance Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Bodybuilding Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Small Groupp Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Sports Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33.901894396%2C-118.521476934&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Los Angeles Weight Training Space</p>
                    </NamedLink>
                </div>
            </div>
        </div>
    );
};

SectionOtherPopularSearches.defaultProps = { rootClassName: null, className: null };

SectionOtherPopularSearches.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionOtherPopularSearches;