import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { ContactForm } from '../../../forms';

import css from './SectionContactUs.css';

const SectionContactUs = props => {
    const {
        rootClassName,
        className,
        newContactMessageResult,
        sendNewContactMessageInProgress,
        sendNewContactMessageError,
        onSendNewContactMessage
    } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionContactUs.title" />
                </h2>
                <p className={css.description}>
                    Got questions, not finding a certain type of space, or want dedicated
                    support creating your listing? Reach out and we will work to help. We would
                    love to hear from you.
                </p>
                <ContactForm
                    rootClassName={css.rootContactForm}
                    formId="contactFormId"
                    onSuccess={newContactMessageResult}
                    inProgress={sendNewContactMessageInProgress}
                    onError={sendNewContactMessageError}
                    onSubmit={values => onSendNewContactMessage(values)} />
            </div>
        </div>
    );
};

SectionContactUs.defaultProps = { rootClassName: null, className: null };

SectionContactUs.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionContactUs;