import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './SectionOtherPopularSearches.css';

const SectionOtherPopularSearches = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionOtherPopularSearches.title" />
                </h2>
                <div className={css.grid}>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Personal Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Bootcamp Gym Rental</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Boxing Gym</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Circuit Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Crossfit Rental</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Functional Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego HIIT Space Rental</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Interval Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Kettlebell Space Rental</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Olympic Lifting Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Performance Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Bodybuilding Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Small Group Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Sports Training Space</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.33965639%2C-116.11825974%2C32.26498755%2C-117.99697921&mapSearch=true&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Diego Weight Training Space</p>
                    </NamedLink>
                </div>
            </div>
        </div>
    );
};

SectionOtherPopularSearches.defaultProps = { rootClassName: null, className: null };

SectionOtherPopularSearches.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionOtherPopularSearches;