import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionNextLevelFeatures.css';

import multipleBookingOptionsImage from './images/bookingOptions.png';
import filteringOptionsImage from './images/filters.png';
import secureCommunication from './images/send.png';
import saveListingsImage from './images/save.png';
import lowFeesImage from './images/percent.png';
import insuranceImage from './images/insurance.png';
import reviewsImage from './images/like.png';

const feature = (title, image, description) => {

  if(title === ''){
    return (
      <div className={css.featureWrapperNone}>
        <div className={css.imageWrapper}>
          <img src={image} alt={title} />
        </div>
        <div className={css.featureInfo}>
          <h2 className={css.featureTitle}>{title}</h2>
          <p className={css.featureDescription}>{description}</p>
        </div>
      </div>
    );
  }else{
    return (
      <div className={css.featureWrapper}>
        <div className={css.imageWrapper}>
          <img src={image} alt={title} className={css.featureImage} />
        </div>
        <div className={css.featureInfo}>
          <h2 className={css.featureTitle}>{title}</h2>
          <p className={css.featureDescription}>{description}</p>
        </div>
      </div>
    );
  }
};

const SectionNextLevelFeatures = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);


  return (
    <div className={classes}>
      <div className={css.preTitle}>
        <FormattedMessage id="SectionNextLevelFeatures.preTitle" />
      </div>
      <div className={css.title}>
        <FormattedMessage id="SectionNextLevelFeatures.title" />
      </div>      
      <div className={css.grid}>
        {feature(
          'Multiple booking options',
          multipleBookingOptionsImage,
          `You can now book spaces 4 different ways to fit your specific needs.`
        )}
        {feature(
          'Filters',
          filteringOptionsImage,
          `Filter listings by location, price, amenities, availability or activity type 
          to find what spaces fit you.`
        )}
        {feature(
          'Save Listings',
          saveListingsImage,
          `Users can save spaces in their profile to review later to help make searching easier.`
        )}
        {feature(
          'Secure Communication',
          secureCommunication,
          `Comfortably communicate with other members through our platform so you don't have to 
          share personal information.`
        )}
        {feature(
          'Low fees',
          lowFeesImage,
          `We work hard to keep our fees low. Currently our fees are only 4%-6% depending on your 
          booking option.`
        )}
        {feature(
          'Reviews',
          reviewsImage,
          `Write and read reviews to get a better understanding of who is on the marketplace.`
        )}
        {feature(
          '',
          '',
          ``
        )}
        {feature(
          'Insurance',
          insuranceImage,
          `Gofynder provides insurance coverage for all hosts so they can feel secure knowing we have
          them covered.`
        )}
      </div>
      <div className={css.footer}>
        <NamedLink
           name="SignupPage"           
           className={css.getStarted}
        >
           <FormattedMessage id="SectionNextLevelFeatures.signUp" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionNextLevelFeatures.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionNextLevelFeatures.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionNextLevelFeatures;
