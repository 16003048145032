import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { ListingLink } from '..';
import { EditListingSpaceTypeForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import { BOOKING_OPTION_CLASS, LISTING_STATE_DRAFT } from '../../util/types';
import { MAX_SEATS_PRIVATE, MAX_SEATS_SHARED } from '../EditListingTimeSlotsPanel/EditListingTimeSlotsPanel';
import css from './EditListingSpaceTypePanel.css';


const EditListingSpaceTypePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    userMissingRequiredFields,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  return (
    <div className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingSpaceType.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
          />
        ) : (
            <FormattedMessage id="EditListingSpaceType.createListingTitle" />
          )}
      </h1>
      <EditListingSpaceTypeForm
        className={css.form}
        initialValues={{ spaceType: publicData.spaceType }}
        saveActionMsg={submitButtonText}
        userMissingRequiredFields={userMissingRequiredFields}
        onSubmit={values => {
          const { spaceType } = values;

          let availabilityPlanUpdateMaybe = {};
          let publicExtra = {};

          const availabilityPlan = currentListing.attributes.availabilityPlan;
          // we update seats according to space type
          // e.g for private seats are 1 and shared 100
          if (availabilityPlan && availabilityPlan.entries.length > 0) {
            availabilityPlanUpdateMaybe = {
              availabilityPlan: {
                ...availabilityPlan,
                entries: availabilityPlan.entries.map(entry => {
                  return {
                    ...entry,
                    seats: spaceType === 'private' ? MAX_SEATS_PRIVATE : MAX_SEATS_SHARED
                  }
                })
              }
            };
          }

          if (spaceType === 'shared') {
            // we make sure we clean public data if there are booking by the class options
            const savedBookingOptions = publicData.bookingOptions ? publicData.bookingOptions : {};
            const savedSearchFilterBookingOptions = publicData.searchFilterBookingOptions ? publicData.searchFilterBookingOptions : [];
            const updatedBookingOptions = Object.keys(savedBookingOptions).reduce((map, key) => {
              if (key !== BOOKING_OPTION_CLASS) {
                return map = {
                  ...map,
                  [key]: savedBookingOptions[key]
                }
              }
              return map;
            }, {})
            publicExtra.bookingOptions = updatedBookingOptions;
            publicExtra.searchFilterBookingOptions = savedSearchFilterBookingOptions.filter(k => k !== BOOKING_OPTION_CLASS);
            publicExtra.timeSlotsAvailableForClassBookings = [];
          }

          const updateValues = {
            publicData: {
              spaceType,
              ...publicExtra
            },
            ...availabilityPlanUpdateMaybe,
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors} />
    </div>
  );
};

EditListingSpaceTypePanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingSpaceTypePanel.propTypes = {
  className: string,
  rootClassName: string,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingSpaceTypePanel;
