import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionSellingPoints.css';

import image from './images/User_Selling_Points_Image.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const SectionSellingPoints = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>

      <div className={css.contentWrapper}>

        <div className={css.leftColumn}>
          <div className={css.title}>
            <div className={css.titleLine}>
              <FormattedMessage id="SectionSellingPoints.titleLine1" />
            </div>
            <div className={css.titleLine}>
              <FormattedMessage id="SectionSellingPoints.titleLine2" />
            </div>
          </div>

          <div className={css.description}>
            <div className={css.paragram}>
              <FormattedMessage id="SectionSellingPoints.descriptionParag1" />
            </div>
            <div className={css.paragram}>
              <FormattedMessage id="SectionSellingPoints.descriptionParag2" />
            </div>
            <div className={css.paragram}>
              <FormattedMessage id="SectionSellingPoints.descriptionParag3" />
            </div>
            <div className={css.paragram}>
              <FormattedMessage id="SectionSellingPoints.descriptionParag4" />
            </div>
          </div>

          <div className={css.buttonWrapper}>
            <NamedLink name="SearchPage" className={css.signUpButton}>
              <FormattedMessage id="SectionSellingPoints.signUpButton" />
            </NamedLink>
          </div>

        </div>

        <div className={css.rightColumn}>
          <div className={css.imageWrapper}>
            <div className={css.aspectWrapper}>
              <LazyImage src={image} alt={"Selling Point"} className={css.image} />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

SectionSellingPoints.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionSellingPoints.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSellingPoints;
