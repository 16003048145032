import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import config from '../../config';

import SectionEarnMoney from './SectionEarnMoney/SectionEarnMoney';
import SectionChooseHowYouShare from './SectionChooseHowYouShare/SectionChooseHowYouShare';
import SectionHowHostingWorks from './SectionHowHostingWorks/SectionHostingHowItWorks';
import SectionHostingFaqs from './SectionHostingFaqs/SectionHostingFaqs';
import SectionWeGotYouCovered from './SectionWeGotYouCovered/SectionWeGotYouCovered';

import css from './HostPage.css';

const HostPageComponent = props => {

  const {
    intl,
  } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'HostPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'HostPage.schemaDescription' });

  // prettier-ignore
  return (
    <StaticPage
      title={schemaTitle}
      description={schemaDescription}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="HostPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <ul className={css.sections}>
            <li id="how-it-works" className={css.sectionEarnMoney}>
              <SectionEarnMoney />
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionChooseHowYouShare />
              </div>
            </li>
            <li className={css.sectionPaymentsMadeEasyContainer}>
              <SectionWeGotYouCovered />
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowHostingWorks />
              </div>
            </li>
            <li id="faqs" className={css.section}>
                <SectionHostingFaqs />
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};



const HostPage = compose(
  withRouter,
  injectIntl
)(HostPageComponent);

export default HostPage;
