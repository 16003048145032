import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ButtonTabNavHorizontal, NamedLink } from '../../components';

import css from './SectionTopSearchs.css';

const SectionTopSearchs = props => {

    const { className, rootClassName, intl, currentActivity, setCurrentActivity } = props;
    const classes = classNames(rootClassName || css.root, className);

    const changetoFitness = () => { setCurrentActivity("Fitness") };
    const changetoYoga = () => { setCurrentActivity("Yoga") };
    const changetoMartialArts = () => { setCurrentActivity("MartialArts") };
    const changetoDance = () => { setCurrentActivity("Dance") };
    
    const tabs = [
        {
            text: (
                <p className={css.tab}>
                    <FormattedMessage id="SectionTopSearchs.fitnessLinkText" />
                </p>
            ),
            selected: currentActivity === "Fitness",
            onClick: changetoFitness,
        },
        {
            text: (
                <p className={css.tab}>
                <FormattedMessage id="SectionTopSearchs.yogaLinkText" />
                </p>
            ),
            selected: currentActivity === "Yoga",
            onClick: changetoYoga,
        },
        {
            text: (
                <p className={css.tab}>
                <FormattedMessage id="SectionTopSearchs.martialArtsLinkText" />
                </p>
            ),
            selected: currentActivity === "MartialArts",
            onClick: changetoMartialArts,
        },
        {
            text: (
                <p className={css.tab}>
                    <FormattedMessage id="SectionTopSearchs.danceLinkText" />
                </p>
            ),
            selected: currentActivity === "Dance",
            onClick: changetoDance,
        },
    ]

    const tagLabel = intl.formatMessage({ id: 'SectionTopSearchs.tagsTitle' });

    const mostRecentPages = (spaceName, spaceTags, searchQuery ) => {
        return(
            <div className={css.recentSearches}>
                <NamedLink
                    name="SearchPage"
                    to={{
                        search: searchQuery,
                    }}
                    className={css.tabContainer}
                >
                    <p className={css.spaceTitle}>{spaceName}</p>
                    <div className={css.tagsSection}>
                        <p className={css.tagsTitle}>{tagLabel}</p>
                        <p className={css.tagsBody}>{spaceTags}</p>
                    </div>
                </NamedLink>
            </div>
        );
    }

    return (
        <div className={classes}>
            <h2 className={css.title}><FormattedMessage id="SectionTopSearchs.title" /></h2>
            <ButtonTabNavHorizontal className={css.tabsContainer} tabRootClassName={css.tabs} tabs={tabs} tabButton={css.tabButton} tabButtonSelected={css.tabButtonSelected}/>
            <div className={css.searchTabs}>
                {currentActivity === "Fitness" ? 
                    <>
                        {mostRecentPages('Los Angeles Gym Rental','Personal Training, Crossfit, Bodybuilding', '?address=Los%20Angeles%2C%20California&bounds=34.29091773%2C-117.83903367%2C33.77181909%2C-118.80372831&pub_activities=fitness')}
                        {mostRecentPages('San Diego Gym Rental','HIIT, Sports Training, Circuit Training', '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.072208901%2C-116.853120013%2C32.534871031%2C-117.262118936&pub_activities=fitness')}
                        {mostRecentPages('San Francisco Gym Rental','TRX, Small Group Training, Open Gym', '?address=San%20Francisco%2C%20California%2C%20United%20States&bounds=37.84958441%2C-122.18097503%2C37.58726685%2C-122.6917009&pub_activities=fitness')}
                        {mostRecentPages('Carlsbad Gym Space Rental','Kettlebell, Boxing, Personal Training', '?address=Carlsbad%2C%20California%2C%20United%20States&bounds=33.182819998%2C-117.202461017%2C33.060413011%2C-117.358780797&pub_activities=fitness')}
                        {mostRecentPages('San Antonio Fitness Space Rental','Personal Training, Crossfit, Olympic Lifting', '?address=San%20Antonio%2C%20Texas%2C%20United%20States&bounds=29.749001976%2C-98.255613106%2C29.111114117%2C-98.886411915&pub_activities=fitness')}
                        {mostRecentPages('Pasadenda Gym Space Rental','Sports Training, Small Group Training, Bootcamp', '?address=Pasadena%2C%20California%2C%20United%20States&bounds=34.203973967%2C-117.981748%2C34.051258%2C-118.332171604&pub_activities=fitness')}
                        {mostRecentPages('Long Beach Fitness Studio Rental','Competition Crossfit, Personal Training, HIIT', 'address=Long%20Beach%2C%20California%2C%20United%20States&bounds=33.97275135%2C-117.83195198%2C33.62298577%2C-118.48017657&pub_activities=fitness')}
                        {mostRecentPages('Irvine Gym Rental','Bootcamp, Interval Training, Performance Training', '?address=Irvine%2C%20California%2C%20United%20States&bounds=33.87546795%2C-117.41831228%2C33.50228179%2C-118.10906445&pub_activities=fitness')}
                        {mostRecentPages('Houston Fitness Space Rental','Competition Weight Training, Functional Training', '?address=Houston%2C%20Texas%2C%20United%20States&bounds=30.04036398%2C-94.94080988%2C29.52892359%2C-95.84837813&pub_activities=fitness')}
                    </>
                : currentActivity === "Yoga" ?
                    <>
                        {mostRecentPages('Los Angeles Yoga Studio Rental','Yoga, Aerial, Barre Yoga', '?address=Los%20Angeles%2C%20California&bounds=34.29091773%2C-117.83903367%2C33.77181909%2C-118.80372831&pub_activities=yoga')}
                        {mostRecentPages('San Diego Yoga Studio Rental','Aerial Yoga, Yoga, Barre', '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.072208901%2C-116.853120013%2C32.534871031%2C-117.262118936&pub_activities=yoga')}
                        {mostRecentPages('Sacramento Yoga Space Rental','Barre Yoga, Heated Yoga', '?address=Sacramento%2C%20California%2C%20United%20States&bounds=38.9706617%2C-120.64240899%2C38.16270595%2C-122.23390223&pub_activities=yoga')}
                        {mostRecentPages('Oakland Yoga Rental','Yoga, Aerial, Barre Yoga', '?address=?address=Oakland%2C%20California%2C%20United%20States&bounds=37.95282289%2C-121.89746484%2C37.61445212%2C-122.55684615&pub_activities=yoga')}
                        {mostRecentPages('Burbank Yoga Studio Rental','Aerial, Aerial Yoga', '?address=Burbank%2C%20California%2C%20United%20States&bounds=34.26110118%2C-118.17820572%2C34.10325643%2C-118.47206654&pub_activities=yoga')}
                        {mostRecentPages('Escondido Yoga Space Rental','Yoga, Heated Yoga', '?address=Escondido%2C%20California%2C%20United%20States&bounds=33.45853275%2C-116.53534059%2C32.90047581%2C-117.56223977&pub_activities=yoga')}
                        {mostRecentPages('Santa Monica Yoga Studio Rental','Yoga, Heated Yoga, Barre', '?address=Santa%20Monica%2C%20California%2C%20United%20States&bounds=34.07812255%2C-118.37932733%2C33.96782657%2C-118.58428019&pub_activities=yoga')}
                        {mostRecentPages('Beverly Hills Yoga Rental','Heated Yoga, Barre Yoga', '?address=Beverly%20Hills%2C%20California%2C%20United%20States&bounds=34.17682398%2C-118.25322575%2C34.01123686%2C-118.56117921&pub_activities=yoga')}
                        {mostRecentPages('Van Nuys Yoga Space Rental','Yoga, Cadio Barre', '?address=Van%20Nuys%2C%20California%2C%20United%20States&bounds=34.29752276%2C-118.31163599%2C34.08043134%2C-118.71582959&pub_activities=yoga')}
                    </>
                :currentActivity === "MartialArts" ? 
                    <>
                        {mostRecentPages('Los Angeles Martial Arts Space Rental','Martial Arts, Jiu Jitsu, Judo', '?address=Los%20Angeles%2C%20California&bounds=34.29091773%2C-117.83903367%2C33.77181909%2C-118.80372831&pub_activities=martialArts')}
                        {mostRecentPages('San Diego Martial Arts Space Rental','Jiu Jitsu, Kids Martial Arts, MMA', '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.072208901%2C-116.853120013%2C32.534871031%2C-117.262118936&pub_activities=martialArts')}
                        {mostRecentPages('Westminster Martail Arts Rental','Karate, Kung Fu, Kickboxing', '?address=Westminster%2C%20California%2C%20United%20States&bounds=33.80259479%2C-117.89164859%2C33.69229468%2C-118.09594826&pub_activities=martialArts')}
                        {mostRecentPages('Mira Mesa Jiu Jitsu Space Rental','Martial Arts, Karate,Tai Chi', '?address=?address=San%20Diego%2C%20California&bounds=34.161440963%2C-118.126644633%2C33901894396%2C-118.521476934&pub_activities&pub_activities=martialArts')}
                        {mostRecentPages('Costa Mesa Martial Arts Rental','Grappling, Kickboxing, Martial Arts', '?address=Costa%20Mesa%2C%20California%2C%20United%20States&bounds=33.74031778%2C-117.76656413%2C33.58675185%2C-118.05072377&pub_activities=martialArts')}
                        {mostRecentPages('Vista Martial Arts Space Rental','MMA, Small Group Training, Kung Fu', '?address=Vista%2C%20California%2C%20United%20States&bounds=33.33760843%2C-116.96704387%2C33.06183986%2C-117.47460915&pub_activities=martialArts')}
                        {mostRecentPages('New York Martial Arts Rental','Martial Arts, Jiu Jitsu, Judo', '?address=New%20York%2C%20New/20York%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33901894396%2C-118.521476934&pub_activities=martialArts')}
                        {mostRecentPages('New Jersey Martial Arts Rental','Kickboxing, Martial Arts, Jiu Jitsu', '?address=New%20Jersey%2C%20United%20States&bounds=41.3574239900958%2C-73.8722201035284%2C38.8516494003392%2C-75.5741859892775&pub_activities=martialArts')}
                        {mostRecentPages('Orlando Karate Space Rental','Kids Martial Arts, MMA, Wrestling', '?address=Orlando%2C%20Florida%2C%20United%20States&bounds=29.16098213%2C-80.04648642%2C27.96815349%2C-82.13823274&pub_activities=martialArts')}
                    </>
                : 
                    <>
                        {mostRecentPages('Los Angeles Dance Studio Rental','Dance, Ballet, Barre', '?address=Los%20Angeles%2C%20California&bounds=34.29091773%2C-117.83903367%2C33.77181909%2C-118.80372831&pub_activities=dance')}
                        {mostRecentPages('San Diego Dance Studio Rental','Dance, Contemporay Dance, Zumba', '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.072208901%2C-116.853120013%2C32.534871031%2C-117.262118936&pub_activities=dance')}
                        {mostRecentPages('New York Dance Studio Rental','Ballet, Dance, Cardio Barre', '?address=New%20York%2C%20New/20York%2C%20United%20States&bounds=34.161440963%2C-118.126644633%2C33901894396%2C-118.521476934&pub_activities=dance')}
                        {mostRecentPages('New Jersey Dance Space Rental','Dance, Ballet, Cardio Barre', '?address=New%20Jersey%2C%20United%20States&bounds=41.3574239900958%2C-73.8722201035284%2C38.8516494003392%2C-75.5741859892775&pub_activities=dance')}
                        {mostRecentPages('Anaheim Dance Studio Rental','Dance, Trampoline, Contemporay Dance', '?address=Anaheim%2C%20California%2C%20United%20States&bounds=34.01355835%2C-117.5001081%2C33.64156693%2C-118.18976372&pub_activities=dance')}
                        {mostRecentPages('Salt Lake City Dance Rental','Dance, Ballet, Contemporay Dance', '?address=Salt%20Lake%20City%2C%20Utah%2C%20United%20States&bounds=41.09984869%2C-111.12081859%2C40.38496361%2C-112.57401604&pub_activities=dance')}
                        {mostRecentPages('Denver Dance Studio Rental','Ballet, Bootcamp', '?address=Denver%2C%20Colorado%2C%20United%20States&bounds=40.08044805%2C-104.30474314%2C39.47080521%2C-105.52641867&pub_activities=dance')}
                        {mostRecentPages('San Francisco Dance Space Rental','Dance, Ballet, Barre', '?address=San%20Francisco%2C%20California%2C%20United%20States&bounds=37.84958441%2C-122.18097503%2C37.58726685%2C-122.6917009&pub_activities=dance')}
                        {mostRecentPages('Culver City Dance Studio Rental','Barre Yoga, Dance, Small Group Training', '?address=?address=Culver%20City%2C%20California%2C%20United%20States&bounds=34.035080823%2C-118.369446006%2C33.978476187%2C-118.420881452&pub_activities=dance')}
                    </>
                }
            </div>
        </div>
    );

}

SectionTopSearchs.defaultProps = {
    className: null,
    rootClassName: null,
};

const { string } = PropTypes;

SectionTopSearchs.propTypes = {
    className: string,
    rootClassName: string,
};

export default SectionTopSearchs;