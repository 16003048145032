import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import moment from 'moment';
import classNames from 'classnames';
import { DATE_TYPE_DATE, BOOKING_OPTION_CLASS } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { dateIsBefore, resetToStartOfDay, isDayMomentInsideRange, isSameDay } from '../../util/dates';
import {
    Form,
    FieldDateInput,
    Button,
} from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import css from './BookingMonthForm.css';

const { Money } = sdkTypes;

const BookingMonthForm = ({
    rootClassName,
    className,
    submitButtonWrapperClassName,
    // isOwnListing,
    listing,
    onSubmit,
    monthlyTimeSlots,
    timeZone,
    unitPrice,
    unitType,
    startDatePlaceholder,
    intl,
}) => {

    const classes = classNames(rootClassName || css.root, className);

    const bookedDates = listing && listing.attributes.publicData.bookedDates
        ? listing.attributes.publicData.bookedDates : [];

    return (
        <FinalForm
            onSubmit={onSubmit}
            render={fieldRenderProps => {
                const {
                    handleSubmit,
                    values,
                    form,
                    userMissingRequiredFields
                } = fieldRenderProps;

                const { publicData } = listing.attributes;
                const isPrivate = publicData && publicData.spaceType ?
                    publicData.spaceType === 'private' : false;
                const availableTimeSlots = publicData && publicData.availableTimeSlots
                    ? publicData.availableTimeSlots
                    : {};

                const bookingStartDateLabel = intl.formatMessage({
                    id: 'BookingMonthForm.bookingStartTitle',
                });

                const submitButtonClasses = classNames(
                    submitButtonWrapperClassName || css.submitButtonWrapper
                );

                const handleStartDateChange = value => {
                    const startDate = value.date;
                    const endDate = startDate ? new Date(moment(startDate).tz(timeZone)
                        .add(1, 'year'))
                        : null;
                    form.change(`endDate`, { date: endDate });
                }

                const startDate = values.startDate ? values.startDate.date : null;
                const endDate = values.endDate ? values.endDate.date : null;

                const buttonDisabled = !startDate || userMissingRequiredFields;

                const unitPriceCustomerCommissionAmount = (unitPrice.amount * config.custom.BOOKING_OPTION_MONTH_COMMISSION)

                const bookingData =
                    startDate && endDate
                        ? {
                            unitType,
                            unitPrice,
                            startDate,
                            endDate,
                            unitPriceCustomerCommission: new Money(unitPriceCustomerCommissionAmount, config.currency),
                            timeZone,
                            dateType: DATE_TYPE_DATE,
                            availableTimeSlots,
                            intl
                        }
                        : null;

                const bookingInfo = bookingData ? (
                    <div className={css.priceBreakdownContainer}>
                        <EstimatedBreakdownMaybe bookingData={bookingData} />
                    </div>
                ) : null;

                // const checkForMonthAvailability = day => {
                //     const oneMonthInTheFuture = moment(day).add(1, 'month');
                //     return !!bookedDatesSample.find(b =>
                //         (b.type === BOOKING_OPTION_CLASS || b.type === BOOKING_OPTION_MONTH)
                //         && dateIsAfter(day, moment(b.start).tz(timeZone)) && dateIsBefore(day, moment(b.end).tz(timeZone)) ||
                //         isInRange(
                //             moment(b.start).tz(timeZone),
                //             day,
                //             new Date(oneMonthInTheFuture),
                //             timeZone
                //         ) || isSameDay(
                //             moment(b.start).tz(timeZone),
                //             day,
                //             timeZone))
                // }
                const checkForMonthAvailability = day => {
                    return !!bookedDates.find(b =>
                        (b.type === BOOKING_OPTION_CLASS && dateIsBefore(day, moment(b.start).add(1, 'year').tz(timeZone))) ||
                        dateIsBefore(day, moment(b.end).tz(timeZone)))
                }

                const allTimeSlots = Object.keys(monthlyTimeSlots).reduce((map, k) => {
                    if (monthlyTimeSlots[k].timeSlots) {
                        monthlyTimeSlots[k].timeSlots.map(t => {
                            return map.push({
                                ...t,
                                attributes: {
                                    ...t.attributes,
                                    type: 'time-slot/day',
                                    start: resetToStartOfDay(t.attributes.start, timeZone),
                                    end: resetToStartOfDay(t.attributes.start, timeZone, 1)
                                }
                            });
                        })
                    }
                    return map;
                }, [])

                const isDayBlocked =
                    day => isSameDay(day, moment().add(1, 'day')) ||
                        (monthlyTimeSlots && isPrivate ? checkForMonthAvailability(day) : false) ||
                        !allTimeSlots.find(timeSlot =>
                            isDayMomentInsideRange(
                                day,
                                timeSlot.attributes.start,
                                timeSlot.attributes.end,
                                timeZone
                            )
                        )
                // : () => false;

                return (
                    <Form onSubmit={handleSubmit} className={classes}>

                        <FieldDateInput
                            className={css.bookingDates}
                            name="startDate"
                            id="startDate"
                            label={bookingStartDateLabel}
                            placeholderText={startDatePlaceholder}
                            isDayBlocked={isDayBlocked}
                            useMobileMargins
                            onChange={handleStartDateChange} />

                        {bookingInfo}

                        <div className={submitButtonClasses}>
                            <Button
                                className={css.reserveButton}
                                disabled={buttonDisabled}
                                type="submit">
                                <div className={css.firstLine}>
                                    <FormattedMessage id="BookingMonthForm.requestToBook" />
                                </div>
                                <div className={css.secondLine}>
                                    <FormattedMessage id='BookingDatesForm.youWontBeChargedInfo' />
                                </div>
                            </Button>
                        </div>
                    </Form>
                )
            }} />
    )
}

export default BookingMonthForm;