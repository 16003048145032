import React from 'react';
import PropTypes, { func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { ListingCard } from '../../components';

import css from './SectionFeaturedListings.css';
import { propTypes } from '../../util/types';

const SectionFeaturedListings = props => {
  const { rootClassName, className, currentUser, listings, onUpdateSavedListings, ...rest } = props;

  // Panel width relative to the viewport
  // const panelMediumWidth = 50;
  // const panelLargeWidth = 62.5;
  // const cardRenderSizes = [
  //   `(max-width: 767px) ${panelMediumWidth / 3}vw`,
  //   `(max-width: 1023px) ${panelMediumWidth / 2}vw`,
  //   `(max-width: 1920px) ${panelLargeWidth / 4}vw`,
  //   `${panelLargeWidth / 4}vw`,
  // ].join(', ');

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionFeaturedListings.title" />
      </div>

      <div className={css.listings}>
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            currentUser={currentUser}
            listing={l}
            // renderSizes={cardRenderSizes}
            onUpdateSavedListings={onUpdateSavedListings}
            // setActiveListing={setActiveListing}
            {...rest}
          />
        ))}
      </div>

    </div>
  );
};

SectionFeaturedListings.defaultProps = { rootClassName: null, className: null, currentUser: null, listings: [] };

const { string, array } = PropTypes;

SectionFeaturedListings.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  listings: array,
  onUpdateSavedListings: func.isRequired
};

export default SectionFeaturedListings;
