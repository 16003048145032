import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { bool, func, node, oneOfType, shape, string, array } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS,
  BOOKING_OPTION_HOUR,
  BOOKING_OPTION_CLASS,
  BOOKING_OPTION_DAY,
  BOOKING_OPTION_MONTH,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button, NamedLink } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import { BookingTimeForm, BookingDatesForm, BookingMonthForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Popup } from 'semantic-ui-react';
import stripeImage from '../../assets/stripeImage.png';

import css from './BookingPanel.css';

const { Money } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const TODAY = new Date();

const priceData = (bookingOptions, intl) => {
  let formattedPrices = {};
  let priceTitles = {};
  let prices = {};

  if (bookingOptions && bookingOptions.length > 0) {
    bookingOptions.map(b => {
      const price = new Money(b.rate.amount, b.rate.currency);

      if (price && price.currency === config.currency) {
        const formattedPrice = formatMoney(intl, price);
        formattedPrices[b.key] = formattedPrice;
        priceTitles[b.key] = formattedPrice;
        prices[b.key] = price;
      } else if (price) {
        formattedPrices[b.key] = `(${price.currency})`;
        priceTitles[b.key] = `Unsupported currency (${price.currency})`;
        prices[b.key] = price;
      }

      return b;
    });

    return { formattedPrices, priceTitles, prices };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    history,
    location,
    intl,
    monthlyTimeSlots,
    onFetchTimeSlots,
    bookingOptions: bookingOptionsMaybe,
    userMissingRequiredFields
  } = props;

  const [state, setState] = useState({
    currentMenu: null
  });

  const timeZone = listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
      ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
      : null;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const handleSubmit = values => {
    const submitValues = {
      key: state.currentMenu,
      ...values,
    }
    onSubmit(submitValues);
  }

  // when user selects a new booking options
  const onChangeMenu = (e) => {
    const key = e.target.id;
    const extractKey = key.split('.')[0];
    setState({ ...state, currentMenu: extractKey });
  };

  // sort booking options
  const sortedBookingOptions = () => {
    const bookingOptions = Object.keys(bookingOptionsMaybe).map(key => {
      return {
        key,
        ...bookingOptionsMaybe[key],
        position: key === BOOKING_OPTION_HOUR ? 1 :
          key === BOOKING_OPTION_CLASS ? 2 :
            key === BOOKING_OPTION_DAY ? 3 :
              4
      }
    });
    bookingOptions.sort((a, b) => a.position - b.position);
    return bookingOptions;
  };

  const bookingOptions = bookingOptionsMaybe ? sortedBookingOptions() : null;

  const bookingOptionTranslationKey = (key) => {
    return `BookingPanels.option.${key}`;
  };

  const { formattedPrices, prices } = priceData(bookingOptions, intl);

  const bookingOptionsMenu = () => {
    const translationHelpText = key => intl.formatMessage({ id: `BookingPanel.optionHelpText.${key}` }, { breakLine: <br /> });
    return bookingOptions ? (
      <FinalForm
        onSubmit={() => { }}
        render={() => {
          return (
            <form>
              {bookingOptions.map((plan, index) => (
                <div className={css.bookingOptionsRoot} key={index}>
                  <div className={css.bookingOptionTopLine}>
                    <div className={css.topLineFirstColumn}>
                      {/* <input
                        className={css.radioInput}
                        type="radio"
                        id={`${plan.key}.select`}
                        name={`select-plan`}
                        onChange={onChangeMenu} /> */}
                      <label className={css.radioContainer}>
                        <input className={css.radioInput} type="radio" id={`${plan.key}.option`} name={`option-menu`} onChange={onChangeMenu} />
                        <span className={css.checkmark}></span>
                      </label>

                      <label className={css.bookingOptionlabel}>
                        <FormattedMessage id={bookingOptionTranslationKey(plan.key)} />
                        {/* WE ARE USING STYLE INSTEAD OF CLASS BECAUSE CSS MODULE SEEMS NOT TO BE WORKING */}
                        <Popup style={{ fontSize: 14 }} content={translationHelpText(plan.key)} trigger={<i className="question circle outline icon"></i>} />
                      </label>
                    </div>
                    <div className={css.topLineSecondColumn}>
                      <h3 className={css.bookingOptionprice}>{formattedPrices[plan.key]}</h3>
                    </div>
                  </div>
                  {plan.length ? (
                    <div className={css.bottomLine}>
                      <div className={css.bookingOptionMinimumLength}>
                        <FormattedMessage id="BookingPanels.minimumLength" values={{ count: plan.length }} />
                      </div>
                    </div>
                  ) : null}
                </div>
              ))
              }
            </form>
          )
        }}>
      </FinalForm>
    ) : (
        <div className={css.missingBookingOptions}>
          <FormattedMessage id="BookingPanels.missingBookingOptions" />
        </div>
      )
  }

  const dateFormattingOptions = {
    month: 'short', day: 'numeric', weekday: 'short'
  };

  const bookingForms = () => {
    switch (state.currentMenu) {
      case BOOKING_OPTION_CLASS:
      case BOOKING_OPTION_HOUR: {
        return <BookingTimeForm
          className={css.bookingForm}
          formId="BookingTimeForm"
          submitButtonWrapperClassName={css.submitButtonWrapper}
          unitType={LINE_ITEM_UNITS}
          onSubmit={handleSubmit}
          unitPrice={prices[state.currentMenu]}
          isOwnListing={isOwnListing}
          listing={listing}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          timeZone={timeZone}
          bookingOption={{
            key: state.currentMenu,
            ...bookingOptionsMaybe[state.currentMenu]
          }}
          userMissingRequiredFields={userMissingRequiredFields}
        />
      }
      case BOOKING_OPTION_DAY: {
        return <BookingDatesForm
          className={css.bookingForm}
          formId="BookingPanel"
          submitButtonWrapperClassName={css.submitButtonWrapper}
          unitType={LINE_ITEM_DAY}
          onSubmit={handleSubmit}
          unitPrice={prices[state.currentMenu]}
          isOwnListing={isOwnListing}
          listing={listing}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          timeZone={timeZone}
          intl={intl}
          userMissingRequiredFields={userMissingRequiredFields}
        />;
      }
      case BOOKING_OPTION_MONTH: {
        return <BookingMonthForm
          className={css.bookingForm}
          formId="BookingMonthForm"
          submitButtonWrapperClassName={css.submitButtonWrapper}
          unitType={LINE_ITEM_DAY}
          onSubmit={handleSubmit}
          unitPrice={prices[state.currentMenu]}
          isOwnListing={isOwnListing}
          listing={listing}
          monthlyTimeSlots={monthlyTimeSlots}
          onFetchTimeSlots={onFetchTimeSlots}
          startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
          timeZone={timeZone}
          intl={intl}
          userMissingRequiredFields={userMissingRequiredFields}
        />
      }
      default: return null;
    }
  }

  const updateProfileLink = (
    <NamedLink
      name="ProfileSettingsPage"
    >
      <FormattedMessage id="BookingPanel.updateProfileLink" />
    </NamedLink>
  );

  const userMissingRequiredFieldsErrorMessage = state.currentMenu && userMissingRequiredFields ? (
      <p className={css.error}>
        <FormattedMessage id="BookingPanel.userRequiredFieldsMissing" values={{ updateProfileLink }} />
      </p>
    ) : null;

  return (
    <div className={classes}>
      <ModalInMobile
        className={css.sticky}
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
          <div className={css.bookingOptionsMenuModal}>
            {bookingOptionsMenu()}
          </div>
        </div>

        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          {subTitleText ? 
            <div className={css.stripeContainer}>
              <p className={css.bookingHelp}>{subTitleText}</p>
              <img className={css.stripeImage} src={stripeImage} alt="Powered by Stripe" />
            </div>
           : null}
          {bookingOptionsMenu()}
        </div>

        {showBookingDatesForm ? bookingForms() : null}

        <div className={css.errors}>
          {userMissingRequiredFieldsErrorMessage}
        </div>

      </ModalInMobile>
      <div className={css.openBookingForm}>
        {showBookingDatesForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : (
            <div className={css.closedListingButton}>
              <FormattedMessage id="BookingPanel.closedListingButtonText" />
            </div>
          )}
      </div>
    </div >
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  // unitType: config.bookingUnitType,
  // timeSlots: null,
  // fetchTimeSlotsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  // unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  // timeSlots: arrayOf(propTypes.timeSlot),
  // fetchTimeSlotsError: propTypes.error,
  userMissingRequiredFields: array,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
