import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import  bob  from './images/Bob_Headshot.png';
import  terren  from './images/Terren_Headshot.png';
import  dog  from './images/Penny_Headshot.png';

import css from './SectionOurTeam.css';

const ourteam = (subtitle, description, image, alt) => {
    return (
            <div>
                <img className={css.teamMembers} src={image} alt={alt} />
                <h4 className={css.subtitle}>{subtitle}</h4>
                <p className={css.description}>{description}</p>
            </div>
    )
}

const SectionOurTeam = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    //const signUp = {name: "./AuthenticationPage.js"};

    return (
        <div className={classes}>
            <div className={css.container}>
                <p className={css.preTitle}>
                    <strong><FormattedMessage id="SectionOurTeam.pretitle" /></strong>
                </p>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionOurTeam.title" />
                </h2>
                <div className={css.grid}>
                    <div className={css.ourTeamContainer}>
                        {ourteam(
                            'Bob',
                            'Bob is a head cheerleader for the team  and is focused on making sure Gofynder is doing all that it can to empower its members. In his spare time Bob is a real goofball that loves being silly with the kids.',
                            bob,
                            'Gofynder co-founder, Bob',                           
                        )}
                                            </div>
                    <div className={css.ourTeamContainer}>
                        {ourteam(
                            'Terren',
                            'Terren ensures that the team stays grounded in reality and that all product developments are focused on our members. Since she is a professional yoga teacher she knows what our members need and she always makes sure we "keep it easy to use".',
                            terren,
                            'Gofynder co-founder, Terren',
                        )}
                                            </div>
                    <div className={css.ourTeamContainer}>
                        {ourteam(
                            'Penny',
                            'Penny is in change of all fun and distractions for the team. She makes sure that nothing is taken too seriously and that we give her lots of attention.',
                            dog,
                            'Gofynder company dog',
                        )}
                    </div>
                </div>
                <NamedLink
                    name="SignupPage"     
                    className={classNames(css.button, css.signUpButton)}>
                    <FormattedMessage id="SectionOurTeam.signUpButton" />
                </NamedLink>
            </div>
        </div>
    );
};

SectionOurTeam.defaultProps = { rootClassName: null, className: null };

SectionOurTeam.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionOurTeam;