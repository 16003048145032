import axios from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ListingLink } from '../../components';
import { EditListingSpecialInstructionsForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import css from './EditListingSpecialInstructionsPanel.css';


const EditListingSpecialInstructionsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const [ promoCodeIsValid, setPromoCodeIsValid ] = useState(null);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingSpecialInstructionsPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
      <FormattedMessage id="EditListingSpecialInstructionsPanel.createListingTitle" />
    );

  /*const promoCodeValidation = promoCode =>{
    return axios.get('promoCodeVerification', promoCode)
    .then(
      response => {
        console.log(response);
        return response.config[0];
      });
  }*/

  const validatePromoCode = async (promoCode) => {

    const cleanPromoCode = promoCode.trim().toLowerCase()

    if (!cleanPromoCode) {
      setPromoCodeIsValid(null);
      return null
    }

    return await axios.get("https://spreadsheets.google.com/feeds/cells/1mIT0tOkgEZcPGhO5tGziKbE_RSYZCkTjMRyFI7MgNQY/1/public/full?alt=json").then((response) => {

      const validCodes = response.data.feed.entry.map(({content}) => content.$t.trim().toLowerCase());

      const isValid = validCodes.indexOf(cleanPromoCode) >= 0;

      return isValid;

    });
  }

  return (
    <div className={classes}>

      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingSpecialInstructionsForm
        className={css.form}
        publicData={publicData}
        initialValues={{ instructions: publicData.instructions, rules: publicData.rules , promoCode: publicData.promoCode, notes: publicData.notes}}
        onSubmit={async (values) => {

          const { instructions = '', rules = '' , promoCode = '', notes = '' } = values;
          const updateValues = {
            publicData: {
              notes,
              instructions,
              rules,
              promoCode,
            },
          };

          if (!promoCode) {
            setPromoCodeIsValid(null);
            return onSubmit(updateValues, null);
          }

          if (!await validatePromoCode(promoCode)) return setPromoCodeIsValid(false)

          return onSubmit(updateValues, promoCodeIsValid);

        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        promoCodeIsValid={promoCodeIsValid}
      />

    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingSpecialInstructionsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingSpecialInstructionsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingSpecialInstructionsPanel;
