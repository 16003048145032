import React from 'react';
import moment from 'moment';
import { FormattedDate } from '../../util/reactIntl';
import { BOOKING_OPTION_HOUR } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemDatePickerDisplayMaybe = props => {
  const { bookingOption, booking, timeZone, useCheckout } = props;

  const isHourly = bookingOption === BOOKING_OPTION_HOUR;

  if (!isHourly || !useCheckout) return null;

  const { start, end, displayStart, displayEnd } = booking.attributes;

  const startDate = start || displayStart;
  // ISSUE: Due to the 24 hour problem this work around that saves the 24h as 23:55 to fix the day and month bug in the calendar
  let endDate = end || displayEnd;

  const timeZoneMaybe = timeZone ? { timeZone } : null;
  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };
  const timeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric'
  };

  // ISSUE: Due to the 24 hour problem this work around that saves the 24h as 23:55 to fix the day and month bug in the calendar 
  if(endDate && endDate.getMinutes() === 55){
    endDate = moment(endDate).add(5, 'minutes').toDate();
  }

  return (
    <div className={css.datePickerDisplay}>
      <div className={css.datePickerDateItem}>
        <label className={css.datePickerLabel}>Date</label>
        <div className={css.datePickerValue}>
          <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
        </div>
        <div className={css.datePickerSeparator} />
      </div>
      <div className={css.datePickerTimesWrapper}>
        <div className={css.datePickerTimeItem}>
          <label className={css.datePickerLabel}>Start time</label>
          <div className={css.datePickerValue}>
            <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} />
          </div>
          <div className={css.datePickerSeparator} />
        </div>
        <div className={css.datePickerTimeItem}>
          <label className={css.datePickerLabel}>End time</label>
          <div className={css.datePickerValue}>
            {console.log(endDate)}
            <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} />
          </div>
          <div className={css.datePickerSeparator} />
        </div>
      </div>
    </div>
  );
};

export default LineItemDatePickerDisplayMaybe;
