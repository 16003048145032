import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { Button, FieldTextInput, Form } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import css from './EditListingSpecialInstructionsForm.css';


export const EditListingSpecialInstructionsForm = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        promoCodeIsValid
      } = formRenderProps;

      const notesLabelMessage = intl.formatMessage({
        id: 'EditListingSpecialInstructionsForm.notesLabel',
      });
      const notesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingSpecialInstructionsForm.notesPlaceholder',
      });

      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingSpecialInstructionsForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingSpecialInstructionsForm.rulesPlaceholder',
      });

      const instructionsLabelMessage = intl.formatMessage({
        id: 'EditListingSpecialInstructionsForm.instructionsLabel',
      });
      const instructionsErrorLabelMessage = intl.formatMessage({
        id: 'EditListingSpecialInstructionsForm.instructionsErrorLabelMessage',
      });
      const instructionsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingSpecialInstructionsForm.instructionsPlaceholder',
      });

      const promoCodeLabelMessage = intl.formatMessage({
        id: 'EditListingSpecialInstructionsForm.promoCodeLabel',
      });
      const promoCodePlaceholderMessage = intl.formatMessage({
        id: 'EditListingSpecialInstructionsForm.promoCodePlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSpecialInstructionsForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingSpecialInstructionsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const isPromoCodeValid = (promoCodeIsValid === false)? <Alert variant="danger" >
          That promo code is invalid, please check your spelling or leave the field blank.
        </Alert> : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldTextInput
            id="notes"
            name="notes"
            className={css.instructions}
            type="textarea"
            rows="6"
            label={notesLabelMessage}
            placeholder={notesPlaceholderMessage}
          />

          <FieldTextInput
            id="rules"
            name="rules"
            className={css.rules}
            type="textarea"
            rows="6"
            label={rulesLabelMessage}
            placeholder={rulesPlaceholderMessage}
          />

          <FieldTextInput
            id="instructions"
            name="instructions"
            className={css.instructions}
            type="textarea"
            rows="6"
            label={instructionsLabelMessage}
            placeholder={instructionsPlaceholderMessage}
            validate={required(instructionsErrorLabelMessage)}
          />

          <FieldTextInput
            id="promoCode"
            name="promoCode"
            className={css.rules}
            type="textarea"
            rows="1"
            label={promoCodeLabelMessage}
            placeholder={promoCodePlaceholderMessage}
          />
          {isPromoCodeValid}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingSpecialInstructionsForm.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingSpecialInstructionsForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingSpecialInstructionsForm);
