import React from 'react';
import PropTypes, { func } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import { ListingCard } from '../../../components';

import css from './SectionFeaturedListings.css';
import { propTypes } from '../../../util/types';

const SectionFeaturedListings = props => {
  const { rootClassName, className, currentUser, listings, onUpdateSavedListings, ...rest } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionFeaturedListings.title" />
      </div>

      <div className={css.listings}>
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            currentUser={currentUser}
            listing={l}
            // renderSizes={cardRenderSizes}
            onUpdateSavedListings={onUpdateSavedListings}
            // setActiveListing={setActiveListing}
            {...rest}
          />
        ))}
      </div>

    </div>
  );
};

SectionFeaturedListings.defaultProps = { rootClassName: null, className: null, currentUser: null, listings: [] };

const { string, array } = PropTypes;

SectionFeaturedListings.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  listings: array,
  onUpdateSavedListings: func.isRequired
};

export default SectionFeaturedListings;
