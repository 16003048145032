import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldRadioButton } from '../../components';

import css from './EditListingNumberSpacesForm.css';

const EditListingNumberSpacesFormComponent = props => (
    <FinalForm
        {...props}
        render={formRenderProps => {
            const {
                className,
                disabled,
                ready,
                handleSubmit,
                intl,
                invalid,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
            } = formRenderProps;

            const questionMessage = intl.formatMessage({ id: 'EditListingNumberSpacesForm.question' });
            const descriptionMessage = intl.formatMessage({ id: 'EditListingNumberSpacesForm.description' });
            //   const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
            //   const titlePlaceholderMessage = intl.formatMessage({
            //     id: 'EditListingDescriptionForm.titlePlaceholder',
            //   });
            //   const titleRequiredMessage = intl.formatMessage({
            //     id: 'EditListingDescriptionForm.titleRequired',
            //   });
            //   const maxLengthMessage = intl.formatMessage(
            //     { id: 'EditListingDescriptionForm.maxLength' },
            //     {
            //       maxLength: TITLE_MAX_LENGTH,
            //     }
            //   );

            //   const descriptionMessage = intl.formatMessage({
            //     id: 'EditListingDescriptionForm.description',
            //   });
            //   const descriptionPlaceholderMessage = intl.formatMessage({
            //     id: 'EditListingDescriptionForm.descriptionPlaceholder',
            //   });
            //   const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
            //   const descriptionRequiredMessage = intl.formatMessage({
            //     id: 'EditListingDescriptionForm.descriptionRequired',
            //   });

            const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
            const errorMessageUpdateListing = updateListingError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingNumberSpaces.updateFailed" />
                </p>
            ) : null;

            // This error happens only on first tab (of EditListingWizard)
            const errorMessageCreateListingDraft = createListingDraftError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingNumberSpaces.createListingDraftError" />
                </p>
            ) : null;

            const errorMessageShowListing = showListingsError ? (
                <p className={css.error}>
                    <FormattedMessage id="EditListingNumberSpaces.showListingFailed" />
                </p>
            ) : null;

            const classes = classNames(css.root, className);
            const submitReady = (updated && pristine) || ready;
            const submitInProgress = updateInProgress;
            const submitDisabled = invalid || disabled || submitInProgress;

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    {errorMessageCreateListingDraft}
                    {errorMessageUpdateListing}
                    {errorMessageShowListing}

                    <h2 className={css.question}>{questionMessage}</h2>
                    <p className={css.description}>{descriptionMessage}</p>

                    <div className={css.optionsContainer}>
                        <FieldRadioButton
                            rootClassName={css.radioButton}
                            id="one"
                            name="numberOfSpaces"
                            label="1"
                            value="1" />
                        <FieldRadioButton
                            rootClassName={css.radioButton}
                            id="two"
                            name="numberOfSpaces"
                            label="2"
                            value="2" />
                        <FieldRadioButton
                            rootClassName={css.radioButton}
                            id="three"
                            name="numberOfSpaces"
                            label="3"
                            value="3" />
                        <FieldRadioButton
                            rootClassName={css.radioButton}
                            id="four"
                            name="numberOfSpaces"
                            label="4"
                            value="4" />
                    </div>

                    <Button
                        className={css.submitButton}
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                        ready={submitReady}
                    >
                        {saveActionMsg}
                    </Button>

                </Form>
            );
        }}
    />
);

EditListingNumberSpacesFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingNumberSpacesFormComponent.propTypes = {
    className: string,
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        createListingDraftError: propTypes.error,
        showListingsError: propTypes.error,
        updateListingError: propTypes.error,
    }),
};

export default compose(injectIntl)(EditListingNumberSpacesFormComponent);
