import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureCurrentUser, sortBookingOptions } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './ListingCard.css';

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price, 0, 0);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { currentUser, className, rootClassName, history, location, intl, listing, renderSizes, setActiveListing, onUpdateSavedListings } = props;

  const user = ensureCurrentUser(currentUser);
  const { profile } = user.attributes;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const { bookingOptions: bookingOptionsMaybe, activities, spaceType: spaceTypeMaybe } = publicData || {};
  const bookingOptions = bookingOptionsMaybe ? sortBookingOptions(bookingOptionsMaybe||{}) : {};
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  
  const prices = bookingOptions && bookingOptions.length > 0 ? bookingOptions.map(bookingOption => {
    const { formattedPrice } = priceData(new Money(bookingOption.rate.amount, bookingOption.rate.currency), intl);
    return {
      key: bookingOption.key.charAt(0).toUpperCase() + bookingOption.key.substring(1),
      formattedPrice,
    }
  }) : null

  const activitiesConst = activities ? activities.reduce((map, activity) => {
    if (!map) return map.concat(activity);
    return map.concat(` | ${activity}`);
  }, '') : null;

  const spaceType = publicData && spaceTypeMaybe ? spaceTypeMaybe : null;
  const userFavoriteListings = profile ? profile.publicData.savedListings || [] : [];
  const isSavedAsFavorite = userFavoriteListings.find(l => l === listing.id.uuid) ? true : false;
  const addToFavoriteButtonClassnames = classNames(css.addToFavorite, {
    [css.isFavorite]: isSavedAsFavorite
  })

  const handleOnAddToFavorite = e => {
    e.preventDefault();
    if (!user.id) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };
      const signupURL = createResourceLocatorString('SignupPage', routeConfiguration(), {}, {});
      history.push(signupURL, state);
      return;
    }
    const listingId = listing.id.uuid;
    let newUserFavoriteListings = userFavoriteListings;
    if (isSavedAsFavorite) {
      newUserFavoriteListings = userFavoriteListings.reduce((map, l) => {
        if (l === listingId) return map;
        return map.concat(l);
      }, []);
    } else {
      newUserFavoriteListings = [...userFavoriteListings, listingId];
    }
    onUpdateSavedListings(newUserFavoriteListings);
  }

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }} newTab={true}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >

        <div className={addToFavoriteButtonClassnames} onClick={handleOnAddToFavorite}>
          {isSavedAsFavorite ? <i className="heart icon" /> : <i className="heart outline icon" />}
        </div>

        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        {spaceType ? (
          <div className={css.spaceType}>
            <FormattedMessage id={`ListingCard.spaceType.${spaceType}`} />
          </div>
        ) : null}

        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>

          {prices ? (
            <div className={css.prices}>
              {prices.map(price => (
                <React.Fragment key={price.key}>
                  <div className={css.price}>
                    <FormattedMessage id={`ListingCard.pricePer${price.key}`} values={{ formmatedPrice: price.formattedPrice }} />
                  </div>
                </React.Fragment>
              ))}
            </div>
          ) : null}

          <div className={css.activitiesWrapper}>
            <div className={css.activitiesTitle}><FormattedMessage id="ListingCard.activitiesTitle" /></div> {activitiesConst}
          </div>

        </div>
      </div>
    </NamedLink >
  );
};

ListingCardComponent.defaultProps = {
  currentUser: null,
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  currentUser: propTypes.currentUser,
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  onUpdateSavedListings: func.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);