import React from 'react';
import { bool, arrayOf } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, propTypes } from '../../util/types';
import { convertMoneyToNumber, convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { Decimal } from 'decimal.js';
import config from '../../config';
import { Popup } from 'semantic-ui-react';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount >= 0
  );
};

const estimatedTotalCommission = (transactions) => {
  const totalPrice = transactions.reduce((decimalTotalPrice, transaction) => {
    const customerCommissionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal
    );
    if (!isValidCommission(customerCommissionLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission line item:', customerCommissionLineItem);
      throw new Error('Commission should be present and the value should be zero or positive');
    }
    const numericPrice = convertMoneyToNumber(customerCommissionLineItem.lineTotal);
    return decimalTotalPrice.plus(numericPrice);
  }, new Decimal(0));

  const numericTotalPrice = totalPrice.toNumber();

  return new Money(
    convertUnitToSubUnit(numericTotalPrice, unitDivisor(config.currency)),
    config.currency
  );
};

const LineItemCustomerCommissionMaybe = props => {
  const { transactions, isCustomer, intl } = props;

  const totalCommission = estimatedTotalCommission(transactions);
  const formattedCommission = formatMoney(intl, totalCommission);

  // If commission is passed it will be shown as a fee already reduces from the total price
  let commissionItem = null;

  const helpTextMessage = intl.formatMessage({ id: 'BookingBreakdown.feeHelpText' });

  if (isCustomer && totalCommission) {
    commissionItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.commission" />
          <Popup style={{fontSize: 14}} content={helpTextMessage} trigger={<i className="question circle outline icon"></i>} />
        </span>
        <span className={css.itemValue}>{formattedCommission}</span>
      </div>
    );
  }

  return commissionItem;
};

LineItemCustomerCommissionMaybe.propTypes = {
  transactions: arrayOf(propTypes.transaction.isRequired),
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionMaybe;
