import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHowItWorks.css';

const bookingType = (title, description) => {
    return (
        <div className={css.bookingWrapper}>
            <div className={css.bookingInfo}>
                <h2 className={css.bookingTitle}>{title}</h2>
                <p className={css.bookingDescription}>{description}</p>
            </div>
        </div>
    );
};

const SectionHowItWorks = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <div className={css.image}>
                    <span role="img" aria-label="Gym space available for rent in Los Angeles"></span>
                </div>
                <div className={css.content}>
                    <p className={css.preTitle}>
                        <FormattedMessage id="SectionHowItWorks.preTitle" />
                    </p>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionHowItWorks.title" />
                    </h2>
                    <div className={css.grid}>
                        {bookingType(
                            'By the Hour',
                            `Use spaces for short periods of time as you need them. Limit 
                            your costs while still having access to great facilities.`
                        )}
                        {bookingType(
                            'By the Day',
                            `Want to put on a workshop or run your clients back-to-back on
                            one day? Now you can book a space for the whole day.`
                        )}
                        {bookingType(
                            'By the Class Slot',
                            `Want to build a following by being in the same space every week? Now
                            you can suscribe to a specific time slot that is yours every week using 
                            our Class Slot booking option.`
                        )}
                        {bookingType(
                            'By the Month',
                            `With our monthly booking option you can book a suscription that lets you
                            use a space whenever you want. Giving you maximum flexibility.`
                        )}
                    </div>
                    <div className={css.more}>
                        <NamedLink
                            name="HowItWorksPage"
                            to={{
                                hash: '#booking-options'
                            }}
                        >
                            <FormattedMessage id="SectionHowItWorks.learnMore" />
                        </NamedLink>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

SectionHowItWorks.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionHowItWorks;
