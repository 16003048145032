import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_PUBLISHED, LISTING_STATE_PENDING_APPROVAL } from '../../util/types';
import {
    Button,
    PrimaryButton
} from '../../components';

import css from './EditListingAllDonePanel.css';

const EditListingAllDonePanel = props => {
    const {
        className,
        rootClassName,
        listing,
        disabled,
        ready,
        onSubmit,
        onNextTab,
        updateInProgress,
        intl
    } = props;


    const allDoneText = intl.formatMessage({ id: 'EditListingAllDonePanel.allDone' });
    const addAnotherListingText = intl.formatMessage({ id: 'EditListingAllDonePanel.addAnotherListing' });

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    const isPendingApproval =
        currentListing && currentListing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
    const isPublished = currentListing.id && currentListing.attributes.state === LISTING_STATE_PUBLISHED;

    const panelTitle = <FormattedMessage id="EditListingAllDonePanel.title" />;

    return (
        <div className={classes}>
            <h1 className={css.title}>{panelTitle}</h1>

            {isPendingApproval ? (
                <div className={css.body}>
                    <p><FormattedMessage id="EditListingAllDonePanel.description1" /></p>
                    <p><FormattedMessage id="EditListingAllDonePanel.description2" /></p>
                    <p><FormattedMessage id="EditListingAllDonePanel.description3" /></p>
                </div>
            ) : null}

            {isPublished ? (
                <>
                    <p>Your listing is complete and ready to receive new bookings.</p>
                </>
            ) : null}

            <div className={css.buttonsWrapper}>
                <Button
                    className={css.submitButton}
                    onClick={onSubmit}
                    inProgress={updateInProgress}
                    disabled={disabled}
                    ready={ready}>
                    {allDoneText}
                </Button>
                <PrimaryButton
                    className={classNames(css.submitButton, css.addAnotherListingButton)}
                    onClick={onNextTab}>
                    {addAnotherListingText}
                </PrimaryButton>
            </div>
        </div>
    )
};

EditListingAllDonePanel.defaultProps = {
    className: null,
    rootClassName: null,
    listing: null,
};

EditListingAllDonePanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onNextTab: func.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
    intl: intlShape.isRequired,
};

export default EditListingAllDonePanel;