import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { BOOKING_OPTION_MONTH } from '../../util/types';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import { TimeSlotLine } from './LineItemBookingPeriod';
import moment from 'moment';

import css from './BookingBreakdown.css';

const LiteItemDetailsByMonthMaybe = props => {
  const { bookingOption, booking, availableTimeSlots, timeZone, useCheckout } = props;

  const isDaily = bookingOption === BOOKING_OPTION_MONTH;

  if (!useCheckout || !isDaily) return null;

  const { start, end, displayStart, displayEnd } = booking.attributes;

  const startDate = dateFromAPIToLocalNoon(displayStart || start)
  const endDateRaw = dateFromAPIToLocalNoon(displayEnd || end);

  const endDate = moment(endDateRaw).subtract(1, 'days'); // eslint-disable-line no-unused-vars

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const weekday = moment(startDate).format('ddd').toLocaleLowerCase();
  const availableTimeSlotsMaybe = weekday && availableTimeSlots
    ? availableTimeSlots[weekday] ? availableTimeSlots[weekday] : []
    : [];

  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  return (
    <div className={css.bookingPeriodCheckout}>
      <h3 className={css.bookingSummaryTitle}>
        <FormattedMessage id="BookingBreakdown.startDateTitle" />
      </h3>
      <div className={css.dateLabel}>
        <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
      </div>
      <div className={css.timeSlotsWrapper}>
        <TimeSlotLine id={1} weekday={"hour"} timeSlots={availableTimeSlotsMaybe} timeZoneMaybe={null} />
      </div>

      <h3 className={css.bookingSummaryTitle}>
        <FormattedMessage id="BookingBreakdown.hoursDetailsTitle" />
      </h3>
      <div className={css.bookingTimeSlotsDescription}>
        <FormattedMessage id="BookingBreakdown.hoursDetailsSubTitle" />
      </div>
      <div className={css.bookingTimeSlotsDetails}>
        {/* we set the time zone to null because we are not using datetime, we fetch the time slot from the availability plan */}
        {Object.keys(availableTimeSlots).map((weekday, index) => {
          return <TimeSlotLine key={index} weekday={weekday} timeSlots={availableTimeSlots[weekday]} timeZoneMaybe={null} />
        })}
      </div>
    </div>
  );
};

export default LiteItemDetailsByMonthMaybe;
