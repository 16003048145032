import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import covidImage from '../../assets/1AnyP8rQ.png';

import css from './SectionCovid.css';

const SectionCovid = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <img className={css.image} src={covidImage} alt="Person Cleaning" />
            <div className={css.container}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionCovid.title" />
                </h2>
                <h2 className={css.description}>
                    <FormattedMessage id="SectionCovid.description" />
                </h2>
                <ExternalLink
                    key="CovidSection"
                    href="http://www.gofynder.info/covid-19-information"
                    className={css.learnMore}
                    title="goToCovidInformation"
                >
                    <FormattedMessage className={css.learnMoreText} id="SectionCovid.learnMore" />
                </ExternalLink>
            </div>
        </div>
    );
};

SectionCovid.defaultProps = { rootClassName: null, className: null };

SectionCovid.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionCovid;