import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, LocationAutocompleteInputField } from '../../components';

import css from './GetStartedHostPageForm.css';

class GetStartedHostPageFormComponent extends Component {

    render() {
        return (
            <FinalForm
                {...this.props}
                render={fieldRenderProps => {
                    const {
                        rootClassName,
                        className,
                        formId,
                        handleSubmit,
                        inProgress,
                        onError,
                        intl,
                        invalid,
                    } = fieldRenderProps;

                    // Address
                    const addressPlaceholder = intl.formatMessage({
                        id: 'GetStartedHostPageForm.addressPlaceholder',
                    });
                    const addressRequiredMessage = intl.formatMessage({
                        id: 'GetStartedHostPageForm.addressRequiredMessage',
                    });
                    const addressNotRecognizedMessage = intl.formatMessage({
                        id: 'GetStartedHostPageForm.addressNotRecognized',
                      });

                    const onCreateListingDraftErrorMessage = onError
                        ? <p className={css.error}><FormattedMessage id="GetStartedHostPageForm.submitErrorMessage" /></p>
                        : null;

                    const classes = classNames(rootClassName || css.root, className);
                    const submitDisabled =
                        invalid ||
                        inProgress;

                    return (
                        <Form
                            className={classes}
                            onSubmit={e => {
                                handleSubmit(e);
                            }}
                        >
                            <div className={css.twoColumns}>
                                <LocationAutocompleteInputField
                                    className={css.fieldAddress}
                                    inputClassName={css.rootField}
                                    iconClassName={css.icon}
                                    useDefaultPredictions={false}
                                    name="address"
                                    id={formId ? `${formId}.address` : 'address'}
                                    placeholder={addressPlaceholder}
                                    validate={validators.composeValidators(
                                        validators.autocompleteSearchRequired(addressRequiredMessage),
                                        validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                                    )}
                                />
                            </div>

                            <PrimaryButton
                                className={css.button}
                                type="submit"
                                inProgress={inProgress}
                                // ready={pristineSinceLastSubmit}
                                disabled={submitDisabled}
                            >
                                <FormattedMessage id="GetStartedHostPageForm.getStarted" />
                            </PrimaryButton>
                            {onCreateListingDraftErrorMessage}
                        </Form>
                    );
                }}
            />
        );
    }
}

GetStartedHostPageFormComponent.defaultProps = {
    ContactFormComponent: null,
    className: null,
    formId: null,
    inProgress: false,
};

const { bool, string } = PropTypes;

GetStartedHostPageFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    formId: string,
    inProgress: bool,
    intl: intlShape.isRequired,
};

const GetStartedHostPageForm = compose(injectIntl)(GetStartedHostPageFormComponent);

GetStartedHostPageForm.displayName = 'GetStartedHostPageForm';

export default GetStartedHostPageForm;
