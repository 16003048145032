import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionDidYouKnow.css';


const didYouKnow = (porcentage, subtitle, entity, description) => {
    return (
        <div className={css.didYouKnowContainer}>
            <h2 className={css.porcentage}>{porcentage}</h2>
            <h3 className={css.subtitle}>{subtitle}</h3>
            <small className={css.entity}>{entity}</small>
            <p className={css.description}>{description}</p>
        </div>
    )
}

const SectionDidYouKnow = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <h2 className={css.title}>
                <i><FormattedMessage id="SectionDidYouKnow.title" /></i>
            </h2>
            <div className={css.grid}>
                {didYouKnow(
                    '66%',
                    'of independent studios are open for less than half a week',
                    'Association of Fitness Studios',
                )}
                {didYouKnow(
                    '49%',
                    'of personal trainers are independent contractors',
                    'American Council on Excercise',
                )}
                {didYouKnow(
                    '36%',
                    'of Fit pros are looking to open their own fitness businesses',
                    'Association of Fitness Studios',
                )}
            </div>
        </div>
    );
};

SectionDidYouKnow.defaultProps = { rootClassName: null, className: null };

SectionDidYouKnow.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionDidYouKnow;