import React from 'react';
import classNames from 'classnames';
import {
  DATE_TYPE_DATE,
  DATE_TYPE_DATETIME,
  BOOKING_OPTION_MONTH,
  BOOKING_OPTION_DAY,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS
} from '../../util/types';
import { ensureListing } from '../../util/data';
import { BookingBreakdownMultiple } from '../../components';

import css from './TransactionPanel.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const { className, rootClassName, breakdownClassName, transaction, transactionRole, bookingPlan, availableTimeSlots } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  const unitType =
    bookingPlan === BOOKING_OPTION_DAY ||
      bookingPlan === BOOKING_OPTION_MONTH
      ? LINE_ITEM_DAY
      : LINE_ITEM_UNITS;

  const dateType =
    bookingPlan === BOOKING_OPTION_DAY ||
      bookingPlan === BOOKING_OPTION_MONTH
      ? DATE_TYPE_DATE
      : DATE_TYPE_DATETIME;

  return loaded ? (
    <div className={classes}>
      <BookingBreakdownMultiple
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={unitType}
        transactions={[transaction]}
        bookings={[transaction.booking]}
        dateType={dateType}
        bookingOption={bookingPlan}
        timeZone={timeZone}
        availableTimeSlots={availableTimeSlots}
        useCheckout={true}
      />
      {/* <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={unitType}
        transaction={transaction}
        booking={transaction.booking}
        dateType={dateType}
        bookingOption={bookingPlan}
        timeZone={timeZone}
      /> */}
    </div>
  ) : null;
};

export default BreakdownMaybe;
