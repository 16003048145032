import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionStartHosting.css';

const SectionStartHosting = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionStartHosting.title" />
                </h2>
                <p className={css.info}>
                    <FormattedMessage id="SectionStartHosting.description" />
                </p>
                <NamedLink
                    name="HostPage"           
                    className={css.startHosting}>
                    <FormattedMessage id="SectionStartHosting.startHostingToday" />
                </NamedLink>
            </div>
        </div>
    );
};

SectionStartHosting.defaultProps = { rootClassName: null, className: null };

SectionStartHosting.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionStartHosting;