import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import moment from 'moment';

import css from './ListingPage.css';

const SectionAvailabilityMaybe = props => {
  const { publicData } = props;

  const { availableTimeSlots } = publicData;

  const weekdays = {
    'sun': 'Sunday',
    'mon': 'Monday',
    'tue': 'Tuesday',
    'wed': 'Wednesday',
    'thu': 'Thursday',
    'fri': 'Friday',
    'sat': 'Saturday',
  }
  const weekdaysKeys = Object.keys(weekdays);

  const format12HourClock = h => (moment(h, 'h:mm').format('h:mm A'));

  return availableTimeSlots ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.availabilityTitle" />
      </h2>
      <div className={css.chart}>
        {
          weekdaysKeys.map(day => {
              return(
                <div className={css.dayLine}>
                  <div className={css.dayName}>
                    {weekdays[day]}
                  </div>
                  <div className={css.timeSlots}>
                    {availableTimeSlots[day] ? availableTimeSlots[day].map(timeSlot => {
                      const endTime = timeSlot.endTime === "23:55" ? "24:00" : timeSlot.endTime;
                      return(
                          <span>
                            {format12HourClock(timeSlot.startTime).toLowerCase() + ' - ' + format12HourClock(endTime).toLowerCase()}
                          </span>
                      )
                    }): null}
                  </div>
                </div>
              )
          })
        }
      </div>
    </div>
  ) : null;
};

export default SectionAvailabilityMaybe;
