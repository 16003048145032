import React from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import { findPhoneNumbersInText } from 'libphonenumber-js'
import { Avatar, InlineTextButton, ReviewRating, UserDisplayName } from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  transitionIsReviewed,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
  isCustomerReview,
  isProviderReview,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_CANCEL_BY_CUSTOMER_WITH_REFUND,
  TRANSITION_CANCEL_BY_CUSTOMER_NO_REFUND,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_COMPLETE_FIRST_TIME,
  TRANSITION_START_SUBSCRIPTION,
  TRANSITION_SUBSCRIPTION_CANCELLED_BY_CUSTOMER,
  TRANSITION_SUBSCRIPTION_CANCELLED_BY_PROVIDER,
  TRANSITION_SUBSCRIPTION_LATE_CANCELLED_BY_CUSTOMER,
  TRANSITION_COMPLETE_BOOKING_PERIOD
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';
import moment from 'moment';

import css from './ActivityFeed.css';

const validateIfPhoneNumberExists = (value) => {
  const phoneNumbers = findPhoneNumbersInText(value, 'US');
  return phoneNumbers.length > 0;
}

const validateMessage = (message) => {
  for(var i = 0; i < message.length; i++){
    const stringIsANumber = !isNaN(message[i]);
    const lessThanFourDigits =  message[i].length < 5;
    if (stringIsANumber === true && !lessThanFourDigits) {
      return true;
    }
  }
  return false;
}

const verifyMessage = message =>{
  //Replace Email
  var hasEmail = message.replace(/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/, "*-*-*");

  const separateMessage = message.split(" ");
  const hasPhoneNumbers = validateMessage(separateMessage);
  const getPhoneNumbers = validateIfPhoneNumberExists(message);

  //If the message has emails then invalid the message.
  if(hasEmail.includes("*-*-*") || hasPhoneNumbers || getPhoneNumbers){
    message = "Invalid Message: For added safety and your protection, messages that share personal information like phone numbers and email addresses are prohibited.";
  }
  return message;
}

const Message = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={message.sender} />
      <div>
        <p className={css.messageContent}>{verifyMessage(message.attributes.content)}</p>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const EmailMessage = props => {
  const { message, currentUser, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={currentUser} />
      <div>
        <p className={css.messageContent}>{verifyMessage(message.attributes.content)}</p>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, new Date(message.attributes.createdAt))}
        </p>
      </div>
    </div>
  );
};

EmailMessage.propTypes = {
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent}>{verifyMessage(message.attributes.content)}</p>
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnEmailMessage = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent}>{verifyMessage(message.attributes.content)}</p>
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, new Date(message.attributes.createdAt))}
      </p>
    </div>
  );
};

OwnEmailMessage.propTypes = {
  intl: intlShape.isRequired,
};

const Review = props => {
  const { content, rating } = props;
  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      <ReviewRating
        reviewStarClassName={css.reviewStar}
        className={css.reviewStars}
        rating={rating}
      />
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer insted of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};


const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal,
  subscription
) => {
  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;
  const subscriptionAvailable = !!(
    subscription &&
    subscription.id
  );

  const getLeaveReviewLink = () => {
    const reviewPeriodJustStarted = txIsDelivered(transaction);

    const reviewAsFirstLink = reviewPeriodJustStarted ? (
      <InlineTextButton onClick={onOpenReviewModal}>
        <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
      </InlineTextButton>
    ) : null;
    return reviewAsFirstLink;
  }

  const getExpirationDate = () => {
    const last_day = subscriptionAvailable ? (
      !!subscription.cancel_at
        ? subscription.cancel_at
        : subscription.ended_at
          ? subscription.ended_at
          : subscription.current_period_end
            ? subscription.current_period_end : null
    ) : null;
    return subscriptionAvailable
      ? intl.formatDate(moment.unix(last_day), { weekday: 'short', month: 'short', day: 'numeric', })
      : null;
  }

  switch (currentTransition) {
    case TRANSITION_CONFIRM_PAYMENT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequest" values={{ listingTitle }} />
      ) : (
          <FormattedMessage
            id="ActivityFeed.transitionRequest"
            values={{ displayName, listingTitle }}
          />
        );
    case TRANSITION_ACCEPT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAccept" />
      ) : (
          <FormattedMessage id="ActivityFeed.transitionAccept" values={{ displayName }} />
        );
    case TRANSITION_DECLINE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDecline" />
      ) : (
          <FormattedMessage id="ActivityFeed.transitionDecline" values={{ displayName }} />
        );
    case TRANSITION_EXPIRE:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExpire" />
      ) : (
          <FormattedMessage id="ActivityFeed.transitionExpire" values={{ displayName }} />
        );

    case TRANSITION_CANCEL_BY_PROVIDER:
    case TRANSITION_CANCEL_BY_CUSTOMER:
    case TRANSITION_CANCEL_BY_CUSTOMER_WITH_REFUND:
    case TRANSITION_CANCEL_BY_CUSTOMER_NO_REFUND:
    case TRANSITION_CANCEL:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;

    case TRANSITION_COMPLETE:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
          values={{ reviewLink: getLeaveReviewLink() }}
        />
      );

    case TRANSITION_START_SUBSCRIPTION:
      return (
        <FormattedMessage
          id="ActivityFeed.subscriptionRunning"
        />
      );

    // Soon to be deprecated - we use transition_start_running instead
    case TRANSITION_COMPLETE_FIRST_TIME:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage
          id="ActivityFeed.firstTimeCompleted"
          values={{ displayName }}
        />
      ) : (
          <FormattedMessage
            id="ActivityFeed.ownFirstTimeCompleted"
          />
        );

    case TRANSITION_SUBSCRIPTION_CANCELLED_BY_PROVIDER:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.subscriptionCanceledByProviderToProvider"
          values={{
            reviewLink: getLeaveReviewLink(),
            displayName,
            expirationDate: getExpirationDate()
          }} />
      ) : (
          <FormattedMessage id="ActivityFeed.subscriptionCanceledByProviderToCustomer"
            values={{
              reviewLink: getLeaveReviewLink(),
              expirationDate: getExpirationDate()
            }} />
        );

    case TRANSITION_SUBSCRIPTION_CANCELLED_BY_CUSTOMER:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.subscriptionCancelledByCustomerToProvider"
          values={{
            reviewLink: getLeaveReviewLink(),
            displayName,
            expirationDate: getExpirationDate()
          }} />
      ) : (
          <FormattedMessage id="ActivityFeed.subscriptionCancelledByCustomerToCustomer"
            values={{
              reviewLink: getLeaveReviewLink(),
              displayName,
              expirationDate: getExpirationDate()
            }} />
        );

        case TRANSITION_SUBSCRIPTION_LATE_CANCELLED_BY_CUSTOMER:
          return txRoleIsProvider(ownRole) ? (
            <FormattedMessage id="ActivityFeed.subscriptionCancelledByCustomerToProvider"
              values={{
                reviewLink: getLeaveReviewLink(),
                displayName,
                expirationDate: getExpirationDate()
              }} />
          ) : (
              <FormattedMessage id="ActivityFeed.subscriptionCancelledByCustomerToCustomer"
                values={{
                  reviewLink: getLeaveReviewLink(),
                  displayName,
                  expirationDate: getExpirationDate()
                }} />
            );

    case TRANSITION_COMPLETE_BOOKING_PERIOD:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionCompleteBookingPeriod"
          values={{ reviewLink: getLeaveReviewLink() }}
        />
      );

    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
        const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
          </InlineTextButton>
        ) : null;
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        );
      }

    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(r => r.author.id.uuid === userId.uuid)[0];
};

const Transition = props => {
  const { transition, transaction, currentUser, subscription, intl, onOpenReviewModal } = props;

  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
      <UserDisplayName user={provider} intl={intl} />
    );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal,
    subscription
  );
  const currentTransition = transition.transition;

  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);
      reviewComponent = (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      );
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      );
    }
  }

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>{formatDate(intl, todayString, transition.createdAt)}</p>
        {reviewComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';
const isEmailMessage = item => item && item.type === 'emailMessage';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : isEmailMessage(item) ? new Date(item.attributes.createdAt) : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, emailMessages, hideOldTransitions) => {
  const items = messages.concat(transitions).concat(emailMessages).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    subscription,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);
  const currentSubscription = subscription ? subscription : null;
  const currentUserCustomer = currentUser && currentCustomer && currentUser.id.uuid === currentCustomer.id.uuid;
  
  const emailMessages = transaction.attributes.metadata.messages || [];

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(messages, transitions, emailMessages, hasOlderMessages || fetchMessagesInProgress);

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
          subscription={currentSubscription}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const emailMessageComponent = message => {
    const isOwnMessage =
      message.attributes.sender &&
      currentUser &&
      currentUser.attributes.email &&
      message.attributes.sender === currentUser.attributes.email;
    if (isOwnMessage) {
      return <OwnEmailMessage message={message} intl={intl} />;
    }
    return <EmailMessage message={message} currentUser={!currentUserCustomer ? currentCustomer : currentProvider} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  const emailMessageListItem = (message, index) => {
    return (
      <li id={`msg-${transaction.id.uuid + "-" + index}`} key={transaction.id.uuid + "-" + index} className={css.messageItem}>
        {emailMessageComponent(message)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (isRelevantPastTransition(transition.transition)) {
      return (
        <li key={transition.transition} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {items.map((item, index) => {
        if (isMessage(item)) {
          return messageListItem(item);
        } else if(isEmailMessage(item)) {
          return emailMessageListItem(item, index);
        }else {
          return transitionListItem(item);
        }
      })}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
