import { userLocation } from './maps';
import config from '../config';
import routeConfiguration from '../routeConfiguration';
import { createResourceLocatorString } from './routes';
import { types as sdkTypes } from './sdkLoader';

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

const locationBounds = (latlng, distance) => {
    if (!latlng) {
        return null;
    }

    const bounds = new window.mapboxgl.LngLat(latlng.lng, latlng.lat).toBounds(distance);
    return new SDKLatLngBounds(
        new SDKLatLng(bounds.getNorth(), bounds.getEast()),
        new SDKLatLng(bounds.getSouth(), bounds.getWest())
    );
};

export const getSearchPathUserLocation = async () => {
    const currentLocation = await userLocation().then(latlng => {
        return {
            address: '',
            origin: latlng,
            bounds: locationBounds(latlng, config.maps.search.currentLocationBoundsDistance),
        };
    });
    const { origin, bounds, address } = currentLocation;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
        ...originMaybe,
        address,
        bounds,
    };
    return createResourceLocatorString('SearchPage', routeConfiguration(), null, searchParams);
}