import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionChooseHowYouShare.css';

import priceIcon from './images/dollar.png';
import availabilityIcon from './images/calendar-1.png';
import bookingsIcon from './images/checked.png';
import optionsIcon from './images/app.png';


const SectionChooseHowYouShare = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <p className={css.preTitle}>
                    <FormattedMessage id="SectionChooseHowYouShare.preTitle" />
                </p>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionChooseHowYouShare.title" />
                </h2>
                <div className={css.content}>
                    <div className={css.card}>
                        <img className={css.icon} src={priceIcon} alt="Price Icon" />
                        <h3 className={css.cardTitle}>
                        <FormattedMessage id="SectionChooseHowYouShare.priceHeader" />
                        </h3>
                        <div className={css.cardBody}>
                            <FormattedMessage id="SectionChooseHowYouShare.priceDescription" />
                        </div>
                    </div>
                    <div className={css.card}>
                        <img className={css.icon} src={availabilityIcon} alt="Availability Icon" />
                        <h3 className={css.cardTitle}>
                            <FormattedMessage id="SectionChooseHowYouShare.availabilityHeader" />
                        </h3>
                        <div className={css.cardBody}>
                            <FormattedMessage id="SectionChooseHowYouShare.availabilityDescription" />
                        </div>
                    </div>
                    <div className={css.card}>
                        <img className={css.icon} src={bookingsIcon} alt="Bookings Icon" />
                        <h3 className={css.cardTitle}>
                        <FormattedMessage id="SectionChooseHowYouShare.bookingsHeader" />
                        </h3>
                        <div className={css.cardBody}>
                            <FormattedMessage id="SectionChooseHowYouShare.bookingsDescription" />
                        </div>
                    </div>
                    <div className={css.card}>
                        <img className={css.icon} src={optionsIcon} alt="Options Icon" />
                        <h3 className={css.cardTitle}>
                        <FormattedMessage id="SectionChooseHowYouShare.optionsHeader" />
                        </h3>
                        <div className={css.cardBody}>
                            <FormattedMessage id="SectionChooseHowYouShare.optionsDescription" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SectionChooseHowYouShare.defaultProps = { rootClassName: null, className: null };

SectionChooseHowYouShare.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionChooseHowYouShare;