import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink, ExternalLink } from '../../../components';

import css from './SectionInformation.css';

import first from './image/first-img.jpg';
import second from './image/second-img.jpg';
import third from './image/third-img.jpg';

const SectionInformation = props => {

    return (
        <div className={css.root}>
            <div className={css.first}>
                <div className={css.headerContent}>
                    <h1 className={css.title}>
                        <FormattedMessage id="SectionInformation.firstTitle" />
                    </h1>
                    <p className={css.subtitle}>
                        <FormattedMessage id="SectionInformation.firstDescription" values={{
                            link: <ExternalLink href="https://www.mindbodyonline.com/business/education/blog/heres-how-covid-19-has-changed-fitness">
                                <FormattedMessage id="SectionInformation.firstLink" />
                            </ExternalLink>
                        }}/>
                    </p>
                    <div className={css.buttonsWrapper}>
                        <p className={css.listTitle}>
                            <FormattedMessage id="SectionInformation.firstListTitle" />
                        </p>
                        <p className={css.list}>
                            <FormattedMessage id="SectionInformation.firstListItem1" />
                        </p>
                        <p className={css.list}>
                            <FormattedMessage id="SectionInformation.firstListItem2" />
                        </p>
                        <p className={css.list}>
                            <FormattedMessage id="SectionInformation.firstListItem3" />
                        </p>
                    </div>
                </div>
                <div className={css.headerImage}>
                    <img className={css.image} src={first} alt="Gofynder" />
                </div>
            </div>
            <div className={css.second}>
                <div className={css.secondContent}>
                    <h1 className={css.title}>
                        <FormattedMessage id="SectionInformation.secondTitle" />
                    </h1>
                    <p className={css.subtitle}>
                        <FormattedMessage id="SectionInformation.secondDescription" />
                    </p>
                    <p className={css.subtitle}>
                        <FormattedMessage id="SectionInformation.secondDescriptionTwo" />
                    </p>
                    <NamedLink
                        name="SignupPage"
                        className={css.button}
                    >
                        <FormattedMessage id="SectionInformation.signup" />
                    </NamedLink>
                </div>
                <div className={css.secondImage}>
                    <img className={css.image} src={second} alt="Gofynder" />
                </div>
            </div>
            <div className={css.third}>
                <div className={css.headerContent}>
                    <h1 className={css.title}>
                        <FormattedMessage id="SectionInformation.thirdTitle" />
                    </h1>
                    <p className={css.subtitle}>
                        <FormattedMessage id="SectionInformation.thirdDescription" />
                    </p>
                    <p className={css.subtitle}>
                        <FormattedMessage id="SectionInformation.thirdDescriptionTwo" />
                    </p>
                </div>
                <div className={css.headerImage}>
                    <img className={css.image} src={third} alt="Gofynder" />
                </div>
            </div>
        </div>
    )

}

export default SectionInformation;