import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionInstructionsMaybe = props => {
  const { publicData } = props;
  return publicData.notes ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.instructionsTitle" />
      </h2>
      <p className={css.description}>
        {publicData.notes}
      </p>
    </div>
  ) : null;
};

export default SectionInstructionsMaybe;
