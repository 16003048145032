import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import { ExternalLink } from '../../../components';

import css from './SectionPartners.css';

/* import fitnessStudiosImage from './images/AFS_vertical_(1).png'; */
import insureFitnessImage from './images/Insure_logo_small.png';
import acceleratorGraduate from './images/accelerator_badge.png';

const partner = (name, image, description, id, href, title) => {
    return (
        <div className={css.partnerWrapper}>
            <div className={css.partnerInfo}>
                <h2 className={css.partnerName}>{name}</h2>
                <p className={css.partnerDescription}>{description}</p>
            </div>
            <ExternalLink
                key={id}
                href={href}
                title={title}
                className={css.imageWrapper}
            >
                <img src={image} alt={title} className={css.partnerImage} />
            </ExternalLink>
        </div>
    );
};

const reference = ({ referenceTitle, referenceDescription }) => {
    return (
        <div className={css.reference}>
            <span className={css.referenceTitle}>{referenceTitle}</span>
            <span className={css.referenceLocation}> {referenceDescription} </span>
        </div>
    )
};

const SectionPartners = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <span role="img" aria-label="Large gym space available for rental in Dallas, Texas"></span>
                {reference({
                    referenceTitle: "1940's Industrial Fitness Warehouse",
                    referenceDescription: "Dallas, TX"
                })}
                <div className={css.content}>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionPartners.title" />
                    </h2>
                    <div className={css.grid}>
                        {partner(
                            'San Diego Sports Innovators',
                            acceleratorGraduate,
                            `Gofynder  to annnounce that we have 
                            graduated from the SDSI Accelerator Program and have become a member
                            of the SDSI community.`,
                            'linktoSanDiegoSportsInnovators',
                            'http://www.sdsportinnovators.org/',
                            'San Diego Sports Innovators'
                        )}
                        {partner(
                            'Insure Fitness Group',
                            insureFitnessImage,
                            `Gofynder is proud to have partnered with the Insure
                            Fitness Group to provide discounted insurance rates to
                            our community. Click on their logo to see the details.`,
                            'linkToInsureFitness',
                            'https://insurefitness.com/fynder/?utm_source=gofynder&utm_medium=affiliate&utm_campaign=partnership_annoucment&utm_content=email',
                            'Go to Insure Fitness page'
                        )}
                    </div>
                </div>
                <div className={css.image} />
            </div>
        </div>
    );
};

SectionPartners.defaultProps = { rootClassName: null, className: null };

SectionPartners.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionPartners;