import { convertUnitToSubUnit, unitDivisor } from "./currency";
import { types as sdkTypes } from './sdkLoader';

import { currencyConfiguration } from "../currency-config";

const currency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY;

const currencyConfig = currencyConfiguration(currency);

const { Money } = sdkTypes;

const getPrice = (unformattedValue) => {
    const isEmptyString = unformattedValue === '';
    try {
      return isEmptyString
        ? null
        : new Money(
            convertUnitToSubUnit(unformattedValue, unitDivisor(currencyConfig.currency)),
            currencyConfig.currency
          );
    } catch (e) {
      return null;
    }
  };
export default getPrice;
