import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionAbout.css';

import image from './images/about-us-img.png';


const SectionAbout = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.grid}>
                <img className={css.image} src={image} alt="About Us" />
                <div className={css.info}>
                    <p className={css.preTitle}>
                        <FormattedMessage id="SectionAbout.preTitle" />
                    </p>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionAbout.title" />
                    </h2>
                    <p className={css.text}>
                        We built Gofynder for YOU - our fellow fitness professionals and entrepreneurs.
                    </p>
                    <p className={css.text}>
                        Whether you are a personal trainer, yoga teacher or a dance instructor we all
                        have things in common. We are passionate about our work, our lives are spent on the move, and we are always looking to grow our businesses.
                    </p>
                    <p className={css.text}>
                        Gofynder was created to serve the unique needs of fitness professionals and your success
                        as a fitness entrepenuer is at the heart of what we do.
                    </p>
                    <NamedLink
                        name="SignupPage"
                        className={css.startHosting}>
                        <FormattedMessage id="SectionAbout.join" />
                    </NamedLink>
                </div>
            </div>
        </div>
    );
};

SectionAbout.defaultProps = { rootClassName: null, className: null };

SectionAbout.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionAbout;