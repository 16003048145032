import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionTags.css';

import yogaImage from './images/Yoga.png';
import danceImage from './images/Dance.png';
import groupFitnessImage from './images/Gym.png';
import martialArtsImage from './images/MartialArts.png';

const activityTagLink = (name, description, image, searchQuery, alt) => {
    return (
        <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.activityTag}>
            <div className={css.imageWrapper}>
                <img src={image} alt={alt} className={css.activityTagImage} />
                <div className={css.activityText}>
                    <h2 className={css.activityTagName}>{name}</h2>
                    <p className={css.activityTagDescription}>{description}</p>
                </div>
            </div>
        </NamedLink>
    );
};

const SectionTags = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <p className={css.preTitle}>Explore</p>
            <h2 className={css.title}>
                <FormattedMessage id="SectionTags.findSpaces" />
            </h2>
            <div className={css.grid}>
                {activityTagLink(
                    'Gyms',
                    'Great for PT, crossfit, & more',
                    groupFitnessImage,
                    '?pub_activities=fitness',
                    'Gym space for rent'
                )}
                {activityTagLink(
                    'Yoga Studios',
                    'Grow your following',
                    yogaImage,
                    '?pub_activities=yoga',
                    'Yoga space for rent'
                )}
                {activityTagLink(
                    'Martial Arts Spaces',
                    'Train your group',
                    martialArtsImage,
                    '?pub_activities=martialarts',
                    'Dance space for rent'
                )}
                {activityTagLink(
                    'Dance Studios',
                    'Teach classes big or small',
                    danceImage,
                    '?pub_activities=dance',
                    'Martial Arts space for rent'
                )}
            </div>
        </div>
    );
};

SectionTags.defaultProps = { rootClassName: null, className: null };

SectionTags.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionTags;