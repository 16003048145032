import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionOurStory.css';

const SectionOurStory = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);



    return (
        <div className={classes}>
            <div className={css.container}>
                <p className={css.preTitle}>
                    <strong>Our Story</strong>
                </p>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionOurStory.title" />
                </h2>
                <div className={css.content}>
                    
                    <p>
                        Our co-founder, Terren, has worked for over 13 years as a professional yoga teacher.
                        Her husband Bob and fellow co-founder has been there every step
                        of the way. From just starting out and knowing very little about the business to evolving
                        into a seasoned pro that brought great value to her clients.
                    </p>
                    
                    <p>
                        Along the way it became more and more clear that something was missing. There was no way
                        for fitness pros to teach clients without opening their own studios and there are thousands
                        of existing studios looking to make more money.
                    </p>
                    <p>
                        That is why we created Gofynder. To empower the fitness professionals of the world by 
                        providing a safe and powerful way for them to connect, grow, and succeed.
                    </p>
                </div>
            </div>
        </div>
    );
};

SectionOurStory.defaultProps = { rootClassName: null, className: null };

SectionOurStory.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionOurStory;