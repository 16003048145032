import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.css';

import image from '../../assets/heroImage.png';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <p className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </p>
        <div className={css.buttonsWrapper}>
          <NamedLink
            name="HowItWorksPage"
            className={css.getStarted}
          >
            <FormattedMessage id="SectionHero.getStarted" />
          </NamedLink>
          <NamedLink
            name="SearchPage"
            to={{
              search: '?address=Long%20Beach%2C%20California%2C%20United%20States&bounds=34.59017509%2C-117.322542%2C33.00273444%2C-119.015406&mapSearch=true',
            }}
            className={css.listYourSpace}
          >
            <FormattedMessage id="SectionHero.exploreSpaces" />
          </NamedLink>
        </div>
        <NamedLink className={css.button} name="PersonalTrainingPage">
          <FormattedMessage id="SectionHero.videoLink" />
        </NamedLink>
      </div>
      <div className={css.imageContainer}>
        <img src={image} alt={"Personal trainer greets clients for training session"} className={css.heroImage} />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
