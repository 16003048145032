import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { calculateQuantityFromHours, timestampToDate } from '../../util/dates';
import { propTypes, BOOKING_OPTION_CLASS, BOOKING_OPTION_HOUR } from '../../util/types';
import config from '../../config';
import { Form, Button } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import { types as sdkTypes } from '../../util/sdkLoader';
// import { Popup } from 'semantic-ui-react';

import css from './BookingTimeForm.css';

const { Money } = sdkTypes;

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  render() {
    const { rootClassName, className, unitPrice, listing, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const bookedDates = listing && listing.attributes.publicData.bookedDates
      ? listing.attributes.publicData.bookedDates : [];

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            // isOwnListing,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            bookingOption,
            userMissingRequiredFields
          } = fieldRenderProps;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          const { publicData } = listing.attributes;
          const isClassOption = bookingOption.key === BOOKING_OPTION_CLASS;
          const classBookings = publicData && publicData.timeSlotsAvailableForClassBookings ?
            publicData.timeSlotsAvailableForClassBookings : null;

          const isPrivate = publicData && publicData.spaceType ?
            publicData.spaceType === 'private' : false;
          const minimumLength = bookingOption && bookingOption.length ? parseFloat(bookingOption.length) : 1; // default 1

          const totalBookedHours = calculateQuantityFromHours(startDate, endDate);
          const bookHoursLessThanMinimumHours = totalBookedHours ? (totalBookedHours < minimumLength) : false;

          const errorMinimumLengthBooked = bookHoursLessThanMinimumHours ? (
            <FormattedMessage id="BookingTimeForm.minimumLengthError" values={{ count: minimumLength }} />
          ) : null

          const bookingStartLabelByTheClass = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitleByTheClass',
          });
          const bookingStartLabelByHour = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitleByTheHour',
          });

          const bookingEndLabel = intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' });

          const buttonDisabled = !startDate || !endDate || bookHoursLessThanMinimumHours || userMissingRequiredFields;

          const unitPriceCustomerCommissionAmount = isClassOption ?
            (unitPrice.amount * config.custom.BOOKING_OPTION_CLASS_COMMISSION) :
            (unitPrice.amount * config.custom.BOOKING_OPTION_HOUR_COMMISSION);

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                unitType,
                unitPrice,
                startDate,
                endDate,
                unitPriceCustomerCommission: new Money(unitPriceCustomerCommissionAmount, config.currency),

                // Calculate the quantity as hours between the booking start and booking end
                quantity: calculateQuantityFromHours(startDate, endDate),
                timeZone,
                bookingPlan: isClassOption ? BOOKING_OPTION_CLASS : BOOKING_OPTION_HOUR
              }
              : null;

          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              <EstimatedBreakdownMaybe bookingData={bookingData} />
            </div>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: isClassOption ? bookingStartLabelByTheClass : bookingStartLabelByHour,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form onSubmit={handleSubmit} className={classes}>

              <div className={css.bookingDatesWrapper}>
                {monthlyTimeSlots && timeZone ? (
                  <FieldDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    listingId={listing.id}
                    // bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    bookedDates={bookedDates}
                    isPrivate={isPrivate}
                    isClassOption={isClassOption}
                    classBookings={classBookings}
                  />
                  //     ))}
                  // </FieldArray>
                ) : null}
                {/* <Popup content="Details here" trigger={<i className="question circle outline icon"></i>} /> */}
              </div>

              {errorMinimumLengthBooked && (
                <p className={css.minimumLengthError}>
                  {errorMinimumLengthBooked}
                </p>
              )}

              {bookingInfo}

              <div className={submitButtonClasses}>
                <Button
                  className={css.reserveButton}
                  disabled={buttonDisabled}
                  type="submit">
                  <div className={css.firstLine}>
                    <FormattedMessage id="BookingTimeForm.requestToBook" />
                  </div>
                  <div className={css.secondLine}>
                    <FormattedMessage id='BookingTimeForm.youWontBeChargedInfo' />
                  </div>
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
