import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import classNames from 'classnames';
// import { SecondaryButton } from '../../components';

import css from './TransactionPanel.css';
import { timestampSecondsToMilliseconds } from '../../util/dates';

const SubscriptionPanelMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    // isCustomer,
    // transaction,
    stripeSubscription,
    // fetchStripeSubscriptionInProgress,
    fetchStripeSubscriptionError,
  } = props;

  const fetchStripeSubscriptionErrorMessage = fetchStripeSubscriptionError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.fetchStripeSubscriptionError" />
    </p>
  ) : null;

  const statusTranslation = status => {
    if (status === 'trialing' || status === 'active') return 'active';
    return status;
  }

  const subscriptionStatus =
    stripeSubscription &&
      stripeSubscription.status
      ? statusTranslation(stripeSubscription.status)
      : null;

  const nextBillingDate =
    stripeSubscription &&
      stripeSubscription.status !== 'canceled' &&
      !stripeSubscription.cancel_at_period_end &&
      stripeSubscription.current_period_end
      ? timestampSecondsToMilliseconds(stripeSubscription.current_period_end)
      : null;

  const willEnd =
    stripeSubscription &&
      stripeSubscription.status !== 'canceled' &&
      stripeSubscription.cancel_at
      ? timestampSecondsToMilliseconds(stripeSubscription.cancel_at)
      : null;

  const endedDate =
    stripeSubscription &&
      stripeSubscription.status === 'canceled' &&
      stripeSubscription.ended_at
      ? timestampSecondsToMilliseconds(stripeSubscription.ended_at)
      : null;

  const lastInvoiceStatus =
    stripeSubscription &&
      stripeSubscription.latest_invoice
      ? stripeSubscription.latest_invoice.status
      : null;

  /**
   * This is an option to enable the booked dates again, e.g: class bookings 
   * need to enable the time slots again
   * We are still testing this
   */
  // const showCloseBookingButton =
  //   stripeSubscription &&
  //   !isCustomer &&
  //   statusTranslation(subscriptionStatus) === 'canceled' &&
  //   (
  //     txIsInFirstTimeCompleted(transaction) ||
  //     txIsAccepted(transaction)
  //   );

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons && stripeSubscription ? (
    <div className={classes}>

      <div className={css.subscriptionInfoWrapper}>
        <div className={css.subLineItem}>
          <span className={css.subLineItemLabel}><FormattedMessage id="TransactionPanel.subscriptionStatusLabel" /></span>
          <span className={css.subLineItemValue}>{subscriptionStatus}</span>
        </div>

        {nextBillingDate ? (
          <div className={css.subLineItem}>
            <span className={css.subLineItemLabel}><FormattedMessage id="TransactionPanel.subscriptionNextBillingDateLabel" /></span>
            <span className={css.subLineItemValue}><FormattedDate value={nextBillingDate} month="short" day="numeric" year="numeric" /></span>
          </div>
        ) : null}

        {willEnd ? (
          <div className={css.subLineItem}>
            <span className={css.subLineItemLabel}><FormattedMessage id="TransactionPanel.subscriptionEndDateLabel" /></span>
            <span className={css.subLineItemValue}><FormattedDate value={willEnd} month="short" day="numeric" year="numeric" /></span>
          </div>
        ) : null}

        {endedDate ? (
          <div className={css.subLineItem}>
            <span className={css.subLineItemLabel}><FormattedMessage id="TransactionPanel.subscriptionEndedDateLabel" /></span>
            <span className={css.subLineItemValue}><FormattedDate value={endedDate} month="short" day="numeric" year="numeric" /></span>
          </div>
        ) : null}

        <div className={css.subLineItem}>
          <span className={css.subLineItemLabel}><FormattedMessage id="TransactionPanel.subscriptionLastInvoiceStatusLabel" /></span>
          <span className={css.subLineItemValue}>{lastInvoiceStatus}</span>
        </div>

      </div>

      {/* {showCloseBookingButton ? (
        <div className={css.actionButtonWrapper}>
          <SecondaryButton
            // inProgress={submitInProgress}
            // disabled={buttonsDisabled}
            // onClick={handleCancelBooking}
          >
            <FormattedMessage id="TransactionPanel.closeBooking" />
          </SecondaryButton>
        </div>
      ) : null} */}

      {/* {cancelSubscriptionErrorMessage} */}
    </div>
  ) : (
      <div className={classes}>
        {fetchStripeSubscriptionErrorMessage}
      </div>
    );
};

export default SubscriptionPanelMaybe;
