import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import {
    EditListingIntroductionForm
} from '../../forms';

import css from './EditListingIntroductionPanel.css';

const EditListingIntroductionPanel = props => {
    const {
        className,
        rootClassName,
        listing,
        disabled,
        ready,
        panelUpdated,
        onSubmit,
        submitButtonText,
        updateInProgress,
        errors,
        userMissingRequiredFields,
        location
    } = props;


    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const isCreated = currentListing.id && currentListing.attributes.state !== null ? true : false;

    const panelTitle = <FormattedMessage id="EditListingIntroductionPanel.title" />

    return (
        <div className={classes}>
            <h1 className={css.title}>{panelTitle}</h1>
            <p className={css.introduction}>
                <FormattedMessage id="EditListingIntroductionPanel.introduction" />
            </p>
            
            <EditListingIntroductionForm
                saveActionMsg={submitButtonText}
                onSubmit={() => onSubmit({})} // we don't send anything, first tab
                disabled={disabled}
                ready={ready}
                updated={panelUpdated}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
                isCreated={isCreated}
                userMissingRequiredFields={userMissingRequiredFields}
                location={location}
            />
        </div>
    )
};

EditListingIntroductionPanel.defaultProps = {
    className: null,
    rootClassName: null,
    listing: null,
};

EditListingIntroductionPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    submitButtonText: string.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
};

export default EditListingIntroductionPanel;