import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { BOOKING_OPTION_HOUR, BOOKING_OPTION_CLASS, BOOKING_OPTION_DAY } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemBookingHeading = props => {
  const { bookingOption, useCheckout } = props;

  if (!useCheckout) return null;

  const isHourly = bookingOption === BOOKING_OPTION_HOUR;
  const isClass = bookingOption === BOOKING_OPTION_CLASS;
  const isDaily = bookingOption === BOOKING_OPTION_DAY;

  const translationKeyBookingOption = isHourly
    ? 'BookingBreakdown.byTheHourTitle'
    : isClass
      ? 'BookingBreakdown.byTheClassTitle'
      : isDaily
        ? 'BookingBreakdown.byTheDayTitle'
        : 'BookingBreakdown.byTheMonthTitle'; // month by default

  const translationKeyDescription = isHourly
    ? 'BookingBreakdown.byTheHourDescription'
    : isClass
      ? 'BookingBreakdown.byTheClassDescription'
      : isDaily
        ? 'BookingBreakdown.byTheDayDescription'
        : 'BookingBreakdown.byTheMonthDescription'; // month by default

  return (
    <div className={css.bookingOptionWrapper}>
      <div className={css.lineItem}>
        <span className={css.bookingOptionTitle}>
          <FormattedMessage id={translationKeyBookingOption} />
        </span>
      </div>
      <span className={css.bookingOptionDescription}>
        <FormattedMessage id={translationKeyDescription} values={{breakLine: <br/>}} />
      </span>
    </div>
  );
};

export default LineItemBookingHeading;
