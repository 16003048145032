import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import LogoImageDefault from './primary_logo_blue_png.png';
import LogoImageWhite from './primary_logo_white_png_transparent_arrow.png';
import LogoImageBlack from './primary_logo_black.png';
import css from './Logo.css';

const Logo = props => {
  const { className, format, useWhite, isDark, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  if (format === 'desktop' && isDark) {
    return <img className={className} src={LogoImageBlack} alt={config.siteTitle} {...rest} />;
  } else {
    return <img className={format === 'desktop' ? className : mobileClasses} src={!useWhite ? LogoImageDefault : LogoImageWhite} alt={config.siteTitle} {...rest} />;

  }
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
