import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../../components';

import css from './SectionOtherPopularCities.css';

const SectionOtherPopularCities = props => {
    const { intl, rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    const sendMessagePlaceholder = intl.formatMessage(
        { id: 'SectionOtherPopularCities.title' },
        { location: "Los Angeles" }
    );

    return (
        <div className={classes}>
            <div className={css.container}>
                <h2 className={css.title}>
                    {sendMessagePlaceholder}
                </h2>
                <div className={css.grid}>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Costa%20Mesa%2C%20California%2C%20United%20States&bounds=33.74031778%2C-117.80151118%2C33.58675185%2C-118.01577672&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Costa Mesa</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Northridge%2C%20California%2C%20United%20States&bounds=34.35097819%2C-118.41112152%2C34.16081997%2C-118.67829709&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Northridge</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Long%20Beach%2C%20California%2C%20United%20States&bounds=33.88333062%2C-118.07164575%2C33.70844339%2C-118.31603688&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Long Beach</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Burbank%2C%20California%2C%20United%20States&bounds=34.26110118%2C-118.21434586%2C34.10325643%2C-118.4359264&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Burbank</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Pasadena%2C%20California%2C%20United%20States&bounds=34.25245244%2C-117.981748%2C34.00266398%2C-118.3321716&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Pasadena</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Hollywood%2C%20Los%20Angeles%2C%20California%2090028%2C%20United%20States&bounds=34.15295506%2C-118.24459782%2C34.03157687%2C-118.41480582&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Hollywood</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Santa%20Monica%2C%20California%2C%20United%20States&bounds=34.07812255%2C-118.40453322%2C33.96782657%2C-118.55907429&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Santa Monica</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Thousand%20Oaks%2C%20California%2C%20United%20States&bounds=34.28170221%2C-118.76836806%2C34.11582889%2C-119.00126483&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Thousand Oaks</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Santa%20Clarita%2C%20California%2C%20United%20States&bounds=34.56333981%2C-118.31338206%2C34.26547958%2C-118.73267292&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Santa Clarita</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Anaheim%2C%20California%2C%20United%20States&bounds=34.07407705%2C-117.5001081%2C33.5807412%2C-118.18976372&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Anaheim</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Glendale%2C%20California%2C%20United%20States&bounds=34.30080538%2C-118.08432453%2C34.08507199%2C-118.40532842&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Glendale</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Pomona%2C%20California%2C%20United%20States&bounds=34.15655415%2C-117.62910607%2C33.96859021%2C-117.88687793&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Pomona</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Torrance%2C%20California%2C%20United%20States&bounds=33.88743189%2C-118.284915006%2C33.780445002%2C-118.378371648&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Torrance</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Norwalk%2C%20California%2C%20United%20States&bounds=33.96004813%2C-118.00413612%2C33.85371679%2C-118.14969089&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Norwalk</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=West%20Covina%2C%20California%2C%20United%20States&bounds=34.16312364%2C-117.80897962%2C33.98189009%2C-118.05755062&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>West Covina</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Irvine%2C%20California%2C%20United%20States&bounds=33.7804852%2C-117.69561568%2C33.59388799%2C-117.95039015&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Irvine</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Santa%20Ana%2C%20California%2C%20United%20States&bounds=33.82541838%2C-117.77397087%2C33.68963047%2C-117.9595237&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Santa Ana</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Downey%2C%20California%2C%20United%20States&bounds=34.00823817%2C-118.03300924%2C33.86716472%2C-118.22619169&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Downey</p>
                    </NamedLink>
                </div>
            </div>
        </div>
    );
};

SectionOtherPopularCities.defaultProps = { rootClassName: null, className: null };

SectionOtherPopularCities.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionOtherPopularCities;