import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  PageHeaderTitle
} from '../../components';
import { TopbarContainer } from '../../containers';
import SectionConnecting from './SectionConnecting/SectionConnecting';
import SectionBookingOptions from './SectionBookingOptions/SectionBookingOptions';
import SectionSpacesTypes from './SectionSpacesTypes/SectionSpacesTypes';
import SectionFaqs from './SectionFaqs/SectionFaqs';
import SectionGetStarted from './SectionGetStarted/SectionGetStarted';
import SectionTags from './SectionTags/SectionTags';

import css from './HowItWorksPage.css';
import classNames from 'classnames';

export const HowItWorksPageComponent = props => {
  const { intl, scrollingDisabled, isAuthenticated, history } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'HowItWorksPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'HowItWorksPage.schemaDescription' });

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      description={schemaDescription}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="HowItWorksPage"/>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.pageHeaderContainer}>
            <PageHeaderTitle
              title="Find a fitness space to host your clients"
              referenceDescription="Learn how to connect with great local yoga studios, gyms and more in three easy steps"
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <div className={css.circle}>1</div>
                <SectionConnecting isAuthenticated={isAuthenticated} history={history} isHIW={true}/>
              </div>
            </li>
            <li id="booking-options" className={css.section}>
              <div className={css.sectionContent}>
                <div className={css.circle}>2</div>
                <SectionBookingOptions />
              </div>
            </li>
            <li id="space-types" className={css.section}>
              <div className={css.sectionContent}>
                <div className={css.circle}>3</div>
                <SectionSpacesTypes history={history}/>
              </div>
            </li>
            <li id="faqs" className={classNames(css.section, css.greyBg)}>
              <div className={css.faqSection}>
                <SectionFaqs />
              </div>
            </li>
            <li id="become" className={css.section}>
              <div className={css.sectionTagsContainer}>
                <SectionTags />
              </div>
            </li>
            <li className={css.sectionGetStartedContainer}>
              <SectionGetStarted />
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

HowItWorksPageComponent.defaultProps = {
}

HowItWorksPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    isAuthenticated
  } = state.Auth;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated
  };
};

const HowItWorksPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(HowItWorksPageComponent);

// LandingPage.loadData = loadData;

export default HowItWorksPage;
