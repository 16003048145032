import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import { LINE_ITEM_DAY, DATE_TYPE_DATE, propTypes, BOOKING_OPTION_CLASS, BOOKING_OPTION_DAY, BOOKING_OPTION_MONTH } from '../../util/types';
import { dateFromAPIToLocalNoon, calculateQuantityFromHours, daysBetween } from '../../util/dates';

import css from './BookingBreakdown.css';

export const TimeSlotLine = ({ weekday, timeSlots, timeZoneMaybe }) => (
  <div className={css.lineTimeSlots}>
    <div className={css.timeSlotLabel}>
      <FormattedMessage id={`BookingBreakdownMultiple.weekday.${weekday}`} />
    </div>
    <div className={css.timeSlotsValue}>
      {timeSlots.map((t, index) => (
        <div className={css.timeLabel} key={index}>
          <FormattedDate value={moment(t.startTime, 'HH:mm')} hour="numeric" hour12 {...timeZoneMaybe} /> - <FormattedDate value={t.endTime === "23:55" ? moment("24:00", 'HH:mm') : moment(t.endTime, 'HH:mm')} hour="numeric" hour12 {...timeZoneMaybe} />
        </div>
      ))}
    </div>
  </div>
);

const BookingPeriodClassMaybe = ({ bookingOption, startDate, endDate, dateFormatOptions, timeZoneMaybe, intl }) => {
  if (bookingOption === BOOKING_OPTION_CLASS) {
    let nextDates = [startDate];
    for (let i = 1; i < 4; i++) {
      const lastDate = nextDates[nextDates.length - 1];
      nextDates = [...nextDates, moment(lastDate).add(1, 'weeks').toDate()]
    }

    const startTime = intl.formatDate(startDate, { hour12: true, hour: "numeric", minute: "numeric", ...timeZoneMaybe });
    const endTime = intl.formatDate(endDate, { hour12: true, hour: "numeric", minute: "numeric", ...timeZoneMaybe });

    return (
      <>
        {nextDates.map((n, index) => (
          <div className={css.lineItem} key={index}>
            <div className={css.dateLabel}>
              <FormattedDate value={n} {...dateFormatOptions} {...timeZoneMaybe} />
            </div>
            <div className={css.classTimeSlotLabel}>
              {startTime} - {endTime}
            </div>
          </div>
        ))}
      </>
    );
  }
  return null;
}

const BookingPeriodDayMaybe = ({ bookingOption, startDate, endDate, availableTimeSlots, dateFormatOptions, timeZoneMaybe }) => {
  if (bookingOption === BOOKING_OPTION_DAY) {
    const startWeekday = moment(startDate).format('ddd').toLocaleLowerCase();
    const daysCount = daysBetween(startDate, endDate);

    let extraDays = [];
    if (daysCount > 0) {
      let lastDate = startDate;
      for (let i = 0; i < daysCount; i++) {
        const currentDate = moment(lastDate).add(1, 'days');
        const currentWeekday = currentDate.format('ddd').toLocaleLowerCase();

        extraDays.push(
          <>
            <div className={css.lineItemDay}>
              <div className={css.dateLabel}>
                <FormattedDate value={currentDate} {...dateFormatOptions} {...timeZoneMaybe} />
              </div>
            </div>
            {/* print hours available in a day */}
            <div className={css.timeSlotsWrapper}>
              {/* we set the time zone to null because we are not using datetime, we fetch the time slot from the availability plan */}
              <TimeSlotLine weekday="hour" timeSlots={availableTimeSlots[currentWeekday]} timeZoneMaybe={null} />
            </div>
          </>
        )
        lastDate = currentDate;
      }
    }

    return (
      <>
        <div className={css.lineItemDay}>
          <div className={css.dateLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>
        {/* print hours available in a day */}
        <div className={css.timeSlotsWrapper}>
          {/* we set the time zone to null because we are not using datetime, we fetch the time slot from the availability plan */}
          <TimeSlotLine weekday="hour" timeSlots={availableTimeSlots[startWeekday]} timeZoneMaybe={null} />
        </div>
        {extraDays.map((e, index) => (<React.Fragment key={index}>{e}</React.Fragment>))}
      </>
    );
  }
  return null;
}

const BookingPeriodMonthMaybe = ({ bookingOption, startDate, availableTimeSlots, dateFormatOptions, timeZoneMaybe }) => {
  if (bookingOption === BOOKING_OPTION_MONTH) {
    return (
      <>
        <div className={css.lineItemMonth}>
          <div className={css.dateLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>
        <div className={css.timeSlotsWrapper}>
          <div className={css.spaceTimesDescription}>
            <FormattedMessage id="BookingBreakdownMultiple.spaceTimesDescription" />
          </div>
          {/* we set the time zone to null because we are not using datetime, we fetch the time slot from the availability plan */}
          {Object.keys(availableTimeSlots).map((weekday, index) => {
            return <TimeSlotLine key={index} weekday={weekday} timeSlots={availableTimeSlots[weekday]} timeZoneMaybe={null} />
          })}
        </div>
      </>
    );
  }
  return null;
}

const BookingPeriod = props => {
  const { startDate, endDate, bookingOption, timeZone, availableTimeSlots, intl } = props;
  // endDate, quantity, dateType, intl, we are not using this anymore
  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    // ...bookingOption === BOOKING_OPTION_MONTH ? { year: 'numeric' } : {}
  };

  return (
    <>
      <BookingPeriodClassMaybe
        bookingOption={bookingOption}
        startDate={startDate}
        endDate={endDate}
        dateFormatOptions={dateFormatOptions}
        timeZoneMaybe={timeZoneMaybe}
        intl={intl} />

      <BookingPeriodDayMaybe
        bookingOption={bookingOption}
        startDate={startDate}
        endDate={endDate}
        availableTimeSlots={availableTimeSlots} // this is an array
        dateFormatOptions={dateFormatOptions}
        timeZoneMaybe={timeZoneMaybe} />

      <BookingPeriodMonthMaybe
        bookingOption={bookingOption}
        startDate={startDate}
        availableTimeSlots={availableTimeSlots} // this is an object
        dateFormatOptions={dateFormatOptions}
        timeZoneMaybe={timeZoneMaybe} />
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, availableTimeSlots: availableTimeSlotsMaybe, ...rest } = props;

  const { start, end, displayStart, displayEnd } = booking.attributes;
  const { bookingOption } = rest;

  let quantity =
    bookingOption === BOOKING_OPTION_MONTH
      ? 12
      : dateType === DATE_TYPE_DATE ?
        daysBetween(start, end) :
        calculateQuantityFromHours(start, end);

  const isDaily = unitType === LINE_ITEM_DAY;
  const isMontly = bookingOption === BOOKING_OPTION_MONTH;
  const localStartDate = isDaily ? dateFromAPIToLocalNoon(displayStart || start) : (displayStart || start);
  const localEndDateRaw = isDaily ? dateFromAPIToLocalNoon(displayEnd || end) : (displayEnd || end);

  const endDay = isDaily && !isMontly ? moment(localEndDateRaw).subtract(1, 'days') : localEndDateRaw;

  // show available timeslots for daily and monthly bookings
  // const weekday = isDaily && !isMontly ? moment(localStartDate).format('ddd').toLocaleLowerCase() : null;
  // const availableTimeSlots = weekday && availableTimeSlotsMaybe
  //   ? availableTimeSlotsMaybe[weekday]
  //   : isMontly && availableTimeSlotsMaybe // we need all the available timeslots
  //     ? availableTimeSlotsMaybe
  //     : (weekday ? [] : {}); // defaults

  return (
    <BookingPeriod
      startDate={localStartDate}
      endDate={endDay}
      quantity={quantity}
      dateType={dateType}
      availableTimeSlots={availableTimeSlotsMaybe}
      {...rest} />
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
