import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, FieldRadioButton, Button, NamedLink } from '../../components';

import css from './EditListingIntroductionForm.css';
import { required } from '../../util/validators';

export const EditListingIntroductionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        isCreated,
        userMissingRequiredFields,
      } = formRenderProps;

      const getItMessage = intl.formatMessage({
        id: 'EditListingIntroductionForm.getIt',
      });
      const getItRequiredMessage = intl.formatMessage({
        id: 'EditListingIntroductionForm.getItRequired',
      });

      const { createListingDraftError, showListingsError } = fetchErrors || {};

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingIntroductionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const profileLink = (
        <NamedLink
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="EditListingIntroductionForm.erroruserRequiredFieldsLinkText" />
        </NamedLink>
      );

      const userMissingRequiredFieldsErrorMessage = userMissingRequiredFields && userMissingRequiredFields.length
        && userMissingRequiredFields.length > 0 ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingIntroductionForm.userMissingRequiredFields" values={{ updateProfileLink: profileLink }} />
          </p>
        ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>

          {errorMessageCreateListingDraft}
          {errorMessageShowListing}
          {userMissingRequiredFieldsErrorMessage}

          <div className={css.body}>
          <h2 className={css.subtitle}>
              <FormattedMessage id="EditListingIntroductionPanel.subtitle1" />
            </h2>
            <p className={css.introduction}>
              <FormattedMessage id="EditListingIntroductionPanel.description1" />
            </p>
          </div>

          <div className={css.content}>
          <h2 className={css.subtitle}>
              <FormattedMessage id="EditListingIntroductionPanel.subtitle2" />
            </h2>
            <p className={css.introduction}>
              <FormattedMessage id="EditListingIntroductionPanel.description2" />
            </p>
            <p className={css.rules}>
              <FormattedMessage id="EditListingIntroductionPanel.rule1" />
            </p>
            <p className={css.rules}>
              <FormattedMessage id="EditListingIntroductionPanel.rule2" />
            </p>
            <p className={css.rules}>
              <FormattedMessage id="EditListingIntroductionPanel.rule3" />
            </p>
          </div>

          {!isCreated ? (
            <>
              <div className={css.fieldWrapper}>
                <FieldRadioButton
                  labelClassName={css.fieldLabel}
                  name="getIt"
                  id="getIt"
                  value="getIt"
                  label={getItMessage}
                  validate={required(getItRequiredMessage)} />
              </div>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </>
          ) : null}

        </Form>
      );
    }}
  />
);

EditListingIntroductionFormComponent.defaultProps = {
  fetchErrors: null
};

EditListingIntroductionFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
  }),
  isCreated: bool.isRequired
};

export default compose(injectIntl)(EditListingIntroductionFormComponent);