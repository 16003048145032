import React from 'react';
import { arrayOf, bool, func, shape, string, array } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, minMaxNumber, required, nonEmptyArray, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCheckboxGroup, DropdownInput } from '../../components';

import css from './EditListingDescriptionForm.css';
import config from '../../config';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        activities,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        tags
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const capacityMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.capacity',
      });
      const capacityPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.capacityPlaceholder',
      });
      const capacityRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.capacityRequired',
      });
      const capacityMinMaxMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.capacityMinMaxNumer',
      });
      const capacityMinMaxNumber = minMaxNumber(capacityMinMaxMessage, 0, 100);

      const activityMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.activity',
      });
      const activityPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.activityPlaceholder',
      });
      const activityRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.activityRequired',
      });
      const minActivityLengthMessage = nonEmptyArray(activityRequiredMessage)

      // TAGS
      const tagsMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.tags',
      });
      const tagsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.tagsPlaceholder',
      });
      const tagsHelpMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.tagsHelp',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.field}
            inputRootClass={css.input}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.field}
            inputRootClass={css.input}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <FieldTextInput
            id="capacity"
            name="capacity"
            className={css.field}
            inputRootClass={css.input}
            type="number"
            min="0"
            max="100"
            label={capacityMessage}
            placeholder={capacityPlaceholderMessage}
            validate={composeValidators(required(capacityRequiredMessage), capacityMinMaxNumber)}
          />

          <FieldCheckboxGroup
            className={css.field}
            textClassName={css.activitiesText}
            listClassName={css.listActivities}
            id="activities"
            name="activities"
            label={activityMessage}
            placeholder={activityPlaceholderMessage}
            options={activities}
            validate={composeValidators(required(activityRequiredMessage), minActivityLengthMessage)} />

          <DropdownInput
            id="tags"
            name="tags"
            rootClassName={css.field}
            className={css.input}
            label={tagsMessage}
            placeholder={tagsPlaceholderMessage}
            help={tagsHelpMessage}
            options={tags.map(tag => ({ key: tag.key, text: tag.label, value: tag.key }))}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null, tags: config.custom.tags };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  activities: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  tags: array,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
