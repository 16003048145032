import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import { LINE_ITEM_DAY, DATE_TYPE_DATE, propTypes, BOOKING_OPTION_CLASS, BOOKING_OPTION_DAY, BOOKING_OPTION_MONTH, DATE_TYPE_DATETIME } from '../../util/types';
import { dateFromAPIToLocalNoon, calculateQuantityFromHours, daysBetween } from '../../util/dates';

import css from './BookingBreakdown.css';

const BookingPeriod = props => {
  const { startDate, endDate, quantity, dateType, bookingOption, intl, timeZone } = props;

  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    ...bookingOption === BOOKING_OPTION_MONTH ? { year: 'numeric' } : {}
  };

  const timeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric'
  };

  const quantityTranslationKey =
    bookingOption === BOOKING_OPTION_MONTH
      ? 'BookingBreakdown.quantityMonth'
      : dateType === DATE_TYPE_DATE
        ? 'BookingBreakdown.quantityDay'
        : 'BookingBreakdown.quantity';

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const formattedStartDate =
    <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
  const formattedEndDate =
    <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
  const formattedStartTime =
    <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} />
  const formattedEndTime =
    <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} />
  const startDateWeekday = intl.formatDate(startDate, { weekday: 'long' });


  let dateLabel;
  if (bookingOption === BOOKING_OPTION_CLASS) {
    dateLabel = (
      <>
        {formattedStartDate} - <FormattedMessage id='BookingBreakdown.every' values={{ weekday: startDateWeekday }} />
      </>
    )
  }
  else if (bookingOption === BOOKING_OPTION_DAY) {
    if (quantity > 1) {
      dateLabel = (
        <>
          {formattedStartDate} - { formattedEndDate}
        </>
      )
    } else { dateLabel = formattedStartDate }
  }
  else if (bookingOption === BOOKING_OPTION_MONTH) {
    dateLabel = (
      <>
        {formattedStartDate} - { formattedEndDate} / <span className={css.payPerMonth}><FormattedMessage id="BookingBreakdown.billedPerMonth" /></span>
      </>
    )
  }
  else {
    dateLabel = formattedStartDate;
  }

  const timeLabel = dateType === DATE_TYPE_DATETIME
    ? (<div className={css.timeLabel}>{formattedStartTime} - {formattedEndTime}</div>)
    : null;

  return (
    <>
      <span className={css.itemLabel}>
        {dateLabel}
        {timeLabel}
      </span>
      <span className={css.itemValue}>
        <FormattedMessage id={quantityTranslationKey} values={{ quantity }} />
      </span>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, ...rest } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const { bookingOption } = rest;

  let quantity =
    bookingOption === BOOKING_OPTION_MONTH
      ? 12
      : dateType === DATE_TYPE_DATE ?
        daysBetween(start, end) :
        calculateQuantityFromHours(start, end);

  const isDaily = unitType === LINE_ITEM_DAY;
  const isMontly = bookingOption === BOOKING_OPTION_MONTH;
  const localStartDate = isDaily ? dateFromAPIToLocalNoon(displayStart || start) : (displayStart || start);
  const localEndDateRaw = isDaily ? dateFromAPIToLocalNoon(displayEnd || end) : (displayEnd || end);

  const endDay = isDaily && !isMontly ? moment(localEndDateRaw).subtract(1, 'days') : localEndDateRaw;

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod startDate={localStartDate} endDate={endDay} quantity={quantity} dateType={dateType} {...rest} />
      </div>
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;