import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
//import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  // IconSocialMediaFacebook,
  // IconSocialMediaInstagram,
  // IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.css';

const poweredByStripeImage = require('./images/powered_by_stripe@3x.png');
const instagramImage = require('./images/instagram.png');
const facebookImage = require('./images/facebook.png');
//const twitterImage = require('./images/twitter.png');
const youtubeImage = require('./images/youtube.png');

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, /*siteTwitterHandle,*/ siteYoutubePage } = config;
  //const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  //const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <img src={instagramImage} alt="Instagram" />
    </ExternalLink>
  ) : null;

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <img src={facebookImage} alt="Facebook" />
    </ExternalLink>
  ) : null;

  /*const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <img src={twitterImage} alt="Twitter" />
    </ExternalLink>
  ) : null;*/

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      className={css.icon}
      title={goToYoutube}
    >
      <img src={youtubeImage} alt="Youtube" />
    </ExternalLink>
  ) : null;

  return [instragramLink, fbLink/*, twitterLink*/, youtubeLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          {/* <div className={css.someLiksMobile}>{socialMediaLinks}</div> */}
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" useWhite={false} isDark={true} className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationTitle}>
                  <FormattedMessage id="Footer.organizationTitle" />
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <h3 className={css.infoLinkTitle}>
                <FormattedMessage id="Footer.company" />
              </h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="SignupPage" className={css.link}>
                    <FormattedMessage id="Footer.toSignupPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TermsOfServicePage" className={css.link}>
                    <FormattedMessage id="Footer.toTermsOfService" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" to={{ hash: '#contact' }} className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <h3 className={css.infoLinkTitle}>
                <FormattedMessage id="Footer.popularCities" />
              </h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink
                    name="PersonalTrainingPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchPersonalTraining" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="YogaPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchYoga" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="GroupFitnessPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchGroupFitness" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="CyclesPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchCycle" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="PilatesPage"
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.searchPilates" />
                  </NamedLink>
                </li>

              </ul>
            </div>
            <div className={css.explore}>
              <h3 className={css.infoLinkTitle}>
                <FormattedMessage id="Footer.explore" />
              </h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="HowItWorksPage" className={css.link}>
                    <FormattedMessage id="Footer.toHowItWorks" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="FAQPage" className={css.link}>
                    <FormattedMessage id="Footer.faqPageLink" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HowItWorksPage" to={{hash: '#booking-options'}} className={css.link}>
                    <FormattedMessage id="Footer.toBookingOptions" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HowItWorksPage" to={{hash: '#space-types'}} className={css.link}>
                    <FormattedMessage id="Footer.toSharedAndPrivate" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HowItWorksPage" to={{ hash: '#tags' }} className={css.link}>
                    <FormattedMessage id="Footer.toTags" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.host}>
              <h3 className={css.infoLinkTitle}>
                <FormattedMessage id="Footer.host" />
              </h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toListYourSpace" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HostPage" to={{hash: "#how-it-works"}} className={css.link}>
                    <FormattedMessage id="Footer.toHowHostingWorks" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink key="aboutPage" href="https://calendly.com/gofynder/gofynder-hello?back=1&month=2021-05" className={css.link} title="Get Help">
                    <FormattedMessage id="Footer.toGetHelp" />
                  </ExternalLink>
                </li>
              </ul>
            </div>
          </div>

          <div className={css.socialMediaLinks}>
            <ExternalLink
              key="linkToStripe"
              href="https://www.stripe.com"
              className={css.poweredByStripe}
              title="Go to Stripe page"
            >
              <img src={poweredByStripeImage} alt="Powered by Stripe" />
            </ExternalLink>
            <div className={css.socialMediaDesktop}>
              {socialMediaLinks}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
