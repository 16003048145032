import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './PageHeaderTitle.css';

const PageHeaderTitle = props => {
    const { rootClassName, className, title, referenceDescription, isAbout } = props;

    const classes = classNames(rootClassName || className === "aboutPage" ? css.aboutPage : css.root);
    const hasAbout = isAbout ? true : false;

    if(hasAbout){
        return (
            <div className={classes}>
                <h2 className={css.aboutTitle}>{title}</h2>
    
                <div className={css.reference}>
                    <span className={css.referenceTitle}>{referenceDescription}</span>
                </div>
            </div>
        );
    }else{
        return (
            <div className={classes}>
                <h1 className={css.title}>{title}</h1>
    
                <div className={css.reference}>
                    <span className={css.referenceTitle}>{referenceDescription}</span>
                </div>
            </div>
        );
    }

    
};

PageHeaderTitle.defaultProps = { rootClassName: null, className: null, title: null };

PageHeaderTitle.propTypes = {
    rootClassName: string,
    className: string,
    title: string,
};

export default PageHeaderTitle;