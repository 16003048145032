import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import {
    Page,
    UserNav,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
} from '../../components';
import { TopbarContainer } from '..';
import css from './VerifyCertificatePage.css';

import betaIcon from './images/beta-icon.png';

export class VerifyCertificatePage extends Component {

    render() {

        const {
            schemaTitle,
            scrollingDisabled,
        } = this.props;


        return (
<Page title={schemaTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="VerifyCertificatePage" />
            <UserNav selectedPageName="VerifyCertificatePage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <img className={css.image} src={betaIcon} alt="Beta Icon" />
              <h1 className={css.title}>Get Your Certification Verified by Gofynder</h1>
              <p>It will probably come as no surprise that hosts want to know about the individuals that are booking their spaces. So we are helping out by giving you the ability to get your professional certification verified by Gofynder.
              </p>
              <p>
                Gofynder members that have their certification verified will have a <strong>Verified Certificate Badge* </strong> added to their profile, giving hosts that added assurance that you are qualified to use their space(s).
              </p>
              <p>
                Best of all, getting the badge is totally free.
              </p>
              <p className={css.captcha}>
                *Verified Certificate Badge is currently only available for certifications from NASM, ACE & NCSF. Want your certifying organization added? Email us at hello@gofynder.com
              </p>
              <iframe title="Verify Certification Form" class="airtable-embed" src="https://airtable.com/embed/shrRMnNPd8CPgcMp9?backgroundColor=purple" frameborder="0" onmousewheel="" width="100%" height="1510" style={{background: "transparent", border: "1px solid #ccc"}}></iframe>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
        );
    }
}

VerifyCertificatePage.defaultProps = {
    currentUser: null,
    querySavedListingsError: null,
  };
  
  const { bool } = PropTypes;
  
  VerifyCertificatePage.propTypes = {
    scrollingDisabled: bool.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
  };

  const VerifyCertificate = compose(
    injectIntl
  )(VerifyCertificatePage);

  export default VerifyCertificate;
