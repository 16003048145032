import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../../components';

import css from './SectionOtherPopularCities.css';

const SectionOtherPopularCities = props => {
    const { intl, rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    const sendMessagePlaceholder = intl.formatMessage(
        { id: 'SectionOtherPopularCities.title' },
        { location: "San Diego" }
    );

    return (
        <div className={classes}>
            <div className={css.container}>
                <h2 className={css.title}>
                    {sendMessagePlaceholder}
                </h2>
                <div className={css.grid}>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Carlsbad%2C%20California%2C%20United%20States&bounds=33.17838079%2C-117.19605112%2C33.05596761%2C-117.36578003&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Carlsbad</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Escondido%2C%20California%2C%20United%20States&bounds=33.28396006%2C-116.88212557%2C33.00481829%2C-117.26928312&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Escondido</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Oceanside%2C%20California%2092057%2C%20United%20States&bounds=33.28825049%2C-117.19044633%2C33.10349433%2C-117.4468454&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Oceanside</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Chula%20Vista%2C%20California%2C%20United%20States&bounds=32.78269592%2C-116.80877337%2C32.48445958%2C-117.22003649&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Chula Vista</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Encinitas%2C%20California%2C%20United%20States&bounds=33.090600364%2C-117.18688775%2C33.011320004%2C-117.313614991&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Encinitas</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=San%20Marcos%2C%20California%2C%20United%20States&bounds=33.22003852%2C-117.06408052%2C33.02667267%2C-117.26868094&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>San Marcos</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=La%20Mesa%2C%20California%2C%20United%20States&bounds=32.8450373%2C-116.91177867%2C32.68755714%2C-117.12926413&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>La Mesa</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Poway%2C%20California%2C%20United%20States&bounds=33.13617166%2C-116.82031164%2C32.84765904%2C-117.21977346&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Poway</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Mira%20Mesa%2C%20San%20Diego%2C%20California%2092126%2C%20United%20States&bounds=33.01888154%2C-117.0037844%2C32.81988159%2C-117.2790846&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Mira Mesa</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Mission%20Bay%20Park%2C%20San%20Diego%2C%20California%2092109%2C%20United%20States&bounds=32.82879841%2C-117.16359442%2C32.73186078%2C-117.29748965&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Mission Bay</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=El%20Cajon%2C%20California%2C%20United%20States&bounds=32.88960562%2C-116.8369563%2C32.71348673%2C-117.08027892&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>El Cajon</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Santee%2C%20California%2C%20United%20States&bounds=32.94713475%2C-116.87344201%2C32.76011114%2C-117.13198197&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Santee</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Del%20Mar%2C%20California%2C%20United%20States&bounds=33.00056099%2C-117.19359155%2C32.930522%2C-117.2905353&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Del Mar</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Pacific%20Beach%2C%20San%20Diego%2C%20California%2092109%2C%20United%20States&bounds=32.84324659%2C-117.18495141%2C32.76638516%2C-117.29114559&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Pacific Beach</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Solana%20Beach%2C%20California%2C%20United%20States&bounds=33.0618983%2C-117.13086749%2C32.92980635%2C-117.37342549&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Solana Beach</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Vista%2C%20California%2C%20United%20States&bounds=33.26874774%2C-117.09393519%2C33.1308634%2C-117.34771783&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Vista</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Point%20Loma%2C%20San%20Diego%2C%20California%2092107%2C%20United%20States&bounds=32.76949679%2C-117.16288587%2C32.68807009%2C-117.3119586&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Point Loma</p>
                    </NamedLink>
                    <NamedLink
                        name="SearchPage"
                        to={{
                            search:
                                '?address=Clairemont%2C%20San%20Diego%2C%20California%2092117%2C%20United%20States&bounds=32.83971482%2C-117.14594806%2C32.80086008%2C-117.21715493&pub_activities=fitness',
                        }}
                        className={css.link}
                    >
                        <p className={css.blueText}>Clairemont</p>
                    </NamedLink>
                </div>
            </div>
        </div>
    );
};

SectionOtherPopularCities.defaultProps = { rootClassName: null, className: null };

SectionOtherPopularCities.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionOtherPopularCities;