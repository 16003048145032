import React, { Component } from "react";
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { StaticPage, TopbarContainer } from '..';
import css from './FAQPage.css';

export class FAQPage extends Component {

  render() {
    const { intl } = this.props;

    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'FAQPage.schemaTitle' }, { siteTitle });

    return (
      <StaticPage 
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="FAQPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <h1 className={css.title}>Frequently Asked Questions</h1>
              <iframe title="FAQ" className="airtable-embed" src="https://airtable.com/embed/shrK5OYqqJvZtegTr?backgroundColor=blueLight&layout=card" frameBorder="0" width="100%" height="800" style={{ background: "transparent", border: "1px solid #ccc" }}></iframe>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
  }
}

FAQPage.defaultProps = {
  currentUser: null,
  querySavedListingsError: null,
};


FAQPage.propTypes = {

  // from injectIntl
  intl: intlShape.isRequired,
};

const FAQ = compose(
  injectIntl
)(FAQPage);

export default FAQ;
