import React from 'react';
import { object, string } from 'prop-types';
import { Field } from 'react-final-form';
import {
    FieldCheckbox,
    FieldTextInput,
    FieldCurrencyInput
} from '../../components';
import config from '../../config';

import css from './FieldAvailabilityPlan.css';

const FieldAvailabilityPlanComponent = props => {

    const {
        rootClassName,
        checkBoxClassName,
        inputClassName,
        helpTextClassName,
        helpText,
        ratePlaceholder,
        rateHelpText,
        bookingLengthPlaceholder,
        bookingLengthHelpText,
        label,
        description,
        input: {
            name
        },
        values,
    } = props;

    const enable = values && values[name] && values[name].enable === true ? true : false;

    return (
        <div className={rootClassName || css.root}>
            <FieldCheckbox
                className={checkBoxClassName}
                id={`${name}.enable`}
                name={`${name}.enable`}
            />
            <h2>{label}</h2>
            <p>{description}</p>
            {helpText ? <span className={helpTextClassName}>{helpText}</span> : null}
            <div>
                <FieldCurrencyInput
                    name={`${name}.rate`}
                    className={inputClassName}
                    placeholder={ratePlaceholder}
                    currencyConfig={config.currencyConfig}
                    // {...validateRate}
                    required={enable}
                />
                {rateHelpText ? <span className={helpTextClassName}>{rateHelpText}</span> : null}
                {bookingLengthPlaceholder && (
                    <React.Fragment>
                        <FieldTextInput
                            type="text"
                            className={inputClassName}
                            name={`${name}.length`}
                            placeholder={bookingLengthPlaceholder}
                            required={enable && bookingLengthPlaceholder} />
                        {bookingLengthHelpText ? <span className={helpTextClassName}>{bookingLengthHelpText}</span> : null}
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

FieldAvailabilityPlanComponent.defaultProps = {
    rootClassName: null,
    checkBoxClassName: null,
    inputClassName: null,
    ratePlaceholder: null,
    rateHelpText: null,
    bookingLengthPlaceholder: null,
    bookingLengthHelpText: null,
    label: null,
    description: null,
    helpText: null,
    input: object.isRequired
};

FieldAvailabilityPlanComponent.propTypes = {
    rootClassName: string,
    checkBoxClassName: string,
    inputClassName: string,
    id: string.isRequired,
    ratePlaceholder: string.isRequired,
    rateHelpText: object,
    bookingLengthPlaceholder: string,
    bookingLengthHelpText: object,
    label: object.isRequired,
    description: object.isRequired,
    helpText: object,
    input: object.isRequired
};

const FieldAvailabilityPlan = props => {
    return <Field component={FieldAvailabilityPlanComponent} {...props} />
}

export default FieldAvailabilityPlan;