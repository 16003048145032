
export class FacebookPixelHandler {
  constructor(pixelId) {
    import('react-facebook-pixel')
      .then(module => module.default)
      .then(ReactPixel => {
        ReactPixel.init(pixelId)
        this.ReactPixel = ReactPixel
      })
  }

  //PageView de facebook pixel
  trackPageView() {
    if (this.ReactPixel) {
      return this.ReactPixel.pageView();
    }
  }
}
