import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldCheckbox } from '../../components';

import css from './ContactForm.css';

class ContactFormComponent extends Component {

    render() {
        return (
            <FinalForm
                {...this.props}
                render={fieldRenderProps => {
                    const {
                        rootClassName,
                        className,
                        formId,
                        handleSubmit,
                        onSuccess,
                        inProgress,
                        onError,
                        intl,
                        invalid,
                        values,
                    } = fieldRenderProps;

                    const { notarobot } = values;

                    // NAME
                    const namePlaceholder = intl.formatMessage({
                        id: 'ContactForm.namePlaceholder',
                    });
                    const nameRequiredMessage = intl.formatMessage({
                        id: 'ContactForm.nameRequired',
                    });
                    const nameRequired = validators.required(nameRequiredMessage);

                    // EMAIL
                    const emailPlaceholder = intl.formatMessage({
                        id: 'ContactForm.emailPlaceholder',
                    });
                    const emailRequiredMessage = intl.formatMessage({
                        id: 'ContactForm.emailRequired',
                    });
                    const emailRequired = validators.required(emailRequiredMessage);
                    const emailInvalidMessage = intl.formatMessage({
                        id: 'ContactForm.emailInvalid',
                    });
                    const emailValid = validators.emailFormatValid(emailInvalidMessage);

                    // MESSAGE
                    const messagePlaceholder = intl.formatMessage({
                        id: 'ContactForm.messagePlaceholder',
                    });
                    const messageRequiredMessage = intl.formatMessage({
                        id: 'ContactForm.messageRequired',
                    });
                    const messageRequired = validators.required(messageRequiredMessage);

                    // Not a robot
                    const notARobotLabel = intl.formatMessage({
                        id: 'ContactForm.notARobotLabel',
                    });

                    const onSubmitContactErrorMessage = onError
                        ? <p className={css.error}><FormattedMessage id="ContactForm.submitErrorMessage" /></p>
                        : null;

                    const onSubmitContactSuccessMessage = onSuccess
                        ? <p className={css.success}><FormattedMessage id="ContactForm.submitSuccessMessage" /></p>
                        : null;

                    const classes = classNames(rootClassName || css.root, className);
                    const submitDisabled =
                        invalid ||
                        inProgress ||
                        !notarobot;

                    return (
                        <Form
                            className={classes}
                            onSubmit={e => {
                                handleSubmit(e);
                            }}
                        >
                            <FieldTextInput
                                className={css.fieldName}
                                inputRootClass={css.rootField}
                                type="text"
                                name="name"
                                id={formId ? `${formId}.name` : 'name'}
                                placeholder={namePlaceholder}
                                validate={nameRequired}
                            />
                            <FieldTextInput
                                className={css.fieldEmail}
                                inputRootClass={css.rootField}
                                type="email"
                                name="email"
                                id={formId ? `${formId}.email` : 'email'}
                                placeholder={emailPlaceholder}
                                validate={validators.composeValidators(emailRequired, emailValid)}
                            />
                            <FieldTextInput
                                className={css.fieldMessage}
                                inputRootClass={css.rootField}
                                type="textarea"
                                name="message"
                                id={formId ? `${formId}.message` : 'message'}
                                placeholder={messagePlaceholder}
                                rows={10}
                                validate={messageRequired}
                            /> 

                            <FieldCheckbox
                                textClassName={css.notarobotText}
                                name="notarobot"
                                id={formId ? `${formId}.notarobot` : 'notarobot'}
                                label={notARobotLabel}
                            />

                            <PrimaryButton
                                className={css.button}
                                type="submit"
                                inProgress={inProgress}
                                // ready={pristineSinceLastSubmit}
                                disabled={submitDisabled}
                            >
                                <FormattedMessage id="ContactForm.sendMessage" />
                            </PrimaryButton>
                            {onSubmitContactErrorMessage}
                            {onSubmitContactSuccessMessage}
                        </Form>
                    );
                }}
            />
        );
    }
}

ContactFormComponent.defaultProps = {
    ContactFormComponent: null,
    className: null,
    formId: null,
    inProgress: false,
};

const { bool, string } = PropTypes;

ContactFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    formId: string,
    inProgress: bool,
    intl: intlShape.isRequired,
};

const ContactForm = compose(injectIntl)(ContactFormComponent);

ContactForm.displayName = 'ContactForm';

export default ContactForm;
