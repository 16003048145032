import React from 'react';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import { LINE_ITEM_CUSTOMER_COMMISSION, DATE_TYPE_DATE, BOOKING_OPTION_MONTH } from '../../util/types';
import { unitDivisor, convertMoneyToNumber, convertUnitToSubUnit } from '../../util/currency';
import { BookingBreakdownMultiple } from '../../components';
import { dateFromLocalToAPI } from '../../util/dates';
import moment from 'moment'

import css from './BookingMonthForm.css';

const { Money, UUID } = sdkTypes;

const estimatedTotalPrice = (unitPrice, unitCount, unitPriceCustomerCommission) => {
  const numericPrice = convertMoneyToNumber(unitPrice);
  const customerComissionPrice = unitPriceCustomerCommission ? convertMoneyToNumber(unitPriceCustomerCommission) : null;
  const numericTotalPrice = unitPriceCustomerCommission ?
    new Decimal(numericPrice)
      .plus(customerComissionPrice)
      .times(unitCount)
      .toNumber() : new Decimal(numericPrice).times(unitCount).toNumber()
  return new Money(
    convertUnitToSubUnit(numericTotalPrice, unitDivisor(unitPrice.currency)),
    unitPrice.currency
  );
};

// When we cannot speculatively initiate a transaction (i.e. logged
// out), we must estimate the booking breakdown. This function creates
// an estimated transaction object for that use case.
const estimatedTransaction = (unitType, bookingStart, bookingEnd, unitPrice, unitPriceCustomerCommission) => {
  const now = new Date();

  const totalUnitPrice = unitPrice;
  const totalPrice = estimatedTotalPrice(unitPrice, 1, unitPriceCustomerCommission);
  const totalCustomerCommission = unitPriceCustomerCommission;

  // bookingStart: "Fri Mar 30 2018 12:00:00 GMT-1100 (SST)" aka "Fri Mar 30 2018 23:00:00 GMT+0000 (UTC)"
  // Server normalizes night/day bookings to start from 00:00 UTC aka "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)"
  // The result is: local timestamp.subtract(12h).add(timezoneoffset) (in eg. -23 h)

  // local noon -> startOf('day') => 00:00 local => remove timezoneoffset => 00:00 API (UTC)
  const serverDayStart = dateFromLocalToAPI(
    moment(bookingStart)
      .startOf('day')
      .toDate()
  );
  const serverDayEnd = dateFromLocalToAPI(
    moment(bookingEnd)
      .startOf('day')
      .toDate()
  );


  return {
    id: new UUID('estimated-transaction'),
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: TRANSITION_REQUEST_PAYMENT,
      payinTotal: totalPrice,
      payoutTotal: totalPrice,
      lineItems: [
        {
          code: LINE_ITEM_CUSTOMER_COMMISSION,
          includeFor: ['customer'],
          unitPrice: unitPriceCustomerCommission,
          quantity: new Decimal(1),
          lineTotal: totalCustomerCommission,
          reversal: false,
        },
        {
          code: unitType,
          includeFor: ['customer', 'provider'],
          unitPrice: unitPrice,
          quantity: new Decimal(1),
          lineTotal: totalUnitPrice,
          reversal: false,
        },
      ],
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_CUSTOMER,
          transition: TRANSITION_REQUEST_PAYMENT,
        },
      ],
    },
    booking: {
      id: new UUID('estimated-booking'),
      type: 'booking',
      attributes: {
        start: serverDayStart,
        end: serverDayEnd,
      },
    },
  };
};


const EstimatedBreakdownMaybe = props => {
  const { unitType, unitPrice, startDate, endDate, unitPriceCustomerCommission, availableTimeSlots } = props.bookingData;

  const canEstimatePrice = startDate && endDate && unitPrice;
  if (!canEstimatePrice) {
    return null;
  }

  const tx = estimatedTransaction(unitType, startDate, endDate, unitPrice, unitPriceCustomerCommission);

  return (
    <BookingBreakdownMultiple
      className={css.receipt}
      userRole="customer"
      unitType={unitType}
      transactions={[tx]}
      bookings={[tx.booking]}
      dateType={DATE_TYPE_DATE}
      bookingOption={BOOKING_OPTION_MONTH}
      availableTimeSlots={availableTimeSlots}
    />
  );
};

export default EstimatedBreakdownMaybe;
