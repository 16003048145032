/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string, arrayOf } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  propTypes,
  BOOKING_OPTION_HOUR,
  BOOKING_OPTION_DAY,
  BOOKING_OPTION_CLASS,
  BOOKING_OPTION_MONTH
} from '../../util/types';

import LineItemBookingHeading from './LineItemBookingHeading';
import LineItemDatePickerDisplayMaybe from './LineItemDatePickerDisplayMaybe';
import LineItemBookingPeriod from './LineItemBookingPeriod';

import LiteItemDetailsByClassMaybe from "./LiteItemDetailsByClassMaybe";
import LiteItemDetailsByDayMaybe from './LiteItemDetailsByDayMaybe';
import LiteItemDetailsByMonthMaybe from './LiteItemDetailsByMonthMaybe';

import LineItemRefundMaybe from './LineItemRefundMaybe';

// import LineItemUnitPriceMaybe from './LineItemUnitPriceMaybe';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';

import css from './BookingBreakdown.css';

export const BookingBreakdownMultipleComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transactions,
    bookings,
    intl,
    dateType,
    timeZone,
    bookingOption,
    availableTimeSlots,
    useCheckout
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const classes = classNames(rootClassName || css.root, className);

  const bookingOptionTitleTranslationKey = bookingOption === BOOKING_OPTION_CLASS
    ? 'BookingBreakdownMultiple.classSlotDetailsTitle'
    : bookingOption === BOOKING_OPTION_DAY
      ? 'BookingBreakdownMultiple.dayDetailsTitle'
      : 'BookingBreakdownMultiple.monthDetailsTitle';

  const bookingOptionSubtitleTranslationKey = bookingOption === BOOKING_OPTION_CLASS
    ? 'BookingBreakdownMultiple.classSlotDetailsSubtitle'
    : bookingOption === BOOKING_OPTION_DAY
      ? 'BookingBreakdownMultiple.dayDetailsSubtitle'
      : 'BookingBreakdownMultiple.monthDetailsSubtitle';

  const bookingSummaryTitleTranslationKey = useCheckout
    ? 'BookingBreakdownMultiple.costSummaryTitle'
    : 'BookingBreakdownMultiple.bookingSummaryTitle'

  const periodDetailsClassname = classNames(css.periodDetails, {
    [css.periodDetailsCenter]: bookingOption === BOOKING_OPTION_CLASS
  });

  const subtitleClassname = classNames(css.bookingSummarySubtitle, {
    [css.monthDetailSubtitle]: bookingOption === BOOKING_OPTION_MONTH
  });

  return (
    <div className={classes}>

      <LineItemBookingHeading
        bookingOption={bookingOption}
        useCheckout={useCheckout} />

      {bookings.map((booking, index) => (
        <LineItemDatePickerDisplayMaybe
          key={index}
          bookingOption={bookingOption}
          booking={booking}
          timeZone={timeZone}
          useCheckout={useCheckout} />
      ))}

      {bookings.map((booking, index) => (
        <LiteItemDetailsByClassMaybe
          key={index}
          bookingOption={bookingOption}
          booking={booking}
          timeZone={timeZone}
          intl={intl}
          useCheckout={useCheckout} />
      ))}

      <LiteItemDetailsByDayMaybe
        bookingOption={bookingOption}
        bookings={bookings}
        availableTimeSlots={availableTimeSlots}
        timeZone={timeZone}
        intl={intl}
        useCheckout={useCheckout} />

      <LiteItemDetailsByMonthMaybe
        bookingOption={bookingOption}
        booking={bookings[0]}
        availableTimeSlots={availableTimeSlots}
        timeZone={timeZone}
        intl={intl}
        useCheckout={useCheckout} />

      {bookingOption === BOOKING_OPTION_HOUR ? null : !useCheckout ? (
        <div className={css.panelDetails}>
          <h3 className={css.bookingSummaryTitle}>
            <FormattedMessage id={bookingOptionTitleTranslationKey} />
          </h3>
          <div className={subtitleClassname}>
            <FormattedMessage id={bookingOptionSubtitleTranslationKey} />
          </div>
          <div className={periodDetailsClassname}>
            {bookings.map(booking => (
              <LineItemBookingPeriod
                key={booking.id.uuid}
                booking={booking}
                unitType={unitType}
                dateType={dateType}
                bookingOption={bookingOption}
                intl={intl}
                timeZone={timeZone}
                availableTimeSlots={availableTimeSlots}
              />
            ))}
          </div>
        </div>
      ) : null}

      <div className={classNames({
        [css.panelDetails]: !useCheckout
      })}>
        <h3 className={css.bookingSummaryTitle}>
          <FormattedMessage id={bookingSummaryTitleTranslationKey} />
        </h3>

        <LineItemBasePriceMaybe
          transactions={transactions}
          unitType={unitType}
          intl={intl}
          bookingOption={bookingOption}
        />
        <LineItemRefundMaybe transaction={transactions[0]} intl={intl} />

        <LineItemCustomerCommissionMaybe
          transactions={transactions}
          isCustomer={isCustomer}
          intl={intl}
        />
        <LineItemCustomerCommissionRefundMaybe
          transaction={transactions[0]}
          isCustomer={isCustomer}
          intl={intl}
        />

        <LineItemProviderCommissionMaybe
          transaction={transactions[0]}
          isProvider={isProvider}
          intl={intl}
        />
        <LineItemProviderCommissionRefundMaybe
          transaction={transactions[0]}
          isProvider={isProvider}
          intl={intl}
        />

        <LineItemTotalPrice transactions={transactions} isProvider={isProvider} intl={intl} />
      </div>

    </div>
  );
};

BookingBreakdownMultipleComponent.defaultProps = { rootClassName: null, className: null, dateType: null };

BookingBreakdownMultipleComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transactions: arrayOf(propTypes.transaction.isRequired),
  bookings: arrayOf(propTypes.booking.isRequired),
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdownMultiple = injectIntl(BookingBreakdownMultipleComponent);

BookingBreakdownMultiple.displayName = 'BookingBreakdownMultiple';

export default BookingBreakdownMultiple;
