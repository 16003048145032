import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './SectionHeader.css';

import image from './image/headerImage.png';

const SectionHeader = props => {

    return (
        <div className={css.root}>
            <div className={css.headerContent}>
                <h1 className={css.headerMainTitle}>
                    <FormattedMessage id="SectionHeaderGroupFitness.title" />
                </h1>
                <p className={css.headerSubTitle}>
                    <FormattedMessage id="SectionHeaderGroupFitness.subTitle" />
                </p>
                <div className={css.buttonsWrapper}>
                    <NamedLink
                        name="SignupPage"
                        className={css.signup}
                    >
                        <FormattedMessage id="SectionHeaderGroupFitness.signup" />
                    </NamedLink>
                </div>
            </div>
            <div className={css.headerImage}>
                <img className={css.image} src={image} alt="Gofynder" />
            </div>
        </div>
    )

}

export default SectionHeader;