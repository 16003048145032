import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionTestimonial.css';

const SectionTestimonial = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.testimonialText}>
          <div className={css.quoteLeft}>"</div>
          <div className={css.quoteRight}>"</div>
          <FormattedMessage id="SectionTestimonial.testimonial" />
        </div>
        <div className={css.person}>
          <FormattedMessage id="SectionTestimonial.personName" />
        </div>
      </div>
    </div>
  );
};

SectionTestimonial.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionTestimonial.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTestimonial;
