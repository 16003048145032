import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.css';

import losAngelesImage from './images/Los_Angeles_Image_-_rounded_corners.png';
import newYorkImage from './images/New_York_Image_-_rounded_corners.png';
import bayAreaImage from './images/Bay_Area_Image_-_rounded_corners.png';
import dcImage from './images/Washington_DC_Image_-_rounded_corners.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
        <div className={css.locationName}>
          {name}
        </div>
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'Los Angeles',
          losAngelesImage,
          '?address=Los%20Angeles%2C%20California&bounds=34.161440999758%2C-118.121305008073%2C33.9018913203336%2C-118.521456965901'
        )}
        {locationLink(
          'New York',
          newYorkImage,
          '?address=New%20York%20City%2C%20New%20York&bounds=40.9175764013%2C-73.7008392055224%2C40.477399%2C-74.2590879797556'
        )}
        {locationLink(
          'Bay Area',
          bayAreaImage,
          '?address=Bay%20Area%2C%20San%20Francisco&bounds=37.82616630554143%2C-122.45332577794537%2C37.79023369445857%2C-122.49880622205464'
        )}
        {locationLink(
          'Washington DC',
          dcImage,
          '?address=Washington%2C%20D.C&bounds=38.99555093%2C-76.909391%2C38.79155738%2C-77.1197609567342'
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
