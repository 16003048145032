import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';

import css from './FieldRadioButtonGroup.css';

const FieldRadioButtonGroupComponent = props => {
  const { rootClassName, className, wrapperClassName, label, placeholder, children } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {label ? <label className={css.label}>{label}</label> : null}
      {placeholder ? <p className={css.placeholder}>{placeholder}</p> : null}
      <div className={css.wrapper || wrapperClassName}>
        {children}
      </div>
    </div>
  );
};

FieldRadioButtonGroupComponent.defaultProps = {
  className: null,
  rootClassName: null,
  wrapperClassName: null,
  label: null,
  placeholder: null
};

FieldRadioButtonGroupComponent.propTypes = {
  className: string,
  rootClassName: string,
  wrapperClassName: string,
  label: node,
  placeholder: node,
  children: node
};

export default FieldRadioButtonGroupComponent;
