import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

const IMAGE_VARIANTS = {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',
  
      // Listing images:
      'variants.landscape-crop',
      'variants.landscape-crop2x',
    ],
  };

// ================ Action types ================ //

export const FIRST_FEATURED_LISTING_REQUEST = 'app/LosAngelesLandingPage/FIRST_LISTING_REQUEST';
export const FIRST_FEATURED_LISTING_SUCCESS = 'app/LosAngelesLandingPage/FIRST_LISTING_SUCCESS';
export const FIRST_FEATURED_LISTING_ERROR = 'app/LosAngelesLandingPage/FIRST_LISTING_ERROR';

export const SECOND_FEATURED_LISTING_REQUEST = 'app/LosAngelesLandingPage/SECOND_LISTING_REQUEST';
export const SECOND_FEATURED_LISTING_SUCCESS = 'app/LosAngelesLandingPage/SECOND_LISTING_SUCCESS';
export const SECOND_FEATURED_LISTING_ERROR = 'app/LosAngelesLandingPage/SECOND_LISTING_ERROR';

export const THIRD_FEATURED_LISTING_REQUEST = 'app/LosAngelesLandingPage/THIRD_LISTING_REQUEST';
export const THIRD_FEATURED_LISTING_SUCCESS = 'app/LosAngelesLandingPage/THIRD_LISTING_SUCCESS';
export const THIRD_FEATURED_LISTING_ERROR = 'app/LosAngelesLandingPage/THIRD_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
    featuredListings: [],
    featuredListingsInProgress: false,
    featuredListingsError: null,
};

const losAngelesLandingPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case FIRST_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case FIRST_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case FIRST_FEATURED_LISTING_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        case SECOND_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case SECOND_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case SECOND_FEATURED_LISTING_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        case THIRD_FEATURED_LISTING_REQUEST:
            return {
                ...state,
                featuredListingsInProgress: true,
                featuredListingsError: null,
            };
        case THIRD_FEATURED_LISTING_SUCCESS:
            return {
                ...state,
                featuredListings: state.featuredListings.find(l => l.id.uuid === payload.response.id.uuid) ? state.featuredListings : [...state.featuredListings, payload.response],
                featuredListingsInProgress: false,
            };
        case THIRD_FEATURED_LISTING_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return { ...state, featuredListingsInProgress: false, featuredListingsError: payload };
        default:
            return state;
    }
};

export default losAngelesLandingPageReducer;

// ================ Action creators ================ //

export const firstFeaturedListingRequest = () => ({
    type: FIRST_FEATURED_LISTING_REQUEST,
});

export const firstFeaturedListingSuccess = response => ({
    type: FIRST_FEATURED_LISTING_SUCCESS,
    payload: { response },
});

export const firstFeaturedListingError = e => ({
    type: FIRST_FEATURED_LISTING_ERROR,
    payload: e,
});

export const secondFeaturedListingRequest = () => ({
    type: SECOND_FEATURED_LISTING_REQUEST,
});

export const secondFeaturedListingSuccess = response => ({
    type: SECOND_FEATURED_LISTING_SUCCESS,
    payload: { response },
});

export const secondFeaturedListingError = e => ({
    type: SECOND_FEATURED_LISTING_ERROR,
    payload: e,
});

export const thirdFeaturedListingRequest = () => ({
    type: THIRD_FEATURED_LISTING_REQUEST,
});

export const thirdFeaturedListingSuccess = response => ({
    type: THIRD_FEATURED_LISTING_SUCCESS,
    payload: { response },
});

export const thirdFeaturedListingError = e => ({
    type: THIRD_FEATURED_LISTING_ERROR,
    payload: e,
});


export const firstFeaturedListing = (id) => (dispatch, getState, sdk) => {
    dispatch(firstFeaturedListingRequest());

    var listingId = new UUID(id);
    sdk.listings.show({
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
      }).then(response => {
        const listingRef = {
            id: response.data.data.id,
            type: response.data.data.type
          };
        dispatch(addMarketplaceEntities(response));
        dispatch(firstFeaturedListingSuccess(listingRef));
        return response;
      })
      .catch(e => dispatch(firstFeaturedListingError(storableError(e))));
};

export const secondFeaturedListing = (id) => (dispatch, getState, sdk) => {
    dispatch(secondFeaturedListingRequest());

    var listingId = new UUID(id);
    sdk.listings.show({
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
        }).then(response => {
            const listingRef = {
                id: response.data.data.id,
                type: response.data.data.type
              };
        dispatch(addMarketplaceEntities(response));
        dispatch(secondFeaturedListingSuccess(listingRef));
        return response;
        })
        .catch(e => dispatch(secondFeaturedListingError(storableError(e))));
};

export const thirdFeaturedListing = (id) => (dispatch, getState, sdk) => {
    dispatch(thirdFeaturedListingRequest());

    var listingId = new UUID(id);
    sdk.listings.show({
        id: listingId,
        include: ['author', 'author.profileImage', 'images'],
        ...IMAGE_VARIANTS,
        }).then(response => {
            const listingRef = {
                id: response.data.data.id,
                type: response.data.data.type
              };
        dispatch(addMarketplaceEntities(response));
        dispatch(thirdFeaturedListingSuccess(listingRef));
        return response;
        })
        .catch(e => dispatch(thirdFeaturedListingError(storableError(e))));
};


export const loadData = (ids) => dispatch => {
    console.log(ids);
    if(ids.length === 0) {
        return Promise.all([]);
    }
    if(ids.length === 1) {
        return Promise.all([
            dispatch(firstFeaturedListing(ids[0])), // get first featured listing
          ]);
    }
    if(ids.length === 2) {
        return Promise.all([
            dispatch(firstFeaturedListing(ids[0])), // get first featured listing
            dispatch(secondFeaturedListing(ids[1])), // get second featured listing
          ]);
    }
    if(ids.length === 3) {
        return Promise.all([
            dispatch(firstFeaturedListing(ids[0])), // get first featured listing
            dispatch(secondFeaturedListing(ids[1])), // get second featured listing
            dispatch(thirdFeaturedListing(ids[2])), // get third featured listing
          ]);
    }
};