import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';


// ================ Action types ================ //

export const FETCH_FEATURED_LISTINGS_REQUEST = 'app/LandingPage/FEATURED_LISTINGS_REQUEST';
export const FETCH_FEATURED_LISTINGS_SUCCESS = 'app/LandingPage/FEATURED_LISTINGS_SUCCESS';
export const FETCH_FEATURED_LISTINGS_ERROR = 'app/LandingPage/FEATURED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
    listingResultIds: [],
    fetchFeaturedListingsInProgress: false,
    fetchFeaturedListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const landingPageReduer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_FEATURED_LISTINGS_REQUEST:
            return {
                ...state,
                fetchFeaturedListingsInProgress: true,
                fetchFeaturedListingsError: null,
            };
        case FETCH_FEATURED_LISTINGS_SUCCESS:
            return {
                ...state,
                listingResultIds: resultIds(payload.data),
                fetchFeaturedListingsInProgress: false,
            };
        case FETCH_FEATURED_LISTINGS_ERROR:
            // eslint-disable-next-line no-console
            console.error(payload);
            return { ...state, fetchFeaturedListingsInProgress: false, fetchFeaturedListingsError: payload };
        default:
            return state;
    }
};

export default landingPageReduer;

// ================ Action creators ================ //

export const fetchFeaturedListingsRequest = () => ({
    type: FETCH_FEATURED_LISTINGS_REQUEST,
});

export const fetchFeaturedListingsSuccess = response => ({
    type: FETCH_FEATURED_LISTINGS_SUCCESS,
    payload: { data: response.data },
});

export const fetchFeaturedListingsError = e => ({
    type: FETCH_FEATURED_LISTINGS_ERROR,
    payload: e,
});


export const fetchFeaturedListings = () => (dispatch, getState, sdk) => {
    dispatch(fetchFeaturedListingsRequest());

    const params = {
        perPage: 4,
        include: ['author', 'images'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
    };

    return sdk.listings
        .query(params)
        .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(fetchFeaturedListingsSuccess(response));
            return response;
        })
        .catch(e => {
            dispatch(fetchFeaturedListingsError(storableError(e)));
            throw e;
        });
};

export const loadData = (params, search) => dispatch => {
    return Promise.resolve(dispatch(fetchFeaturedListings()));
};