import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  ListingCard,
  UserNav
} from '../../components';
import { TopbarContainer } from '../../containers';
import { loadData, updateSavedListingsAsFavorite } from './SavedPage.duck';
import config from '../../config';

import css from './SavedPage.css';

// const MAX_MOBILE_SCREEN_WIDTH = 768;

export class SavedPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const {
      scrollingDisabled,
      currentUser,
      // viewport,
      intl,
      savedListings,
      querySavedListingsInProgress,
      querySavedListingsError,
      onUpdateSavedListingsAsFavorite
    } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);

    const hasListings = savedListings.length > 0;
    // const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    // const savedListings = (profileUser.attributes.profile.publicData && profileUser.attributes.profile.publicData.savedListings) || [];

    const mainContent = (
      <div>
        {querySavedListingsInProgress ? (
          <p className={css.loading}>
            <FormattedMessage id="SavedPage.loadingData" />
          </p>
        ) : null}
        {hasListings ? (
          <div className={css.listingPanel}>
            <h1 className={css.desktopHeading}>
          <FormattedMessage id="SavedPage.titleHeading" />
        </h1>
            <div className={css.listingCards}>
              {savedListings.map(l => (
                <div className={css.listingCard} key={l.id.uuid}>
                  <ListingCard
                    currentUser={ensuredCurrentUser}
                    listing={l}
                    onUpdateSavedListings={onUpdateSavedListingsAsFavorite} />
                </div>
              ))}
            </div>
          </div>
        ) : (
            <div>
              {ensuredCurrentUser.id && !querySavedListingsInProgress ? (
                <p>
                  <FormattedMessage id="SavedPage.emptySavedListings" />
                </p>
              ) : null}
            </div>
          )}
      </div>
    );

    let content;

    if (querySavedListingsError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="SavedPage.loadingDataFailed" />
        </p>
      );
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage(
      {
        id: 'SavedPage.schemaTitle',
      },
      {
        siteTitle: config.siteTitle,
      }
    );

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'SavedPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="SavedPage" />
            <UserNav selectedPageName="SavedPage" />
          </LayoutWrapperTopbar>
          {/* <LayoutWrapperSideNav className={css.aside}>{asideContent}</LayoutWrapperSideNav> */}
          <LayoutWrapperMain>
            {content}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SavedPageComponent.defaultProps = {
  currentUser: null,
  querySavedListingsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

SavedPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  querySavedListingsError: propTypes.error,
  savedListings: arrayOf(propTypes.listing).isRequired,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    savedListingRefs,
    querySavedListingsInProgress,
    querySavedListingsError
  } = state.SavedPage;
  const savedListings = getMarketplaceEntities(state, savedListingRefs);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    savedListings,
    querySavedListingsInProgress,
    querySavedListingsError
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateSavedListingsAsFavorite: (savedListings) => dispatch(updateSavedListingsAsFavorite(savedListings))
})

const SavedPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(SavedPageComponent);

SavedPage.loadData = params => {
  // const id = new UUID(params.id);
  return loadData();
};

export default SavedPage;
