import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../../components';

import css from './SectionEarningPotential.css';
import { Popup } from 'semantic-ui-react';

const SectionEarningPotential = props => {

    return (
        <div className={css.root}>
            <div className={css.headerContent}>
                <h1 className={css.headerMainTitle}>
                    <FormattedMessage id="SectionEPGroupFitness.title" />
                </h1>
                <p className={css.headerSubTitle}>
                    <FormattedMessage id="SectionEPGroupFitness.subTitle" />
                </p>
                <div className={css.grid}>
                    <div className={css.descriptionColumn}>
                        <div className={css.itemHeader}>

                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPGroupFitness.descriptionItem1" />
                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPGroupFitness.descriptionItem2" />
                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPGroupFitness.descriptionItem3" />
                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPGroupFitness.descriptionItem4" />
                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPGroupFitness.descriptionItem5" />
                        </div>
                        <div className={css.itemLabel}>
                            <FormattedMessage id="SectionEPGroupFitness.descriptionItem6" />
                        </div>
                        <div className={css.lastItemLabel}>
                            <FormattedMessage id="SectionEPGroupFitness.descriptionLastItem" />
                        </div>
                    </div>
                    <div className={css.normalColumn}>
                        <div className={css.itemHeader}>
                            <div className={css.itemPretitle}>
                                <FormattedMessage id="SectionEPGroupFitness.normalPretitle" />
                            </div>
                            <div className={css.itemTitle}>
                                <FormattedMessage id="SectionEPGroupFitness.normalTitle" />
                            </div>
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPGroupFitness.normalItem1" />
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPGroupFitness.normalItem2" />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            style={{fontSize: 14}} 
                            content={<div><FormattedMessage id="SectionEPGroupFitness.normalItem3Popup" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPGroupFitness.normalItem3" /></div>} 
                        />
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPGroupFitness.normalItem4" />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            style={{fontSize: 14}} 
                            content={<div><FormattedMessage id="SectionEPGroupFitness.normalItem5Popup" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPGroupFitness.normalItem5" /></div>} 
                        />
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPGroupFitness.normalItem6" />
                        </div>
                        <div className={css.lastItem}>
                            <FormattedMessage id="SectionEPGroupFitness.normalLastItem" />
                        </div>
                    </div>
                    <div className={css.gofynderColumn}>
                        <div className={css.itemHeader}>
                            <div className={css.itemPretitle}>
                                <FormattedMessage id="SectionEPGroupFitness.gofynderPretitle" />
                            </div>
                            <div className={css.itemTitle}>
                                <FormattedMessage id="SectionEPGroupFitness.gofynderTitle" />
                            </div>
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPGroupFitness.gofynderItem1" />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                            <Popup 
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPGroupFitness.gofynderItem2Popup" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPGroupFitness.gofynderItem2" /></div>} 
                            position="top right"
                        />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            className={css.popup}
                            wide="very"
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPGroupFitness.gofynderItem3Popup" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPGroupFitness.gofynderItem3" /></div>}
                            position="top right"
                        />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            className={css.popup2}
                            wide="very"
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPGroupFitness.gofynderItem4Popup" /><br /><br /><FormattedMessage id="SectionEPGroupFitness.gofynderItem4Popup1" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPGroupFitness.gofynderItem4" /></div>} 
                        />
                        </div>
                        <div className={classNames(css.item, css.popups)}>
                        <Popup 
                            className={css.popup2}
                            wide="very"
                            content={<div className={css.popupContent}><FormattedMessage id="SectionEPGroupFitness.gofynderItem5Popup" /><br></br><FormattedMessage id="SectionEPGroupFitness.gofynderItem5Popup1" /></div>} 
                            trigger={<div><FormattedMessage id="SectionEPGroupFitness.gofynderItem5" /></div>} 
                        />
                        </div>
                        <div className={css.item}>
                            <FormattedMessage id="SectionEPGroupFitness.gofynderItem6" />
                        </div>
                        <div className={css.lastItem}>
                            <FormattedMessage id="SectionEPGroupFitness.gofynderLastItem" />
                        </div>
                    </div>
                </div>
                <div className={css.descriptionEarnings}>
                    <FormattedMessage id="SectionEPGroupFitness.descriptionEarnings" />
                </div>
                <NamedLink
                    name="SignupPage"
                    className={css.signup}
                >
                    <FormattedMessage id="SectionEPGroupFitness.signup" />
                </NamedLink>
            </div>
        </div>
    )

}

export default SectionEarningPotential;