import React, { Component } from "react";
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';
import css from './GetStartedPage.css';

export class GetStartedPage extends Component {

  componentDidMount(){
      if(typeof window.gtag === "function"){
        window.gtag('event', 'conversion', {'send_to': 'AW-755775726/WWjkCMmo0pcBEO7xsOgC'});
      }
  }

  render() {
    const {
      intl,
      scrollingDisabled,
    } = this.props;

    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'GetStartedPage.schemaTitle' }, { siteTitle });
    
    return (
      <Page 
        title={schemaTitle} 
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }} 
        scrollingDisabled={scrollingDisabled}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="GetStarted" />
            <UserNav selectedPageName="GetStarted" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <h1 className={css.title}>Get Started</h1>
              <p>Welcome to Gofynder! We are so excited that you have joined our community of fitness professionals. Gofynder is filled with pros of all varities but they are all driven people working hard to create successful businesses based on their passions.
              </p>
              <p>
                We have created this page to give you a reference guide to all things Gofynder. If you don't find the information you are looking for, please check out our <NamedLink name="FAQPage">FAQ Page</NamedLink>. Or you can also ask your question via our <NamedLink name="AboutPage" to={{ hash: '#contact' }}>Contact Us Form</NamedLink>.
              </p>
              <iframe title="Get Started" className="airtable-embed" src="https://airtable.com/embed/shrjUevipAmDRAvz1?backgroundColor=blue" frameBorder="0" width="100%" height="720" style={{ background: "transparent", border: "1px solid #ccc" }}></iframe>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const { bool } = PropTypes;

GetStartedPage.defaultProps = {
  currentUser: null,
  querySavedListingsError: null,
};

GetStartedPage.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {

  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const GetStarted = compose(
  connect(mapStateToProps),
  injectIntl
)(GetStartedPage);

export default GetStarted;
