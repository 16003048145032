import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink, ExternalLink } from '../../../components';

import css from './SectionHostingFaqs.css';

import image from './images/Shout.png';


const SectionHostingFaqs = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.content}>
                <div className={css.infoContainer}>
                    <div className={css.info}>
                        <h2 className={css.infoTitle}>
                            <FormattedMessage id="SectionHostingFaqs.infoTitle" />
                        </h2>
                        <p className={css.infoText}>
                            <FormattedMessage id="SectionHostingFaqs.infoText" />
                        </p>
                        <NamedLink className={css.inforButtons} name="FAQPage">FAQs</NamedLink>
                    </div>
                    <div className={css.info}>
                        <h2 className={css.infoTitle}>
                            <FormattedMessage id="SectionHostingFaqs.infoTitle1" />
                        </h2>
                        <p className={css.infoText}>
                            <FormattedMessage id="SectionHostingFaqs.infoText1" />
                        </p>
                        <ExternalLink 
                            className={css.inforButtons}
                            href={"https://calendly.com/gofynder/gofynder-hello"}
                        >
                            Schedule a call
                        </ExternalLink>
                    </div>
                </div>
                <div className={css.imageContainer}>
                    <img src={image} className={css.image} alt="Shout" />
                </div>
            </div>
        </div >
    );
};

SectionHostingFaqs.defaultProps = { rootClassName: null, className: null };

SectionHostingFaqs.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionHostingFaqs;