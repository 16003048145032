import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './SectionFaqs.css';


const SectionFaqs = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <h2 className={css.title}>
                <FormattedMessage id="SectionFaqs.title" />
            </h2>
            <p className={css.description}>
                <FormattedMessage id="SectionFaqs.description" />
            </p>
            <NamedLink name="FAQPage" className={css.link}>
                <FormattedMessage id="SectionFaqs.link" />
            </NamedLink>
        </div >
    );
};

SectionFaqs.defaultProps = { rootClassName: null, className: null };

SectionFaqs.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionFaqs;