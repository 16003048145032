import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './SectionWhyGofynder.css';

import firstImage from './images/weight.png';
import secondImage from './images/calendar-1.png';
import thirdImage from './images/shield.png';

const gridItem = (title, description, image) => {
    return (
        <div className={css.itemWrapper}>
            <div className={css.iconWrapper}>
                <img className={css.image} src={image} alt={title} />
                <h2 className={css.cellTitle}>{title}</h2>
                <p className={css.cellDescription}>{description}</p>
            </div>
        </div>
    )
}

const SectionWhyGofynder = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <h2 className={css.title}>
                    <FormattedMessage id="SectionWhyGofynder.title" />
                </h2>
                <div className={css.grid}>
                    {gridItem(
                        'By fit pros for fit pros',
                        `Gofynder was started by fitness professionals with the specific goal to empower other fitness pros to be succesful`,
                        firstImage
                    )}
                    {gridItem(
                        'Multiple Booking Options',
                        `We offer more booking options than anywhere else because we know that fitness pros have a wide variety space needs`,
                        secondImage
                    )}
                    {gridItem(
                        'Insurance Coverage',
                        `We include insurance coverage with every booking so hosts can feel confident when making their space available to local fitness pros`,
                        thirdImage
                    )}
                </div>
                <NamedLink name="SignupPage" className={css.link}>
                    <FormattedMessage id="SectionWhyGofynder.toSignupPage" />
                </NamedLink>
            </div>
        </div>
    );
};

SectionWhyGofynder.defaultProps = { rootClassName: null, className: null };

SectionWhyGofynder.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionWhyGofynder;