import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldCheckbox, FieldCurrencyInput, FieldSelect } from '../../components';

import css from './EditListingAvailabilityAndPricingForm.css';
import config from '../../config';

const rateRequired = value => {

    return value && (value.enable)
};
const lengthRequired = value => {

    return value && (value.enable)
};

export class EditListingAvailabilityAndPricingFormComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hourLengthFocus: false,
            classLengthFocus: false,
        }

        this.changeFocus = this.changeFocus.bind(this);
    }

    changeFocus(key, isFocus) {
        if (key === 'hour') this.setState({ ...this.state, hourLengthFocus: isFocus });
        if (key === 'class') this.setState({ ...this.state, classLengthFocus: isFocus });
    }

    render() {
        return (
            <FinalForm
                {...this.props}
                render={formRenderProps => {
                    const {
                        className,
                        rootClassName,
                        disabled,
                        ready,
                        handleSubmit,
                        intl,
                        invalid,
                        pristine,
                        saveActionMsg,
                        updated,
                        updateError,
                        updateInProgress,
                        values,
                        spaceType,
                        form,
                    } = formRenderProps;

                    const hasValue = Object.keys(values).length <= 0 || Object.keys(values).some(key => values[key].enable === true);

                    const minimunLengthRequired = Object.keys(values).length <= 0
                        || !!Object.keys(values).find(key => {
                            const value = values[key];
                            if (value.enable === true && !value.rate) {
                                return true
                            }
                            else if (key === 'hour' || key === 'class') {
                                if (value.enable === true && value.rate && !value.length) {
                                    return true
                                }
                                else if (value.enable === true && !value.rate && value.length) {
                                    return true
                                };
                            }
                            return false;
                        });

                    const monthlySubscriptionLabel = <FormattedMessage id="EditListingAvailabilityAndPricingForm.monthlySubscriptionLabel" />
                    const monthlySubscriptionRatePlaceholder = intl.formatMessage({ id: 'EditListingAvailabilityAndPricingForm.monthlySubscriptionRatePlaceholder' });
                    const monthlySubscriptionRateHelpText = <FormattedMessage id="EditListingAvailabilityAndPricingForm.monthlySubscriptionRateHelpText" />

                    const bookByTheHourLabel = <FormattedMessage id="EditListingAvailabilityAndPricingForm.bookByTheHourLabel" />
                    const bookByTheHourRatePlaceholder = intl.formatMessage({ id: 'EditListingAvailabilityAndPricingForm.bookByTheHourRatePlaceholder' });
                    const bookByTheHourRateHelpText = <FormattedMessage id="EditListingAvailabilityAndPricingForm.bookByTheHourRateHelpText" />
                    const bookByTheHourBookingLengthPlaceholder = intl.formatMessage({ id: 'EditListingAvailabilityAndPricingForm.bookByTheHourBookingLengthPlaceholder' });
                    const bookByTheHourBookingLengthHelpText = <FormattedMessage id="EditListingAvailabilityAndPricingForm.bookByTheHourBookingLengthHelpText" />

                    const classSlotsLabel = <FormattedMessage id="EditListingAvailabilityAndPricingForm.classSlotsLabel" />
                    const classSlotsRatePlaceholder = intl.formatMessage({ id: 'EditListingAvailabilityAndPricingForm.classSlotsRatePlaceholder' });
                    const classSlotsRateHelpText = <FormattedMessage id="EditListingAvailabilityAndPricingForm.classSlotsRateHelpText" />
                    const classSlotsBookingLengthPlaceholder = intl.formatMessage({ id: 'EditListingAvailabilityAndPricingForm.classSlotsBookingLengthPlaceholder' });
                    const classSlotsBookingLengthHelpText = <FormattedMessage id="EditListingAvailabilityAndPricingForm.classSlotsBookingLengthHelpText" />

                    const dayBookingOptionLabel = <FormattedMessage id="EditListingAvailabilityAndPricingForm.dayBookingOptionLabel" />
                    const dayBookingOptioRatePlaceholder = intl.formatMessage({ id: 'EditListingAvailabilityAndPricingForm.dayBookingOptioRatePlaceholder' });
                    const dayBookingOptioRateHelpText = <FormattedMessage id="EditListingAvailabilityAndPricingForm.dayBookingOptioRateHelpText" />

                    const noteToDelete = <FormattedMessage id="EditListingAvailabilityAndPricingForm.noteToDelete" />

                    const errorMessage = updateError ? (
                        <p className={css.error}>
                            <FormattedMessage id="EditListingAvailabilityAndPricingForm.updateFailed" />
                        </p>
                    ) : null;

                    const classes = classNames(rootClassName || css.root, className);
                    const submitReady = (updated && pristine) || ready;
                    const submitInProgress = updateInProgress;
                    const submitDisabled = invalid || disabled || submitInProgress || minimunLengthRequired || pristine || !hasValue;

                    // const columnCount = spaceType && spaceType === 'shared' ? 3 : 4;
                    const isPrivateSpace = spaceType === 'private';

                    // set default values for hour and class length
                    if (values.hour && values.hour.enable && !values.hour.length && !this.state.hourLengthFocus) {
                        form.change('hour.length', "1");
                    }
                    if (values.class && values.class.enable && !values.class.length && !this.state.classLengthFocus) {
                        form.change('class.length', "1");
                    }

                    const classNameSelectors = classNames(css.selector, {
                        [css.column3]: !isPrivateSpace
                    });

                    const classNameLabels = classNames(css.label, {
                        [css.column3]: !isPrivateSpace
                    });

                    const classNameDescriptions = classNames(css.description, {
                        [css.column3]: !isPrivateSpace
                    });

                    const classNamePrices = classNames(css.price, {
                        [css.column3]: !isPrivateSpace
                    });

                    const classNameLengths = classNames(css.length, {
                        [css.column3]: !isPrivateSpace
                    });

                    return (
                        <Form className={classes} onSubmit={handleSubmit}>
                            <div className={css.noteToDelete}>{noteToDelete}</div>
                            {errorMessage}

                            <div className={css.plansRoot}>
                                <div className={css.selectors}>
                                    <div className={classNameSelectors}>
                                        <FieldCheckbox
                                            id={`hour.enable`}
                                            name={`hour.enable`}
                                        />
                                    </div>
                                    {isPrivateSpace ? (
                                        <div className={css.selector}>
                                            <FieldCheckbox
                                                id={`class.enable`}
                                                name={`class.enable`}
                                            />
                                        </div>
                                    ) : null}
                                    <div className={classNameSelectors}>
                                        <FieldCheckbox
                                            id={`day.enable`}
                                            name={`day.enable`}
                                        />
                                    </div>
                                    <div className={classNameSelectors}>
                                        <FieldCheckbox
                                            id={`month.enable`}
                                            name={`month.enable`}
                                        />
                                    </div>
                                </div>
                                <div className={css.labels}>
                                    <h2 className={classNameLabels}>{bookByTheHourLabel}</h2>
                                    {isPrivateSpace ? (
                                        <h2 className={css.label}>{classSlotsLabel}</h2>
                                    ) : null}
                                    <h2 className={classNameLabels}>{dayBookingOptionLabel}</h2>
                                    <h2 className={classNameLabels}>{monthlySubscriptionLabel}</h2>
                                </div>
                                <div className={css.descriptions}>
                                    <div className={classNameDescriptions}>
                                        <p>
                                            Let users book your space in one hour increments. Users will select a start and end
                                            time based on your space's availability.
                                        </p>
                                    </div>
                                    {isPrivateSpace ? (
                                        <div className={css.description}>
                                            <p>
                                                Let users book your space for an ogoing subscription to a time slot.
                                            </p>
                                            <p>
                                                Users will be able to select a start and end time that occurs every week on
                                                the same day and time. (For example: every Tuesday from 5pm - 7pm)
                                            </p>
                                            <p>
                                                Bookings will be charged in 4 week blocks and will renew atuomatically until cancelled.
                                            </p>
                                            <span>
                                                Having separate class rates allows you to offer a discounted rate for users that use your
                                                space on a regular basis.
                                            </span>
                                        </div>
                                    ) : null}
                                    <div className={classNameDescriptions}>
                                        <p>
                                            Let users book your space in one day increments.
                                        </p>
                                        <p>
                                            Users will select the day they want to book but the start and end times for that day will
                                            be based on your set availability.
                                        </p>
                                    </div>
                                    <div className={classNameDescriptions}>
                                        <p>
                                            Let users book your space for an ongoing monthly subscription.
                                        </p>
                                        <p>
                                            Users will have access to the space at any time during your normal
                                            business hours and have no limit on the number of visits to the space
                                        </p>
                                        <p>
                                            Bookings are charged monthly and will automatically renew until cancelled.
                                        </p>
                                    </div>
                                </div>
                                <div className={css.prices}>
                                    <div className={classNamePrices}>
                                        <FieldCurrencyInput
                                            className={css.input}
                                            name={`hour.rate`}
                                            placeholder={bookByTheHourRatePlaceholder}
                                            currencyConfig={config.currencyConfig}
                                            required={rateRequired(values.hour)}
                                        />
                                        <span className={css.inputHelp}>{bookByTheHourRateHelpText}</span>
                                    </div>
                                    {isPrivateSpace ? (
                                        <div className={css.price}>
                                            <FieldCurrencyInput
                                                className={css.input}
                                                name={`class.rate`}
                                                placeholder={classSlotsRatePlaceholder}
                                                currencyConfig={config.currencyConfig}
                                                required={rateRequired(values.class)}
                                            />
                                            <span className={css.inputHelp}>{classSlotsRateHelpText}</span>
                                        </div>
                                    ) : null}
                                    <div className={classNamePrices}>
                                        <FieldCurrencyInput
                                            className={css.input}
                                            name={`day.rate`}
                                            placeholder={dayBookingOptioRatePlaceholder}
                                            currencyConfig={config.currencyConfig}
                                            required={rateRequired(values.day)}
                                        />
                                        <span className={css.inputHelp}>{dayBookingOptioRateHelpText}</span>
                                    </div>
                                    <div className={classNamePrices}>
                                        <FieldCurrencyInput
                                            className={css.input}
                                            name={`month.rate`}
                                            placeholder={monthlySubscriptionRatePlaceholder}
                                            currencyConfig={config.currencyConfig}
                                            required={rateRequired(values.month)}
                                        />
                                        <span className={css.inputHelp}>{monthlySubscriptionRateHelpText}</span>
                                    </div>
                                </div>
                                <div className={css.lengths}>
                                    <div className={classNameLengths}>
                                        <FieldSelect
                                            className={css.input}
                                            name={`hour.length`}
                                            required={lengthRequired(values.hour)}
                                            onFocus={() => this.changeFocus('hour', true)}
                                            onBlur={() => this.changeFocus('hour', false)}
                                        >
                                            <option className={css.option} value="" hidden>{bookByTheHourBookingLengthPlaceholder}</option>
                                            <option className={css.option} value="1">1</option>
                                            <option className={css.option} value="1.25">1:15</option>
                                            <option className={css.option} value="1.5">1:30</option>
                                            <option className={css.option} value="1.75">1:45</option>
                                            <option className={css.option} value="2">2</option>
                                            <option className={css.option} value="2.25">2:15</option>
                                            <option className={css.option} value="2.5">2:30</option>
                                            <option className={css.option} value="2.75">2:45</option>
                                            <option className={css.option} value="3">3</option>
                                            <option className={css.option} value="3.25">3:15</option>
                                            <option className={css.option} value="3.5">3:30</option>
                                            <option className={css.option} value="3.75">3:45</option>
                                            <option className={css.option} value="4">4</option>
                                        </FieldSelect>
                                        <span className={css.inputHelp}>{bookByTheHourBookingLengthHelpText}</span>
                                    </div>
                                    {isPrivateSpace ? (
                                        <div className={css.length}>
                                            <FieldSelect
                                                className={css.input}
                                                name={`class.length`}
                                                required={lengthRequired(values.class)}
                                                onFocus={() => this.changeFocus('class', true)}
                                                onBlur={() => this.changeFocus('class', false)}
                                            >
                                                <option className={css.option} value="" hidden>{classSlotsBookingLengthPlaceholder}</option>
                                                <option className={css.option} value="1">1</option>
                                                <option className={css.option} value="1.25">1:15</option>
                                                <option className={css.option} value="1.5">1:30</option>
                                                <option className={css.option} value="1.75">1:45</option>
                                                <option className={css.option} value="2">2</option>
                                                <option className={css.option} value="2.25">2:15</option>
                                                <option className={css.option} value="2.5">2:30</option>
                                                <option className={css.option} value="2.75">2:45</option>
                                                <option className={css.option} value="3">3</option>
                                                <option className={css.option} value="3.25">3:15</option>
                                                <option className={css.option} value="3.5">3:30</option>
                                                <option className={css.option} value="3.75">3:45</option>
                                                <option className={css.option} value="4">4</option>
                                            </FieldSelect>
                                            <span className={css.inputHelp}>{classSlotsBookingLengthHelpText}</span>
                                        </div>
                                    ) : null}
                                    <div className={classNameLengths} />
                                    <div className={classNameLengths} />
                                </div>
                            </div>

                            <Button
                                className={css.submitButton}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                ready={submitReady}
                            >
                                {saveActionMsg}
                            </Button>
                        </Form>
                    );
                }}
            />
        );
    }
}

EditListingAvailabilityAndPricingFormComponent.defaultProps = {
    updateError: null,
};

EditListingAvailabilityAndPricingFormComponent.propTypes = {
    intl: intlShape.isRequired,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateError: propTypes.error,
    updateInProgress: bool.isRequired,
    spaceType: string.isRequired
};

export default compose(injectIntl)(EditListingAvailabilityAndPricingFormComponent);
