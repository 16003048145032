import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './SectionTags.css';


const SectionTags = props => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            <div className={css.container}>
                <h1 className={css.title}>
                    <FormattedMessage id="SectionTagsHowItWorks.title" />
                </h1>
                <p className={css.description}>
                    <FormattedMessage id="SectionTagsHowItWorks.description" />
                </p>
                <NamedLink name="HostPage" className={css.link}>
                    <FormattedMessage id="SectionTagsHowItWorks.link" />
                </NamedLink>
            </div>
        </div>
    );
};

SectionTags.defaultProps = { rootClassName: null, className: null };

SectionTags.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionTags;