import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton } from '../../components';
import {
    minutesBetween,
  } from '../../util/dates';

import css from './TransactionPanel.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const CancelActionButtonsMaybe = props => {
    const {
        className,
        rootClassName,
        showButtons,
        isProvider,
        isCustomer,
        isOneTime,
        isSubscription,
        stripeSubscription,
        cancellationInProgress,
        cancellationError,
        onCancelBooking,
        timeAheadFromStartDate,
    } = props;

    const TODAY = new Date();

    const buttonsDisabled = cancellationInProgress;

    const endOfSubscriptionEndDate = stripeSubscription ? new Date(stripeSubscription.current_period_end * 1000) : null;
    const timeAheadLastBookingFromSubRenewal = minutesBetween(TODAY, endOfSubscriptionEndDate);

    // timeAhead on minutes
    // 4320 minutes = 72 hours
    let refundNoticeTranslationKey = null;
    if (isOneTime) {
        refundNoticeTranslationKey = isCustomer
            ? timeAheadFromStartDate >= 4320
                ? 'TransactionPanel.refundNotficeCustomerWithRefund'
                : 'TransactionPanel.refundNotficeCustomerWithoutRefund'
            : isProvider ?
                'TransactionPanel.refundNotficeProvider'
                : null;
    }
    else if (isSubscription) {
        refundNoticeTranslationKey = isCustomer
            ? timeAheadFromStartDate >= 4320
                ? 'TransactionPanel.refundNotficeCustomerWithRefund'
                : timeAheadFromStartDate > 0
                    ? 'TransactionPanel.refundNotficeCustomerWithoutRefund'
                    : timeAheadLastBookingFromSubRenewal >= 8640 
                        ? 'TransactionPanel.refundNoticeCustomerWaitTillEndOfPeriod' 
                        : timeAheadLastBookingFromSubRenewal >= 4320
                            ? 'TransactionPanel.cancelNotficeCustomerWithoutPenalization' 
                            : timeAheadLastBookingFromSubRenewal > 0
                                ? 'TransactionPanel.refundNoticeCustomerWithPenalization'
                                : 'TransactionPanel.refundNoticeCustomerWaitTillEndOfPeriod'
            : isProvider ?
                timeAheadFromStartDate > 0
                    ? 'TransactionPanel.refundNotficeProvider'
                    : 'TransactionPanel.refundNoticeProviderWaitTillEndOfPeriod'
                : null
    }

    console.log(refundNoticeTranslationKey)

    const cancelErrorMessage = cancellationError ? (
        <p className={css.actionError}>
            <FormattedMessage id="TransactionPanel.cancelBookingFailed" />
        </p>
    ) : null;

    const refundNoticeMessage = refundNoticeTranslationKey ? (
        <p className={css.refundNotice}>
            <FormattedMessage id={refundNoticeTranslationKey} />
        </p>
    ) : null;

    const classes = classNames(rootClassName || css.actionButtons, className);

    return showButtons ? (
        <div className={classes}>
            <div className={css.actionButtonWrapper}>
                <PrimaryButton
                    inProgress={cancellationInProgress}
                    disabled={buttonsDisabled}
                    onClick={onCancelBooking}
                >
                    <FormattedMessage id="TransactionPanel.cancelButton" />
                </PrimaryButton>
            </div>
            {refundNoticeMessage}
            {/* <div className={css.actionErrors}> */}
            {cancelErrorMessage}
            {/* </div> */}
        </div>
    ) : null;
};

export default CancelActionButtonsMaybe;
