import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditListingNumberSpacesForm } from '../../forms';

import css from './EditListingNumberSpacesPanel.css';

const EditListingNumberSpacesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  return (
    <div className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingNumberSpaces.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
          />
        ) : (
            <FormattedMessage id="EditListingNumberSpaces.createListingTitle" />
          )}
      </h1>
      <EditListingNumberSpacesForm
        initialValues={{ numberOfSpaces: publicData.numberOfSpaces }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { numberOfSpaces } = values;
          const updateValues = {
            publicData: {
              numberOfSpaces
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingNumberSpacesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingNumberSpacesPanel.propTypes = {
  className: string,
  rootClassName: string,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingNumberSpacesPanel;
