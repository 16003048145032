import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: June, 2021</p>

      <p>
      Maintaining Gofynder as a healthy marketplace requires users to showcase their spaces, plan their sessions, and interact with other users in a cordial and professional manner. We rely on you to follow these guidelines to ensure the safety and well-being of all Gofynder users, including yourself, when posting Content, communicating with other users, using Spaces, and making or accepting payments. These guidelines are a non-exhaustive list intended to give assurance to all our users.
      </p>

      <p>
      We take violations of these Community Guidelines seriously and may suspend or terminate an Account for ongoing or serious violations. We may also moderate, remove, or edit any Content that violates these Community Guidelines or if we believe it adversely affects the integrity of Gofynder or other users, and may add and remove elements of these Community Guidelines over time by posting an updated version to our website.
      </p>

      <p>
      These Community Guidelines are part of and use words and phrases defined in the Gofynder Services Agreement. If you have any questions about these Community Guidelines or if you believe that a user has violated these Community Guidelines, please contact us at <a href="mailto:hello@gofynder.com"><strong>hello@gofynder.com</strong></a>.
      </p>

      <p>
      You must agree to these Community Guidelines, as part of the Gofynder Services Agreement, to use of the Platform and Services. Please read them carefully and only use the Platform or Services if you agree to abide by them.
      </p>

      <h2>Hosts will:</h2>
      <p>
      •	Comply with any Laws, including local zoning, permitting, or tax laws. Zoning and use restrictions are in place to protect people, including your users. You know your Space best and Users trust that your Space is up to code, properly licensed, and safe.
      </p>

      <p>
      •	Accurately represent the nature, condition, and pricing of Spaces. Most Users will not have seen your Space before their session. It’s important that you give them an accurate idea of what to expect. While we know you want to put your best foot forward, you should be realistic and honest about your Space. Users should also have a realistic idea of the actual Booking costs and clearly list any services you provide for an additional cost. You may not list prices lower than what you intend to charge.
      </p>

      <p>
      •	Only offer Spaces that you’re permitted to Book. If you don’t own your Space or don’t have permission to List it, then you are not permitted to List it on the Platform. If you are renting, and not the owner or manager of a Space, you may need to have approval from the owner, manager, or other party of interest before you List it. You are welcome to List multiple Spaces but may not offer Spaces to Users that are not Listed.
      </p>

      <h2>Users will:</h2>
      <p>
      •	Use Spaces with respect and as described to Hosts. Hosts take pride in their Spaces and trust that they will be respected by Users. It is important that you are honest and do not misrepresent the intended use of a Space when Booking. It is up to the Hosts to determine how they want (or do not want) their Spaces used.
      </p>

      <p>
      •	Never store, transport, or possess hazardous material in a Space. This should go without saying but hazardous material can harm people and do permanent damage to Spaces. Bringing hazardous materials to a Space is not only dangerous but may be illegal, and you could face significant civil liability or criminal action for doing so.
      </p>

      <p>
      •	Account for set up and shut down time.  Users are booking spaces by the hour and need to respect that the host could potentially be booking the space before and/or after the time(s) you have booked.  Plan your time accordingly to enter and leave the space on time.
      </p>

      <h2>Everyone must:</h2>

      <p>
      •	Communicate with other users honestly. People trust Listings, comments about Spaces, and your communication with them. It’s important that you are clear and honest when you post. You may not use the Services to deceive or mislead people, post false or inaccurate statements, open multiple Accounts, or misrepresent who you are or your affiliations.
      </p>

      <p>
      •	Transact honestly. Users may only use the Platform to transact as provided for legitimate Bookings. Use of the Platform to conduct payment or other transactions offline, or attempting to transact when you know or believe the payment or payout is invalid, may result in suspension or termination of your Account or Fines.
      </p>

      <p>
      •	Respect other people’s information: You are only allowed to use the Content provided through the Platform as intended. You may not use or misuse Content from the Platform, images, or descriptions outside of the Platform. You may not harvest, scrape, or collect other information about our other users, such as names, phone numbers, physical addresses, email addresses or any other Content that is not publicly displayed in search results pages prior to a Booking confirmation.
      </p>

      <p>
      •	Use the Platform as Intended. The Platform is meant for Hosts to List and Users to Book Spaces. You are not allowed to use the Platform to promote products or services other than your Space. You may not send unwanted messages or spam, promote use of a Gofynder competitor, or use the Platform to communicate with users with no intention of Listing or Booking on the Platform. You may not Book Spaces in exchange for other services or goods in lieu of payment unless previously authorized by Gofynder.
      </p>

      <h2>No one may:</h2>

      <p>
      •	Discriminate against other users. Discrimination against any other users based on their race, sex, gender, ethnicity, national origin, sexual orientation, disability, or any other legally protected class is illegal and not permitted on the Platform. If we believe that you have refused to transact with another user based on any of these, we may suspend or terminate your account immediately.
      </p>

      <p>
      •	Encourage or endorse illegal or dangerous behavior. You may not post Content that endorses or promotes illegal or harmful activities, including obscene content or encouraging activity that may lead to other users harm. While use of marijuana is or may become legal in some jurisdictions, it remains prohibited under federal law and may not be grown, sold, distributed or used in any Space that is Booked on the Platform.
      </p>

      <p>
      •	Harm, intimidate, or threaten Users. We’ve built a Platform that we are proud of and that our users trust, and work hard to keep it that way. You are prohibited from doing anything that may harm or intimidate users, or interfere with their use or operation of the Platform. This includes threatening or harassing other users; posting undeserved or disproportionate reviews, or reviews that do not reflect your experience; attempting to extort other users for discounts or favorable reviews; or threatening legal action.
      </p>

      <p>
      •	Share personal or dispute information. For your protection, you may not share personal or sensitive information about yourself. This includes your home address, phone number, payment information, or other sensitive information that could lead to fraud or identity theft. This may be a safety risk to you and others. You may also not post any details about an ongoing dispute between you and another user.
      </p>

      <p>
      •	Harm, intimidate, harass, or threaten Gofynder or our brand. Anyone threatening Gofynder or our employees will be removed from the Platform and reported to law enforcement. You may not engage in behavior on the Platform or use Gofynder marks or logos in a manner that violates the Gofynder Service Agreement or harms, dilutes, or misuses our brand.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
